import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import CancellationReasonPopUp from '../../components/CancellationReasonPopUp'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import { sortDataByKey } from '../../helpers/SalesHelpers'
import { summarizeCancellationReason } from '../../helpers/ClientSucessHelpers'
import { WhatsApp, AddRounded } from '@material-ui/icons'

import '../../styles/containers/ClientSuccess/Cancellations.css'
import Loading from '../../components/Loading'
import { useCallback } from 'react'
import { getSubscriptionsCancelled } from '../../services/utils'
import { DateTime } from 'luxon'

const userTableHeaders = [
  'Id',
  'Nome',
  'E-mail',
  'Telefone',
  'Cancelada em',
  'Motivo Cancelamento'
]

const Cancellations = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])

  let [isLoading, setIsLoading] = useState(true)

  let [paginatedCancellationsData, setPaginatedCancellationsData] = useState([])
  let [
    cancellationsDataSearchResult,
    setCancellationsDataSearchResult
  ] = useState([])

  let [selectedSortHeader, setSelectedSortHeader] = useState(4)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  //variables for popup reason cancellation
  let [showReasonCancellationPopup, setShowReasonCancellationPopup] = useState(
    false
  )
  let [
    selectedUserCancellationReasonPopup,
    setSelectedUserCancellationReasonPopup
  ] = useState(null)
  let [reasonCancellationType, setReasonCancellationType] = useState(false)

  const fetchCancellations = useCallback(async () => {
    await getSubscriptionsCancelled(props.token)
      .then(cancellations => {
        const formattedCancellations = cancellations.map(cancellations => {
          return {
            id: cancellations.userId,
            name: cancellations.name || 'Indefinido',
            email: cancellations.email || 'Indefinido',
            phoneNumber: cancellations.phone,
            updatedAt: DateTime.fromISO(cancellations.updatedAt).toFormat(
              'dd/MM/yyyy'
            ),
            cancellationReason:
              summarizeCancellationReason(
                cancellations.lastCancellationShortReasonCreatedAt,
                cancellations.lastCancellationShortReason
              ) || 'Adicionar Motivo',
            cancellationReasonHistory: []
          }
        })
        setUserData(formattedCancellations)
        setPaginatedCancellationsData(formattedCancellations)
        setCancellationsDataSearchResult(formattedCancellations)
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }, [props.token])

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'CRM' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          SendWhatsappMessage(phoneNumber, '')
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'updatedAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'cancellationReason',
        positionIcon: 'right',
        className: '',
        passObjectToAction: true,
        action: cancellationReason => {
          setSelectedUserCancellationReasonPopup({
            ...cancellationReason,
            cancellationReasonHistory: []
          })
          if (cancellationReason.cancellationReason === 'Adicionar Motivo') {
            setReasonCancellationType('add')
            setShowReasonCancellationPopup(true)
          } else {
            setReasonCancellationType('history')
            setShowReasonCancellationPopup(true)
          }
        },
        icon: <AddRounded className='cancellations-add-icon' />
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const filteredUserData = [...cancellationsDataSearchResult]

    // If the key of the clicked header indicates a date field, set isDateField as 'true' to correctly order the dates in sortDataByKey().
    const isDateField = userTableKeys[selectedSortHeader].key === 'updatedAt'
    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredUserData(filteredUserData)
  }, [
    selectedSortHeader,
    sortOrientation,
    userTableKeys,
    cancellationsDataSearchResult
  ])

  useEffect(() => {
    fetchCancellations()
  }, [fetchCancellations])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Cancelamentos</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setCancellationsDataSearchResult(list)}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedCancellationsData}
            setPaginatedData={setPaginatedCancellationsData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {showReasonCancellationPopup && (
        <CancellationReasonPopUp
          user={selectedUserCancellationReasonPopup}
          refetch={fetchCancellations}
          type={reasonCancellationType}
          showPopup={showReasonCancellationPopup}
          setShowPopup={setShowReasonCancellationPopup}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Cancellations)
