import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'

import BasicCard from '../components/BasicCard'
import FormInput from '../components/FormInput'
import FormButton from '../components/FormButton'
import AdminLogo from '../assets/images/admin-logo.svg'

import '../styles/containers/Login.css'

import { login, getMe, getAdminArea } from '../services/utils'

const LoginCardContent = props => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const submitLogin = async e => {
    setLoading(true)
    e.preventDefault()
    login(email, password)
      .then(async response => {
        props.setToken(response)
        const userData = await getMe(response)
        props.setUser(userData)
        const adminArea = await getAdminArea(response)
        props.setAdminArea(adminArea)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='login-card-inner-container grid place-items-center'>
      <div className='login-card-logo'>
        <img src={AdminLogo} alt='Admin Logo' />
      </div>
      <div className='login-card-header'>
        <h4>Login</h4>
      </div>
      <div className='login-card-form'>
        <form className='login-screen-form' onSubmit={submitLogin}>
          <FormInput
            placeholder='E-mail'
            label='E-mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormInput
            placeholder='Senha'
            label='Senha'
            value={password}
            type='password'
            onChange={e => setPassword(e.target.value)}
          />
          <Link
            to='/esqueci-a-senha'
            className='login-screen-forgot-password-button'
          >
            Esqueci a senha
          </Link>
          <div className='login-button-container'>
            <FormButton loading={loading}>ENTRAR</FormButton>
          </div>
        </form>
      </div>
    </div>
  )
}

const Login = props => {
  return (
    <div className='login-screen-container flex content-screen-container'>
      <div className='login-card-container m-auto'>
        <BasicCard cardContent={LoginCardContent(props)} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps, actions)(Login)
