import { DateTime } from 'luxon'

export const getLastLogin = (date, daysLeftComplement) => {
  const timeDifference = DateTime.now().diff(DateTime.fromISO(date), [
    'days',
    'hours'
  ])

  let timeDifferenceString = ''

  if (timeDifference.values.days === 1 || timeDifference.values.days === 0) {
    timeDifferenceString += `1 dia${daysLeftComplement ? ' restante' : ''}`
  } else {
    timeDifferenceString += `${timeDifference.values.days} dias${
      daysLeftComplement ? ' restantes' : ''
    }`
  }

  return timeDifferenceString
}

const getExperiencePeriod = days => {
  const years = Math.floor(days / 365)
  const months = Math.floor((days - years * 365) / 30)
  let experiencePeriod = ''
  if (years) {
    experiencePeriod += `${years} ano${years > 1 ? 's' : ''}`
  }
  if (months) {
    if (experiencePeriod) {
      experiencePeriod += ' e '
    }
    experiencePeriod += `${months} ${months > 1 ? 'meses' : 'mês'}`
  }
  return experiencePeriod
}

export const getTotalUserExperience = (experienceId, daysSubscribed) => {
  switch (experienceId) {
    case 0:
      return getExperiencePeriod(daysSubscribed)
    case 1:
      return getExperiencePeriod(180 + daysSubscribed)
    case 2:
      return getExperiencePeriod(365 + daysSubscribed)
    case 3:
      return getExperiencePeriod(730 + daysSubscribed)
    case 4:
      return `mais de ${getExperiencePeriod(1095 + daysSubscribed)}`
    default:
      return `pelo menos ${getExperiencePeriod(daysSubscribed)}`
  }
}

export const textToZipCode = text => {
  return `${text.slice(0, 5)}-${text.slice(5, 8)}`
}

export const getSubscriptionTextIndicator = percentage => {
  if (percentage <= 30) {
    return 'Negativo'
  } else if (percentage > 30 && percentage <= 60) {
    return 'Neutro'
  } else {
    return 'Promissor'
  }
}

export const getSuccessRateTextIndicator = percentage => {
  if (percentage <= 30) {
    return 'Muito preocupante'
  } else if (percentage > 30 && percentage <= 50) {
    return 'Não muito bom'
  } else if (percentage > 50 && percentage <= 60) {
    return 'OK'
  } else {
    return 'Ótimo'
  }
}

export const getProgessBarColor = (count, idealNumber, total) => {
  if (count !== 0) {
    if (count >= idealNumber) {
      return '#8bc730'
    }

    const idealPercentage = (idealNumber / total) * 100
    const countPercentage = (count / total) * 100
    const percentageDifference = idealPercentage - countPercentage

    if (percentageDifference <= 20) {
      return '#d3cd31'
    } else {
      return '#ad3b65'
    }
  } else {
    return null
  }
}

export const getFunctionalityName = functionalityName => {
  switch (functionalityName) {
    case 'shopListTotal':
      return 'Lista de Compras'
    case 'moodboardTotal':
      return 'Guia de Estilo'
    case 'clientStyle':
      return 'Estilo do cliente'
    case 'customAboutMe':
      return 'Perfil customizado'
    case 'virtualCloset':
      return 'Closet Virtual'
    case 'billing':
      return 'Cobranças'
    case 'personalColoring':
      return 'Coloração'
    case 'silhouette':
      return 'Silhueta'
    case 'service':
      return 'Serviços'
    case 'agendaEvent':
      return 'Compromissos'
    default:
      return undefined
  }
}

export const getPlanName = planPeriodIdentifier => {
  if (process.env.REACT_APP_ENV === 'development') {
    switch (planPeriodIdentifier) {
      case 334:
        return 'Teste'
      case 765:
        return 'Cores'
      case 725:
        return 'Minimalista Mensal'
      case 956:
        return 'Minimalista Trimestral'
      case 955:
        return 'Minimalista Semestral'
      case 727:
        return 'Quatro Estações'
      case 958:
        return 'Quatro Estações Trimestral'
      case 957:
        return 'Quatro Estações Semestral'
      default:
        return 'Não assina'
    }
  } else {
    switch (planPeriodIdentifier) {
      case 1:
        return 'Teste'
      case 2:
        return 'Quatro Estações'
      case 3:
        return 'Futurista'
      case 4:
        return '[LEGADO] Minimalista'
      case 5:
        return 'Plano Especial Gratuito'
      case 6:
        return 'Plano teste'
      case 7:
        return '[LEGADO] Minimalista 10 clientes'
      case 8:
        return 'Quatro Estações 10 clientes'
      case 9:
        return '[LEGADO] Minimalista Semestral'
      case 10:
        return 'Quatro Estações Semestral'
      case 11:
        return 'Quatro Estações 5 clientes'
      case 12:
        return 'Cores'
      case 13:
        return 'Minimalista'
      case 14:
        return 'Minimalista Trimestral'
      case 15:
        return 'Minimalista Semestral'
      case 16:
        return 'Quatro Estações Trimestral'
      default:
        return 'Não assina'
    }
  }
}

export const getPlanStatus = (
  planStatus,
  paymentMethodIdentifier,
  validUntilDay
) => {
  if (paymentMethodIdentifier === 'manual' && validUntilDay === '2031-03-28') {
    return 'Ativo'
  } else {
    switch (planStatus) {
      case 'awaitingCancel':
        return 'Aguardando cancelamento'
      case 'active':
        return 'Ativo'
      case 'cancelled':
        return 'Cancelado'
      default:
        return 'Indefinido'
    }
  }
}
