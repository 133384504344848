import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'

import {
  getUserExperience,
  getTimeDifference,
  getSubscriptionTime,
  sortDataByKey
} from '../../helpers/SalesHelpers'
import {
  getFunctionalityName,
  getProgessBarColor,
  getPlanName,
  getPlanStatus
} from '../../helpers/ConsultantDetailsHelpers'
import { summarizeIuguCode } from '../../helpers/ClientSucessHelpers'
import { successToast } from '../../helpers/ToastHelper'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'

import BasicCard from '../../components/BasicCard'
import ProgressBar from '../../components/ProgressBar'
import BasicTable from '../../components/BasicTable'

import { WhatsApp, ChevronRightOutlined } from '@material-ui/icons'
import CopyIcon from '../../assets/images/copy.svg'
import FunnelTop from '../../assets/images/funnel-top.svg'
import FunnelMid from '../../assets/images/funnel-mid.svg'
import FunnelBottom from '../../assets/images/funnel-bottom.svg'
import FunnelSquare from '../../assets/images/funnel-square.svg'
import Loading from '../../components/Loading'

import {
  getCRM,
  getSubsCount,
  getTopConsultatsWithSubscribeChance,
  getLastCancelledSubscriptions,
  getLastCreatedSubscriptions
} from '../../services/utils'

import '../../styles/containers/Sales/SalesDashboard.css'

const userTableHeaders = ['ID', 'Nome', 'E-mail', 'Telefone', 'Criado em']

const cancellationsTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Plano',
  'Status',
  'Cancelada em',
  'Assinou em',
  'Código Iugu'
]
const lastCreatedTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Plano',
  'Status',
  'Cupom',
  'Assinou em',
  'Código Iugu'
]

const SalesDashboard = props => {
  let navigate = useNavigate()

  const transformUserData = funnelData => {
    return funnelData.map(user => {
      if (!user.currentPlan) {
        user.currentPlan = 'Não assina'
      }
      return user
    })
  }

  const transformTestingUsersData = testingUsersData => {
    return testingUsersData.map(user => {
      user.experience = getUserExperience(user.experienceId)
      if (user.lastContactDate) {
        user.lastContactDate = getTimeDifference(user.lastContactDate)
      } else {
        user.lastContactDate = 'Nenhum contato feito'
      }

      return user
    })
  }

  const getLeadUsers = users => {
    return users.filter(user => {
      return user.isLead
    })
  }

  const getTestingUsers = users => {
    return users.filter(user => {
      return user.currentPlan === 'Teste'
    })
  }

  const getSubscribersUsers = users => {
    return users.filter(user => {
      return user.currentPlan !== 'Não assina' && user.currentPlan !== 'Teste'
    })
  }

  const getNotConvertedUsers = users => {
    return users.filter(user => {
      return user.currentPlan === 'Não assina' && !user.isLead
    })
  }

  let [testingUsers, setTestingUsers] = useState([])
  let [paginatedTestingUsers, setPaginatedTestingUsers] = useState([])
  let [leadUsers, setLeadUsers] = useState([])
  let [subscribersUsers, setSubscribersUsers] = useState([])
  let [notConvertedUsers, setNotConvertedUsers] = useState([])
  let [goalsData, setGoalsData] = useState([])
  let [testingUsersData, setTestingUsersData] = useState([])
  let [isLoading, setIsLoading] = useState(true)

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('DESC')
  let [testUserData, setTestUserData] = useState([])

  let [
    selectedSortHeaderCancellations,
    setSelectedSortHeaderCancellations
  ] = useState(6)
  let [
    sortOrientationCancellations,
    setSortOrientationCancellations
  ] = useState('DESC')
  let [paginatedCancelledUsers, setPaginatedCancelledUsers] = useState([])
  let [cancelledUsers, setCancelledUsers] = useState([])
  let [cancelledUsersData, setCancelledUsersData] = useState([])

  let [
    selectedSortHeaderLastCreated,
    setSelectedSortHeaderLastCreated
  ] = useState(0)
  let [sortOrientationLastCreated, setSortOrientationLastCreated] = useState(
    'DESC'
  )
  let [paginatedLastCreatedUsers, setPaginatedLastCreatedUsers] = useState([])
  let [lastCreatedUsers, setLastCreatedUsers] = useState([])
  let [lastCreatedUsersData, setLastCreatedUsersData] = useState([])

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  const cancellationsTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          user.id = user.userId
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'plan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'updatedAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'summarizedIuguCode',
        positionIcon: 'left',
        passObjectToAction: true,
        className: '',
        action: subscription => {
          if (subscription.codeIugu) {
            successToast('Código Iugu copiado com sucesso!')
            navigator.clipboard.writeText(subscription.codeIugu)
          } else {
            return null
          }
        },
        icon: (
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        )
      }
    ],
    [navigate]
  )

  const lastCreatedTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          user.id = user.userId
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'plan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'cupom',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'summarizedIuguCode',
        positionIcon: 'left',
        passObjectToAction: true,
        className: '',
        action: subscription => {
          if (subscription.codeIugu) {
            successToast('Código Iugu copiado com sucesso!')
            navigator.clipboard.writeText(subscription.codeIugu)
          } else {
            return null
          }
        },
        icon: (
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        )
      }
    ],
    [navigate]
  )

  const clientCardContent = user => {
    return (
      <div className='py-4 px-4 grid grid-cols-1 md:grid-cols-3'>
        <div className='flex flex-col items-center justify-center col-span-1'>
          <p className='sales-dashboard-client-subscription-chance-number mb-2'>
            {user.subscriptionChance}%
          </p>
          <p className='sales-dashboard-client-subscription-chance-text text-center'>
            Chance de assinar
          </p>
        </div>
        <div className='flex flex-col col-span-2 ml-2'>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.name}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.experience}
              {user.experience !== 'Não preenchido' ? ' de experiência' : ''}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              Testando desde{' '}
              {DateTime.fromISO(user.createdAt).toFormat('dd-MM-yyyy')}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              Último contato há: {user.lastContactDate}
            </p>
          </div>
          <div className='flex'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              Usou principalmente: {user.mainFeatures.join(', ')}
            </p>
          </div>
          <div
            className='flex justify-end cursor-pointer mt-8'
            onClick={() => {
              navigate('/consultoras/detalhes', {
                state: { user, pageOrigin: 'Consultoras em Teste' }
              })
            }}
          >
            <p className='small-paragraph sales-dashboard-see-all-text'>
              Ver detalhes
            </p>
            <ChevronRightOutlined className='sales-dashboard-right-icon' />
          </div>
        </div>
      </div>
    )
  }

  const goalsInformation = (goal, key) => {
    return (
      <div className={`mb-2 ${key % 2 === 0 && 'mr-4'}`} key={key}>
        <p className='mb-2'>{goal.goalInfo}</p>
        <ProgressBar
          bgcolor={getProgessBarColor(
            goal.currentCount,
            goal.idealNumber,
            goal.goalNumber
          )}
          count={goal.currentCount}
          total={goal.goalNumber}
          ideal={goal.idealNumber}
        />
      </div>
    )
  }

  useEffect(() => {
    async function getSalesDashboardInfo () {
      try {
        const crm = await getCRM(props.token)
        const subsCount = await getSubsCount(props.token)
        const lastCancelledSubscriptions = await getLastCancelledSubscriptions(
          props.token
        )
        const lastCreatedSubscriptions = await getLastCreatedSubscriptions(
          props.token
        )
        const topConsultantsWithSubscribeChance = await getTopConsultatsWithSubscribeChance(
          props.token
        )

        // handling crm info
        const userData = crm.map(item => {
          const user = {
            id: item.id,
            name: item.name,
            email: item.email,
            phoneNumber: item.phone,
            city: item.city || 'Não preenchido',
            state: item.state || 'Não preenchido',
            experienceId: item.experienceId,
            subscriptionDate: item.subscriptionCreatedAt
              ? DateTime.fromISO(item.subscriptionCreatedAt).toFormat(
                  'yyyy-MM-dd'
                )
              : undefined,
            currentPlan:
              item.subscriptionPlanIdentifier === 1 &&
              (item.subscriptionStatus === 'active' ||
                item.subscriptionStatus === 'awaitingCancel') &&
              (!item.subscriptionValidUntilDay ||
                DateTime.fromISO(item.subscriptionValidUntilDay).diffNow()) > 0
                ? 'Teste'
                : item.subscriptionStatus === 'cancelled' ||
                  (item.subscriptionPlanIdentifier === 5 &&
                    item.subscriptionStatus !== 'cancelled') ||
                  item.email.includes('@liven') ||
                  item.email.includes('casarotto')
                ? 'Não assina'
                : getPlanName(item.subscriptionPlanIdentifier),
            isLead: !item.subscriptionStatus && item.status === 'open',
            createdAt: DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')
          }

          user.experience = getUserExperience(user.experienceId)
          user.subscriptionTime = getSubscriptionTime(user.subscriptionDate)

          return user
        })

        const transformedUserData = transformUserData(userData)
        const testingUsers = getTestingUsers(transformedUserData)
        setLeadUsers(getLeadUsers(transformedUserData))
        setNotConvertedUsers(getNotConvertedUsers(transformedUserData))
        setSubscribersUsers(getSubscribersUsers(transformedUserData))
        setTestingUsers(testingUsers)
        setPaginatedTestingUsers(testingUsers)

        // handling subs count info
        const dayOfYear = DateTime.now()
          .diff(
            DateTime.fromFormat(`01-01-${DateTime.now().year}`, 'dd-MM-yyyy'),
            'days'
          )
          .toObject()
          .days.toFixed(0)

        //Commented code for future goal changes
        // const averageWeekSubscriptions = (
        //   Math.max(subsCount.subscriberConsultants - 180, 0) /
        //   DateTime.now().weekNumber
        // ).toFixed(0)
        // const averageMonthSubscriptions = (
        //   Math.max(subsCount.subscriberConsultants - 180, 0) /
        //   DateTime.now().month
        // ).toFixed(0)

        // const averageWeekSubscriptionsGoal = (
        //   Math.max(480 - subsCount.subscriberConsultants, 0) /
        //   (DateTime.now().weeksInWeekYear - DateTime.now().weekNumber)
        // ).toFixed(0)
        // const averageMonthSubscriptionsGoal = (
        //   Math.max(480 - subsCount.subscriberConsultants, 0) /
        //   (12 - DateTime.now().month)
        // ).toFixed(0)
        setGoalsData([
          {
            currentCount: subsCount.subscriberConsultants,
            goalInfo: 'Meta assinaturas 2022',
            idealNumber: (((300 - 180) / 365) * dayOfYear + 180).toFixed(0),
            goalNumber: 300
            // idealNumber: (((480 - 180) / 365) * dayOfYear + 180).toFixed(0),
            // goalNumber: 480
          }
          //Commented code for future goal changes
          // {
          //   currentCount: Math.min(
          //     averageWeekSubscriptions,
          //     averageWeekSubscriptionsGoal
          //   ),
          //   goalInfo: 'Média crescimento assinaturas semanais',
          //   goalNumber: averageWeekSubscriptionsGoal
          // },
          // {
          //   currentCount: Math.min(
          //     averageMonthSubscriptions,
          //     averageMonthSubscriptionsGoal
          //   ),
          //   goalInfo: 'Média crescimento assinaturas mensais',
          //   goalNumber: averageMonthSubscriptionsGoal
          // }
        ])

        // handling top consultants with greatest subscription chance
        const topTestingUsers = topConsultantsWithSubscribeChance.map(
          consultant => {
            const functionalitiesObject = { ...consultant.clientDetails }
            const functionalities = []
            const mostUsedFunctionalities = []

            delete functionalitiesObject.shopListImageAndText
            delete functionalitiesObject.shopListMosty
            delete functionalitiesObject.moodboardPdf
            delete functionalitiesObject.moodboardMosty
            delete functionalitiesObject.createdClients
            delete functionalitiesObject.confirmedClients

            for (let i in functionalitiesObject) {
              if (functionalitiesObject[i] > 0)
                functionalities.push([i, functionalitiesObject[i]])
            }

            functionalities.sort((a, b) => a[1] - b[1])

            if (functionalities[0]) {
              mostUsedFunctionalities.push(
                getFunctionalityName(functionalities[0][0])
              )

              if (functionalities[1]) {
                mostUsedFunctionalities.push(
                  getFunctionalityName(functionalities[1][0])
                )
              }
            } else {
              mostUsedFunctionalities.push(['Nenhuma funcionalidade usada'])
            }

            return {
              id: consultant.id,
              name: consultant.name,
              email: consultant.email,
              phoneNumber: consultant.phone,
              experienceId: consultant.experienceId,
              validUntilDay:
                consultant.subscriptionValidUntilDay &&
                DateTime.fromISO(consultant.subscriptionValidUntilDay),
              createdAt: DateTime.fromISO(consultant.createdAt).toFormat(
                'yyyy-MM-dd'
              ),
              lastLogin: DateTime.fromISO(consultant.updatedAt).toFormat(
                'yyyy-MM-dd'
              ),
              subscriptionChance: (consultant.subscriptionChance * 100).toFixed(
                0
              ),
              lastContactDate:
                consultant.lastContactDate &&
                DateTime.fromISO(consultant.lastContactDate).toFormat(
                  'yyyy-MM-dd'
                ),
              mainFeatures: mostUsedFunctionalities
            }
          }
        )

        setTestingUsersData(transformTestingUsersData(topTestingUsers))

        // handling cancelled users
        const cancelledUsersData = lastCancelledSubscriptions.map(item => {
          const user = {
            id: item.id,
            name: item.name,
            email: item.email,
            phoneNumber: item.phone,
            plan: item.planIdentifier
              ? getPlanName(item.planIdentifier)
              : 'Indefinido',
            status: getPlanStatus(item.status),
            updatedAt: DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy'),
            createdAt: DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy'),
            userId: item.userId,
            summarizedIuguCode: summarizeIuguCode(item.externalSubscriptionId),
            codeIugu: item.externalSubscriptionId || ''
          }
          return user
        })
        setCancelledUsers(cancelledUsersData)
        setPaginatedCancelledUsers(cancelledUsersData)

        // handling last subscriptions created
        const createdUsersData = lastCreatedSubscriptions.map(item => {
          const user = {
            id: item.id,
            name: item.name,
            email: item.email,
            plan: item.planIdentifier
              ? getPlanName(item.planIdentifier)
              : 'Indefinido',
            status: getPlanStatus(item.status),
            couponCode: item.couponCode || '',
            createdAt: DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy'),
            userId: item.userId,
            summarizedIuguCode: summarizeIuguCode(item.externalSubscriptionId),
            codeIugu: item.externalSubscriptionId || ''
          }
          return user
        })
        setLastCreatedUsers(createdUsersData)
        setPaginatedLastCreatedUsers(createdUsersData)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    getSalesDashboardInfo()
  }, [props.token])

  useEffect(() => {
    const isDateField =
      userTableKeys[selectedSortHeader].key === 'createdAt' ||
      userTableKeys[selectedSortHeader].key === 'updatedAt'
    const copyTestingUsers = [...testingUsers]
    const copyCancelledUsersForCancellations = [...cancelledUsers]
    const copyLastCreatedUsersForLastCreatedSubscriptions = [
      ...lastCreatedUsers
    ]

    copyTestingUsers.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    copyCancelledUsersForCancellations.sort(
      sortDataByKey(
        cancellationsTableKeys[selectedSortHeaderCancellations].key,
        sortOrientationCancellations,
        isDateField
      )
    )

    copyLastCreatedUsersForLastCreatedSubscriptions.sort(
      sortDataByKey(
        lastCreatedTableKeys[selectedSortHeaderLastCreated].key,
        sortOrientationLastCreated,
        isDateField
      )
    )

    setTestUserData(copyTestingUsers)
    setCancelledUsersData(copyCancelledUsersForCancellations)
    setLastCreatedUsersData(copyLastCreatedUsersForLastCreatedSubscriptions)
  }, [
    selectedSortHeader,
    sortOrientation,
    userTableKeys,
    testingUsers,
    cancelledUsers,
    cancellationsTableKeys,
    selectedSortHeaderCancellations,
    sortOrientationCancellations,
    lastCreatedUsers,
    lastCreatedTableKeys,
    selectedSortHeaderLastCreated,
    sortOrientationLastCreated
  ])

  const onClickSortHeader = (selectedHeader, table) => {
    let orientation = 'ASC'

    if (table === 'lastTestingCreated') {
      if (selectedSortHeader === selectedHeader) {
        if (sortOrientation === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeader(selectedHeader)
      setSortOrientation(orientation)
    }

    if (table === 'cancellations') {
      if (selectedSortHeaderCancellations === selectedHeader) {
        if (sortOrientationCancellations === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderCancellations(selectedHeader)
      setSortOrientationCancellations(orientation)
    }

    if (table === 'lastCreated') {
      if (selectedSortHeaderLastCreated === selectedHeader) {
        if (sortOrientationLastCreated === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderLastCreated(selectedHeader)
      setSortOrientationLastCreated(orientation)
    }
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Dashboard Vendas</h2>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='grid grid-cols-1 md:grid-cols-5'>
            <div className='px-8 col-span-2 min-h-[700px]'>
              <h4 className='my-data-section-header mb-6'>Funil</h4>
              <div className='flex justify-center items-center relative mb-8'>
                <img src={FunnelTop} alt='Topo Funil' />
                <div className='absolute flex flex-col items-center'>
                  <h3>{leadUsers.length}</h3>
                  <p>{leadUsers.length === 1 ? 'Lead' : 'Leads'}</p>
                </div>
              </div>
              <div className='col-span-2 flex justify-center items-center relative mb-8'>
                <img src={FunnelMid} alt='Meio Funil' />
                <div className='absolute flex flex-col items-center'>
                  <h3>{testingUsers.length}</h3>
                  <p>Testando</p>
                </div>
              </div>
              <div className='col-span-2 flex justify-center items-center relative mb-8'>
                <img src={FunnelBottom} alt='Fundo Funil' />
                <div className='absolute flex flex-col items-center mt-[-100px]'>
                  <h3 className='bottom-funnel-text'>
                    {subscribersUsers.length}
                  </h3>
                  <p className='bottom-funnel-text'>
                    {subscribersUsers.length === 1 ? 'Assinante' : 'Assinantes'}
                  </p>
                </div>
              </div>
              <div className='col-span-2 flex justify-center items-center relative mb-8'>
                <img src={FunnelSquare} alt='Não Assinantes' />
                <div className='absolute flex flex-col items-center'>
                  <h3>{notConvertedUsers.length}</h3>
                  <p>
                    {notConvertedUsers.length === 1
                      ? 'Não assinou'
                      : 'Não assinaram'}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-span-3'>
              <div className='mb-4'>
                <h4 className='my-data-section-header'>
                  Consultoras com maior probabilidade de assinar
                </h4>
              </div>
              <div className='flex overflow-auto px-2 py-2 mb-4'>
                {testingUsersData.map((user, key) => {
                  return (
                    <div key={key} className='mr-4 max-w-[340px] min-w-[300px]'>
                      <BasicCard cardContent={clientCardContent(user)} />
                    </div>
                  )
                })}
              </div>
              <div
                className='flex justify-end cursor-pointer mb-8'
                onClick={() => {
                  navigate('/vendas/consultoras-em-teste', {
                    state: {
                      selectedFilters: ['higherSubscriptionChance', 'contact']
                    }
                  })
                }}
              >
                <p className='sales-dashboard-see-all-text'>
                  Ver todas as consultoras
                </p>
                <ChevronRightOutlined className='sales-dashboard-right-icon' />
              </div>
              <div className='mb-4'>
                <h4 className='my-data-section-header'>
                  Últimos testes criados
                </h4>
              </div>
              <BasicTable
                filteredData={testUserData}
                tableHeaders={userTableHeaders}
                tableKeys={userTableKeys}
                paginatedData={paginatedTestingUsers}
                setPaginatedData={setPaginatedTestingUsers}
                onClickSortHeader={onClickSortHeader}
                selectedSortHeader={selectedSortHeader}
                sortOrientation={sortOrientation}
                tableType={'lastTestingCreated'}
                openAction={() =>
                  navigate('/vendas/crm', {
                    state: {
                      selectedOnlyFilter: 'testers'
                    }
                  })
                }
              />
              <div
                className='flex justify-end cursor-pointer mt-2 mb-8'
                onClick={() => {
                  navigate('/vendas/crm', {
                    state: {
                      selectedOnlyFilter: 'testers'
                    }
                  })
                }}
              >
                <p className='sales-dashboard-see-all-text'>
                  Ver todos os testes{' '}
                </p>
                <ChevronRightOutlined className='sales-dashboard-right-icon' />
              </div>
            </div>
          </div>
          <div className='mb-4'>
            <h4 className='my-data-section-header'>
              Últimas assinaturas criadas
            </h4>
            <BasicTable
              filteredData={lastCreatedUsersData}
              tableHeaders={lastCreatedTableHeaders}
              tableKeys={lastCreatedTableKeys}
              paginatedData={paginatedLastCreatedUsers}
              setPaginatedData={setPaginatedLastCreatedUsers}
              onClickSortHeader={onClickSortHeader}
              selectedSortHeader={selectedSortHeaderLastCreated}
              sortOrientation={sortOrientationLastCreated}
              tableType={'lastCreated'}
            />
            <div
              className='flex justify-end cursor-pointer mt-2 mb-8'
              onClick={() => {
                navigate('/sucesso-do-cliente/assinaturas')
              }}
            >
              <p className='sales-dashboard-see-all-text'>
                Ver todas as assinaturas criadas{' '}
              </p>
              <ChevronRightOutlined className='sales-dashboard-right-icon' />
            </div>
          </div>
          <div className='mb-4'>
            <h4 className='my-data-section-header'>
              Últimas assinaturas canceladas
            </h4>
            <BasicTable
              filteredData={cancelledUsersData}
              tableHeaders={cancellationsTableHeaders}
              tableKeys={cancellationsTableKeys}
              paginatedData={paginatedCancelledUsers}
              setPaginatedData={setPaginatedCancelledUsers}
              onClickSortHeader={onClickSortHeader}
              selectedSortHeader={selectedSortHeaderCancellations}
              sortOrientation={sortOrientationCancellations}
              tableType={'cancellations'}
            />
            <div
              className='flex justify-end cursor-pointer mt-2 mb-8'
              onClick={() => {
                navigate('/sucesso-do-cliente/cancelamentos')
              }}
            >
              <p className='sales-dashboard-see-all-text'>
                Ver todos os usuários cancelados{' '}
              </p>
              <ChevronRightOutlined className='sales-dashboard-right-icon' />
            </div>
          </div>
          <div className=''>
            <div className='mb-4'>
              <h4 className='my-data-section-header'>Metas</h4>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2'>
              {goalsData.map((goal, key) => {
                return goalsInformation(goal, key)
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(SalesDashboard)
