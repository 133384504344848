import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import SimpleActionPopup from '../../components/SimpleActionPopup'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import FormInput from '../../components/FormInput'
import {
  getUserExperience,
  getSubscriptionTime,
  sortDataByKey
} from '../../helpers/SalesHelpers'
import { getPlanName } from '../../helpers/ConsultantDetailsHelpers'
import { WhatsApp } from '@material-ui/icons'
import { successToast } from '../../helpers/ToastHelper'

import '../../styles/containers/Sales/CRM.css'
import { addLead, getCRM } from '../../services/utils'
import { DateTime } from 'luxon'
import Loading from '../../components/Loading'

const userTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Cidade',
  'Estado',
  'Experiência',
  'Data de Cadastro'
]

const CRM = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [showAddLeadPopup, setShowAddLeadPopup] = useState(false)
  let [popupLoading, setPopupLoading] = useState(false)
  let [isLoading, setIsLoading] = useState(true)

  let [name, setName] = useState('')
  let [email, setEmail] = useState('')
  let [phoneNumber, setPhoneNumber] = useState('')
  let [instagram, setInstagram] = useState('')
  let [address, setAddress] = useState('')
  let [city, setCity] = useState('')
  let [state, setState] = useState('')

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')

  const location = useLocation()

  const filterInitialState = filter => {
    if (location.state && location.state.selectedOnlyFilter) {
      if (filter === location.state.selectedOnlyFilter) {
        return true
      }
      return false
    }
    return true
  }

  const fetchCRM = async userProps => {
    setIsLoading(true)
    getCRM(userProps.token)
      .then(crm => {
        const userData = crm.map(item => {
          const user = {
            id: item.id,
            name: item.name,
            email: item.email,
            phoneNumber: item.phone,
            city: item.city || 'Não preenchido',
            state: item.state || 'Não preenchido',
            experienceId: item.experienceId,
            subscriptionDate: item.subscriptionCreatedAt
              ? DateTime.fromISO(item.subscriptionCreatedAt).toFormat(
                  'yyyy-MM-dd'
                )
              : undefined,
            currentPlan:
              item.subscriptionPlanIdentifier === 1 &&
              (item.subscriptionStatus === 'active' ||
                item.subscriptionStatus === 'awaitingCancel') &&
              (!item.subscriptionValidUntilDay ||
                DateTime.fromISO(item.subscriptionValidUntilDay).diffNow()) > 0
                ? 'Teste'
                : item.subscriptionStatus === 'cancelled'
                ? 'Não assina'
                : getPlanName(item.subscriptionPlanIdentifier),
            isLead: !item.subscriptionStatus,
            status: item.status,
            createdAt: DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy'),
            validUntilDay: item.subscriptionValidUntilDay
          }

          user.experience = getUserExperience(user.experienceId)
          user.subscriptionTime = getSubscriptionTime(user.subscriptionDate)

          return user
        })

        setUserData(userData)
        setFilteredUserData(userData)
        setPaginatedUserData(userData)
        setUserDataSearchResult(userData)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  const dateInitialState = dateInput => {
    if (dateInput === 'firstDateInput') {
      if (location.state && location.state.firstDateInput) {
        return location.state.firstDateInput
      }
    }
    if (dateInput === 'secondDateInput') {
      if (location.state && location.state.secondDateInput) {
        return location.state.secondDateInput
      }
    }
    return null
  }

  let [subscribersFilter, setSubscribersFilter] = useState(
    false,
    filterInitialState('subscribers')
  )
  let [testersFilter, setTestersFilter] = useState(
    filterInitialState('testers')
  )
  let [leadsOpenFilter, setLeadsOpenFilter] = useState(
    filterInitialState('leads')
  )
  let [notConvertedFilter, setNotConvertedFilter] = useState(
    false,
    filterInitialState('notConverted')
  )
  let [leadsLostFilter, setLeadsLostFilter] = useState(false)
  let [leadsConvertedFilter, setLeadsConvertedFilter] = useState(false)

  let [firstDateInput, setFirstDateInput] = useState(
    dateInitialState('firstDateInput')
  )
  let [secondDateInput, setSecondDateInput] = useState(
    dateInitialState('secondDateInput')
  )

  const filterList = [
    {
      label: 'Assinantes',
      filterState: subscribersFilter,
      setFilter: value => setSubscribersFilter(value)
    },
    {
      label: 'Em Teste',
      filterState: testersFilter,
      setFilter: value => setTestersFilter(value)
    },
    {
      label: 'Leads Abertos',
      filterState: leadsOpenFilter,
      setFilter: value => setLeadsOpenFilter(value)
    },
    {
      label: 'Não assinou',
      filterState: notConvertedFilter,
      setFilter: value => setNotConvertedFilter(value)
    },
    {
      label: 'Leads Convertidos',
      filterState: leadsConvertedFilter,
      setFilter: value => setLeadsConvertedFilter(value)
    },
    {
      label: 'Leads Perdidos',
      filterState: leadsLostFilter,
      setFilter: value => setLeadsLostFilter(value)
    }
  ]

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'CRM' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'city',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'state',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'experience',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const firstDate = firstDateInput
      ? DateTime.fromFormat(firstDateInput, 'yyyy-MM-dd').ts
      : null
    const secondDate = secondDateInput
      ? DateTime.fromFormat(secondDateInput, 'yyyy-MM-dd').ts
      : null

    const filteredUserData = userDataSearchResult.filter(item => {
      const createdAt = DateTime.fromFormat(item.createdAt, 'dd/MM/yyyy').ts
      // TODO: define the desired date to be considered in this period filter
      if (createdAt < firstDate || createdAt > secondDate) {
        return false
      }

      if (subscribersFilter) {
        if (item.currentPlan !== 'Não assina' && item.currentPlan !== 'Teste') {
          return true
        }
      }

      if (testersFilter) {
        if (item.currentPlan === 'Teste') {
          return true
        }
      }

      if (leadsOpenFilter) {
        if (item.status === 'open') {
          return true
        }
      }

      if (notConvertedFilter) {
        // TODO: make the right logic for users who do not convert. This depends on how data will come from backend.
        if (
          item.isLead !== true &&
          item.currentPlan === 'Não assina' &&
          item.subscriptionDate !== null
        ) {
          return true
        }
      }

      if (leadsConvertedFilter) {
        if (item.status === 'converted') {
          return true
        }
      }

      if (leadsLostFilter) {
        if (item.status === 'lost') {
          return true
        }
      }

      return false
    })
    const isDateField = userTableKeys[selectedSortHeader].key === 'createdAt'

    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredUserData(filteredUserData)
  }, [
    subscribersFilter,
    testersFilter,
    leadsOpenFilter,
    notConvertedFilter,
    userDataSearchResult,
    firstDateInput,
    secondDateInput,
    selectedSortHeader,
    sortOrientation,
    userTableKeys,
    leadsConvertedFilter,
    leadsLostFilter
  ])

  useEffect(() => {
    fetchCRM(props)
  }, [props])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  const submitAddLead = async () => {
    const leadToAdd = {
      name,
      email,
      phone: phoneNumber,
      city,
      state,
      instagram,
      streetName: address
    }

    setPopupLoading(true)

    try {
      await addLead(props.token, leadToAdd)
      await fetchCRM(props)
      successToast('Lead adicionado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowAddLeadPopup(false)
      setPopupLoading(false)
    }

    setPopupLoading(false)
  }

  const leadPopupContent = () => {
    return (
      <div className='manage-users-popup-content-container'>
        <div className='grid grid-cols-1 gap-x-14 md:grid-cols-2'>
          <FormInput
            placeholder='Nome'
            label='Nome*'
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormInput
            placeholder='E-mail'
            label='E-mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormInput
            placeholder='Telefone'
            label='Telefone'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            mask={{
              format: '(##) #####-####'
            }}
          />
          <FormInput
            placeholder='Instagram'
            label='Instagram'
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />
          <FormInput
            placeholder='Nome da rua, bairro, número, complemento'
            label='Endereço'
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <div className='grid grid-cols-3 gap-x-2'>
            <div className='col-span-2'>
              <FormInput
                placeholder='Cidade'
                label='Cidade'
                value={city}
                onChange={e => setCity(e.target.value)}
              />
            </div>
            <FormInput
              placeholder='ex: AC'
              label='Estado'
              value={state}
              onChange={e => setState(e.target.value)}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>CRM</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          filterList={filterList}
          firstDateInput={firstDateInput}
          setFirstDateInput={setFirstDateInput}
          secondDateInput={secondDateInput}
          setSecondDateInput={setSecondDateInput}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onPressAddButton={() => {
              setName('')
              setEmail('')
              setPhoneNumber('')
              setInstagram('')
              setAddress('')
              setCity('')
              setState('')
              setShowAddLeadPopup(true)
            }}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {showAddLeadPopup && (
        <SimpleActionPopup
          title={'Adicionar Lead'}
          content={leadPopupContent()}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddLead}
          onClosePopup={() => setShowAddLeadPopup(false)}
          popupLoading={popupLoading}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(CRM)
