import React from 'react'
import { TailSpin } from 'react-loader-spinner'

const Loading = () => {
  return (
    <TailSpin
      color='var(--identity-6)'
      wrapperStyle={{
        justifyContent: 'center',
        paddingTop: 80
      }}
    />
  )
}

export default Loading
