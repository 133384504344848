import React, { useState, useEffect } from 'react'
import '../styles/components/SidebarMenu.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  StoreOutlined,
  HowToRegOutlined,
  // AssessmentOutlined,
  SettingsOutlined,
  PermIdentityOutlined,
  ExitToAppOutlined,
  ChevronRightOutlined,
  ExpandMoreOutlined,
  ReceiptOutlined
} from '@material-ui/icons'
import AdminLogo from '../assets/images/admin-logo-sidebar.svg'
import * as actions from '../redux/actions'

const SidebarMenu = props => {
  const sidebarMenuCategories = [
    // {
    //   category: {
    //     title: 'Início',
    //     path: '/'
    //   },
    //   subcategories: []
    // },
    {
      title: 'Vendas',
      path: '/vendas',
      icon: <StoreOutlined className='sidebar-category-icon' />,
      alt: 'Ícone Vendas',
      subcategories: [
        {
          title: 'Dashboard',
          path: '/vendas'
        },
        {
          title: 'CRM',
          path: '/vendas/crm'
        },
        {
          title: 'Funil de vendas',
          path: '/vendas/funil'
        },
        {
          title: 'Consultoras em teste',
          path: '/vendas/consultoras-em-teste'
        },
        {
          title: 'Indicações',
          path: '/vendas/indicacoes'
        },
        {
          title: 'Cupons',
          path: '/vendas/cupons'
        }
      ]
    },
    {
      title: 'Sucesso do Cliente',
      path: '/sucesso-do-cliente',
      icon: <HowToRegOutlined className='sidebar-category-icon' />,
      subcategories: [
        {
          title: 'Dashboard',
          path: '/sucesso-do-cliente'
        },
        {
          title: 'Consultoras',
          path: '/sucesso-do-cliente/consultoras'
        },
        {
          title: 'Clientes',
          path: '/sucesso-do-cliente/clientes'
        },
        {
          title: 'Assinaturas',
          path: '/sucesso-do-cliente/assinaturas'
        },
        {
          title: 'Acompanhamento de Assinantes - Resumo',
          path: '/sucesso-do-cliente/acompanhamento-assinantes-resumo'
        },
        {
          title: 'Acompanhamento de Assinantes - Tabelas',
          path: '/sucesso-do-cliente/acompanhamento-assinantes-tabelas'
        },
        {
          title: 'Cancelamentos',
          path: '/sucesso-do-cliente/cancelamentos'
        }
      ]
    },
    {
      title: 'Históricos',
      path: '/historicos',
      icon: <ReceiptOutlined className='sidebar-category-icon' />,
      subcategories: [
        {
          title: 'Clientes Apagadas',
          path: '/historicos/historico-clientes-apagadas'
        },
        {
          title: 'Trocas de Consultoras',
          path: '/historicos/historico-mudanca-consultora'
        },
        {
          title: 'E-mails Bloqueados',
          path: '/historicos/emails-bloqueados'
        }
      ]
    },
    // {
    //   title: 'Dados',
    //   path: '/dados',
    //   icon: <AssessmentOutlined className='sidebar-category-icon' />,
    //   subcategories: [
    //     {
    //       title: 'Dashboard',
    //       path: '/dados'
    //     },
    //     {
    //       title: 'Gráficos',
    //       path: '/dados/graficos'
    //     },
    //     {
    //       title: 'Detalhes',
    //       path: '/dados/detalhes'
    //     }
    //   ]
    // },
    {
      title: 'Configurações',
      path: '/configuracoes',
      icon: <SettingsOutlined className='sidebar-category-icon' />,
      subcategories: [
        {
          title: 'Gerenciar Usuários',
          path: '/configuracoes'
        }
      ],
      areaRestriction: 'superAdmin'
    },
    {
      title: 'Minha Conta',
      path: '/minha-conta',
      icon: <PermIdentityOutlined className='sidebar-category-icon' />,
      subcategories: [
        {
          title: 'Meus Dados',
          path: '/meus-dados'
        }
      ]
    },
    {
      title: 'Sair',
      path: '/login',
      icon: <ExitToAppOutlined className='sidebar-category-icon' />,
      subcategories: [],
      action: () => props.logoutUser()
    }
  ]
  let navigate = useNavigate()

  let location = useLocation()
  const getSelectedCategory = () => {
    let selectedCategoryKey
    let selectedSubcategoryKey
    sidebarMenuCategories.forEach(category => {
      return category.subcategories.forEach(subcategory => {
        if (location.pathname === subcategory.path) {
          selectedCategoryKey = category.title
          selectedSubcategoryKey = subcategory.title
        }
      })
    })
    // TODO: Change the default screen to 'Início' or 'Dados'
    if (props.adminArea?.area === 'customerSuccess' && !selectedCategoryKey) {
      selectedCategoryKey = 'Sucesso do Cliente'
      selectedSubcategoryKey = 'Dashboard'
    } else {
      if (!selectedCategoryKey) {
        selectedCategoryKey = 'Vendas'
        selectedSubcategoryKey = 'Dashboard'
      }
    }
    return { selectedCategoryKey, selectedSubcategoryKey }
  }

  const { selectedCategoryKey, selectedSubcategoryKey } = getSelectedCategory()

  // If a user clicks a category (expandedCategory), it will reveal its subcategories, but the user is still at the previous category
  const [expandedCategory, setExpandedCategory] = useState(selectedCategoryKey)
  // When clicking a subcategory, the user changes to that subcategory (currentSubcategory) and its category (currentCategory) page, so both are selected
  const [currentCategory, setCurrentCategory] = useState(selectedCategoryKey)
  const [currentSubcategory, setCurrentSubcategory] = useState(
    selectedSubcategoryKey
  )

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  // TODO: make a proper showSidebar control, using auth and mobile
  const showSidebar = location.pathname === '/login' ? false : true

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }
  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const isMobile = () => {
    if (windowWidth <= 820) {
      return true
    }
    return false
  }

  const restrictedSidebarMenuCategories = sidebarMenuCategories.filter(
    category =>
      !category.areaRestriction ||
      (props.adminArea && category.areaRestriction === props.adminArea.area)
  )

  const renderSidebarMenuCategories = () => {
    return restrictedSidebarMenuCategories.map((category, key) => {
      return (
        <div key={category.title}>
          <div className='sidebar-separator' />
          <div className='sidebar-category-item-container'>
            <div
              className='sidebar-category-title-and-icon-container'
              onClick={() => {
                if (category.title === 'Sair') {
                  navigate('/login')
                  category.action()
                }
                if (expandedCategory === category.title) {
                  setExpandedCategory(null)
                } else {
                  setExpandedCategory(category.title)
                }
              }}
            >
              <div className='sidebar-icon-background'>{category.icon}</div>
              <div className='sidebar-category-title-container'>
                <p className='sidebar-category-text sidebar-font-color'>
                  {category.title}
                </p>
              </div>
              {category.title !== 'Sair' && (
                <div className='sidebar-category-expand'>
                  {expandedCategory === category.title ? (
                    <ExpandMoreOutlined className='sidebar-category-icon' />
                  ) : (
                    <ChevronRightOutlined className='sidebar-category-icon' />
                  )}
                </div>
              )}
            </div>
            {expandedCategory === category.title && (
              <span
                className='sidebar-subcategories-list'
                selected={category.selected}
              >
                {category.subcategories.map((subcategory, subcategoryKey) => {
                  return (
                    <div
                      key={subcategory.title}
                      className={`${currentCategory === category.title &&
                        currentSubcategory === subcategory.title &&
                        'sidebar-subcategory-selected'}`}
                    >
                      <div
                        className='sidebar-subcategory-title'
                        onClick={() => {
                          setCurrentSubcategory(subcategory.title)
                          setCurrentCategory(category.title)
                          navigate(subcategory.path)
                        }}
                      >
                        {subcategory.title}
                      </div>
                    </div>
                  )
                })}
              </span>
            )}
          </div>
        </div>
      )
    })
  }

  const renderMobileSidebarMenuCategories = () => {
    const navigateToPageMobile = ({ subcategoryKey, categoryKey, path }) => {
      setCurrentSubcategory(subcategoryKey)
      setCurrentCategory(categoryKey)
      setShowMobileMenu(false)
      navigate(path)
    }

    const selectedCategory = restrictedSidebarMenuCategories.find(
      category => category.title === currentCategory
    )
    const filteredCategories = restrictedSidebarMenuCategories.filter(
      category => category.title !== currentCategory
    )

    return (
      <>
        {/* menu that is always shown to user on top of the screen */}
        <div className='sidebar-separator' />
        <div className='sidebar-category-item-container mb-2'>
          <div
            className='sidebar-category-title-and-icon-container'
            onClick={() => {
              setShowMobileMenu(!showMobileMenu)
            }}
          >
            <div className='sidebar-icon-background'>
              {selectedCategory.icon}
            </div>
            <div className='sidebar-category-title-container'>
              <p className='sidebar-category-text sidebar-font-color'>
                {selectedCategory.title}
              </p>
            </div>
            {selectedCategory.title !== 'Sair' && (
              <div className='sidebar-category-expand'>
                {showMobileMenu === true ? (
                  <ExpandMoreOutlined className='sidebar-category-icon' />
                ) : (
                  <ChevronRightOutlined className='sidebar-category-icon' />
                )}
              </div>
            )}
          </div>

          {/* Menu dropdown when user click on it to change category */}
          {showMobileMenu && (
            <span
              className='sidebar-subcategories-list-mobile absolute'
              selected={selectedCategory.selected}
            >
              {selectedCategory.subcategories.map(
                (subcategory, subcategoryKey) => {
                  return (
                    <div
                      key={subcategory.title}
                      className={`${currentSubcategory === subcategory.title &&
                        'sidebar-subcategory-selected'}`}
                    >
                      <div
                        className='sidebar-subcategory-title'
                        onClick={() => {
                          navigateToPageMobile({
                            subcategoryKey: subcategory.title,
                            categoryKey: currentCategory,
                            path: subcategory.path
                          })
                        }}
                      >
                        {subcategory.title}
                      </div>
                    </div>
                  )
                }
              )}

              {filteredCategories.map((category, key) => {
                return (
                  <div key={key}>
                    <div className='sidebar-separator' />
                    <div className='sidebar-category-item-container'>
                      <div
                        className='sidebar-category-title-and-icon-container'
                        onClick={() => {
                          if (category.title === 'Sair') {
                            navigate('/login')
                            category.action()
                          }
                          if (expandedCategory === category.title) {
                            setExpandedCategory(null)
                          } else {
                            setExpandedCategory(category.title)
                          }
                        }}
                      >
                        <div className='sidebar-icon-background'>
                          {category.icon}
                        </div>
                        <div className='sidebar-category-title-container'>
                          <p className='sidebar-category-text sidebar-font-color'>
                            {category.title}
                          </p>
                        </div>
                        {category.title !== 'Sair' && (
                          <div className='sidebar-category-expand'>
                            {expandedCategory === category.title ? (
                              <ExpandMoreOutlined className='sidebar-category-icon' />
                            ) : (
                              <ChevronRightOutlined className='sidebar-category-icon' />
                            )}
                          </div>
                        )}
                      </div>
                      {expandedCategory === category.title && (
                        <span
                          className='sidebar-subcategories-list-mobile'
                          selected={category.selected}
                        >
                          {category.subcategories.map(
                            (subcategory, subcategoryKey) => {
                              return (
                                <div key={subcategory.title}>
                                  <div
                                    className='sidebar-subcategory-title'
                                    onClick={() => {
                                      navigateToPageMobile({
                                        subcategoryKey: subcategory.title,
                                        categoryKey: category.title,
                                        path: subcategory.path
                                      })
                                    }}
                                  >
                                    {subcategory.title}
                                  </div>
                                </div>
                              )
                            }
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                )
              })}
            </span>
          )}
        </div>
      </>
    )
  }

  const renderDesktopSidebarMenu = () => {
    return (
      <div className='sidebar-menu-container overflow-auto'>
        <div className='sidebar-logo'>
          <img src={AdminLogo} alt='Admin Logo' />
        </div>
        {props.user && (
          <div className='sidebar-greetings-container'>
            <p className='small-paragraph sidebar-font-color'>Olá,</p>
            <p className='sidebar-font-color'>{props.user.name}</p>
          </div>
        )}
        {renderSidebarMenuCategories()}
      </div>
    )
  }

  const renderMobileSidebarMenu = () => {
    return (
      <div className='sidebar-menu-mobile-container'>
        <div className='flex justify-between'>
          <div className='sidebar-logo-mobile'>
            <img
              src={AdminLogo}
              alt='Admin Logo'
              className='sidebar-logo-mobile-size'
            />
          </div>
          {props.user && (
            <div className='sidebar-greetings-container-mobile'>
              <p className='small-paragraph sidebar-font-color'>Olá,</p>
              <p className='small-paragraph sidebar-font-color'>
                {props.user.name}
              </p>
            </div>
          )}
        </div>
        {renderMobileSidebarMenuCategories()}
      </div>
    )
  }

  const sidebarContent = () => {
    return isMobile() ? renderMobileSidebarMenu() : renderDesktopSidebarMenu()
  }

  return showSidebar ? sidebarContent() : <div></div>
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps, actions)(SidebarMenu)
