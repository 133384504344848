import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import { sortDataByKey } from '../../helpers/SalesHelpers'
import { WhatsApp } from '@material-ui/icons'

import '../../styles/containers/ClientSuccess/Clients.css'
import Loading from '../../components/Loading'
import { useCallback } from 'react'
import { searchClients } from '../../services/utils'
import { errorToast } from '../../helpers/ToastHelper'
import { DateTime } from 'luxon'

const userTableHeaders = [
  'Nome',
  'E-mail',
  'Telefone',
  'Consultora',
  'Criou senha',
  'Cliente criada em',
  'Universo'
]

const Clients = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [searchButtonEnabled, setSearchButtonEnabled] = useState(false)
  let [lastSearch, setLastSearch] = useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [searchButtonWasClicked, setSearchButtonWasClicked] = useState(false)

  let [search, setSearch] = useState('')

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')

  const location = useLocation()

  const filterInitialState = filter => {
    if (location.state && location.state.selectedOnlyFilter) {
      if (filter === location.state.selectedOnlyFilter) {
        return true
      }
      return false
    }
    return false
  }

  let [createdPasswordFilter, setCreatedPasswordFilter] = useState(
    filterInitialState('createdPassword')
  )
  let [notCreatedPasswordFilter, setNotCreatedPasswordFilter] = useState(
    filterInitialState('notCreatedPassword')
  )

  const filterList = [
    {
      label: 'Criou senha',
      filterState: createdPasswordFilter,
      setFilter: value => setCreatedPasswordFilter(value)
    },
    {
      label: 'Não criou senha',
      filterState: notCreatedPasswordFilter,
      setFilter: value => setNotCreatedPasswordFilter(value)
    }
  ]

  const userTableKeys = useMemo(
    () => [
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/clientes/detalhes', {
            state: { user }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phone',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'consultantName',
        className: '',
        icon: '',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: {
              user: {
                id: user.consultantId
              },
              pageOrigin: 'Sucesso do Cliente'
            }
          })
        }
      },
      {
        key: 'createdPassword',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'universe',
        className: '',
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  const fetchClients = useCallback(async () => {
    if (search === lastSearch) {
      return
    }

    if (search.length < 5) {
      return errorToast('A pesquisa deve ter no mínimo 5 caracteres!')
    }

    if (!searchButtonWasClicked) {
      setSearchButtonWasClicked(true)
    }

    setIsLoading(true)

    searchClients(props.token, search)
      .then(res => {
        setLastSearch(search)

        const transformedData = res.map(client => {
          return {
            ...client,
            createdAt: DateTime.fromISO(client.createdAt).toFormat(
              'dd/MM/yyyy'
            ),
            createdPassword: client.auth === 10 ? 'Sim' : 'Não',
            universe:
              client.universe === 'masculine'
                ? 'Masculino'
                : client.universe === 'feminine'
                ? 'Feminino'
                : client.universe === 'other'
                ? 'Outro'
                : 'Não-binário'
          }
        })

        setUserData(transformedData)
        setFilteredUserData(transformedData)
        setPaginatedUserData(transformedData)
        setUserDataSearchResult(transformedData)
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }, [props.token, searchButtonWasClicked, search, lastSearch])

  useEffect(() => {
    const filteredUserData = userDataSearchResult.filter(item => {
      if (createdPasswordFilter) {
        if (item.auth !== 10) {
          return false
        }
      }

      if (notCreatedPasswordFilter) {
        if (item.auth !== 5) {
          return false
        }
      }

      return true
    })

    // If the key of the clicked header indicates a date field, set isDateField as 'true' to correctly order the dates in sortDataByKey().
    const isDateField = userTableKeys[selectedSortHeader].key === 'createdAt'

    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredUserData(filteredUserData)
  }, [
    userDataSearchResult,
    selectedSortHeader,
    sortOrientation,
    userTableKeys,
    createdPasswordFilter,
    notCreatedPasswordFilter
  ])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Sucesso do Cliente - Clientes</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          filterList={filterList}
          searchBarSubmitEnabled={searchButtonEnabled}
          onSearchBarSubmit={fetchClients}
          onChangeSearchBar={e => {
            setSearchButtonEnabled(e.target.value.length >= 5)
            setSearch(e.target.value)
          }}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Clientes</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : !searchButtonWasClicked ? (
          <p className='search-wasnot-clicked-message'>
            Digite o nome ou o e-mail da cliente que deseja encontrar e clique
            no botão de "Buscar"
          </p>
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Clients)
