import React, { useState, useEffect, useCallback } from 'react'
import SimpleActionPopup from './SimpleActionPopup'
import useDimensions from '../hooks/useDimensions'
import { connect } from 'react-redux'
import Loading from '../components/Loading'
import { getDocumentImage } from '../services/utils'
import '../styles/components/DocumentViewPopup.css'

const SubscriptionHistoryPopup = ({
  documentId,
  title,
  setShowPopup,
  token
}) => {
  let [imageUrl, setImageUrl] = useState()
  let [isLoading, setIsLoading] = useState(true)

  const { dimensions } = useDimensions()

  const fetchDocumentImage = useCallback(async () => {
    try {
      setIsLoading(true)
      getDocumentImage(token, documentId)
        .then(imageDoc => {
          setImageUrl(imageDoc)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => setIsLoading(false))
    } catch (err) {
      console.error(err)
    }
  }, [documentId, token])

  const popupContent = () => {
    return (
      <div>
        <div className='document-view-popup-image'>
          {isLoading ? (
            <Loading />
          ) : (
            <img src={imageUrl} alt='Documento de exclusão permanente.' />
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    fetchDocumentImage()
  }, [fetchDocumentImage])

  return (
    <SimpleActionPopup
      title={'Documento de Solicitação ' + title}
      content={popupContent()}
      onClosePopup={() => setShowPopup(false)}
      containerStyle={{
        marginTop: 20,
        maxHeight: dimensions.innerHeight - 20 - 20,
        overflow: 'auto'
      }}
      secondActionButtonStyle={{
        marginInline: 'auto',
        color: 'white',
        width: '700px',
        background: 'var(--linear-identity-1)',
        marginRight: '0px !important'
      }}
      secondActionButtonText={'FECHAR'}
      onPressSecondActionButton={() => {
        setShowPopup(false)
      }}
    />
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    token: user.token
  }
}

export default connect(mapStateToProps)(SubscriptionHistoryPopup)
