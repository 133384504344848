const INITIAL_STATE = {
  token: null,
  user: null,
  adminArea: null
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.token }
    case 'SET_USER':
      return { ...state, user: action.user }
    case 'SET_ADMIN_AREA':
      return { ...state, adminArea: action.adminArea }
    case 'LOGOUT':
      return { state: INITIAL_STATE }
    default:
      return state
  }
}

export default userReducer
