import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import {
  getUserExperience,
  getSubscriptionTime,
  sortDataByKey
} from '../../helpers/SalesHelpers'
import { getPlanName } from '../../helpers/ConsultantDetailsHelpers'
import {
  AddRounded,
  BallotOutlined,
  DeleteOutline,
  WhatsApp
} from '@material-ui/icons'

import '../../styles/containers/Sales/CRM.css'
import {
  addConsultantContact,
  deleteConsultantContact,
  editConsultantContact,
  getConsultantContactHistory,
  getSubscribers
} from '../../services/utils'
import { DateTime } from 'luxon'
import Loading from '../../components/Loading'
import { DatePicker } from '../../components/DateTimePickers'
import FormInput from '../../components/FormInput'
import BasicCard from '../../components/BasicCard'
import EditIcon from '../../assets/images/edit.svg'
import { errorToast, successToast } from '../../helpers/ToastHelper'
import SimpleActionPopup from '../../components/SimpleActionPopup'

const userTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Experiência',
  'Dias Restantes',
  'Último login',
  'Chance de Cancelar',
  'Tempo de Assinatura',
  'Plano',
  'Último Contato (Assinante)'
]

const Subscribers = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [isLoading, setIsLoading] = useState(true)
  let [newContactItemDate, setNewContactItemDate] = useState('')
  let [newContactItemMessage, setNewContactItemMessage] = useState('')

  let [contactToEdit, setContactToEdit] = useState('')
  let [editContactItemDate, setEditContactItemDate] = useState('')
  let [editContactItemMessage, setEditContactItemMessage] = useState('')
  let [popupLoading, setPopupLoading] = useState(false)
  let [showContactHistoryPopup, setShowContactHistoryPopup] = useState(false)
  let [showAddContactItemPopup, setShowAddContactItemPopup] = useState(false)
  let [showEditContactItemPopup, setShowEditContactItemPopup] = useState(false)
  let [
    selectedUserContactHistoryPopup,
    setSelectedUserContactHistoryPopup
  ] = useState(null)
  let [showDeleteContactItemPopup, setShowDeleteContactItemPopup] = useState(
    false
  )
  let [contactToDeleteId, setContactToDeleteId] = useState(0)

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')

  const location = useLocation()

  const filterInitialState = filter => {
    if (location.state && location.state.selectedOnlyFilter) {
      if (filter === location.state.selectedOnlyFilter) {
        return true
      }
      return false
    }
    return false
  }

  const fetchSubscribers = async userProps => {
    setIsLoading(true)
    getSubscribers(userProps.token)
      .then(crm => {
        const userData = crm.map(item => {
          const user = {
            id: item.id,
            name: item.name,
            email: item.email,
            phoneNumber: item.phone,
            city: item.city || 'Não preenchido',
            state: item.state || 'Não preenchido',
            experienceId: item.experienceId,
            subscriptionDate: item.subscriptionCreatedAt
              ? DateTime.fromISO(item.subscriptionCreatedAt).toFormat(
                  'yyyy-MM-dd'
                )
              : undefined,
            currentPlan:
              item.subscriptionPlanIdentifier === 1 &&
              (item.subscriptionStatus === 'active' ||
                item.subscriptionStatus === 'awaitingCancel') &&
              (!item.subscriptionValidUntilDay ||
                DateTime.fromISO(item.subscriptionValidUntilDay).diffNow()) > 0
                ? 'Teste'
                : item.subscriptionStatus === 'cancelled'
                ? 'Não assina'
                : getPlanName(item.subscriptionPlanIdentifier),
            isLead: !item.subscriptionStatus,
            createdAt: DateTime.fromISO(item.createdAt).toFormat('yyyy-MM-dd'),
            validUntilDay: item.subscriptionValidUntilDay
          }

          user.isAwaitingCancel = item.isAwaitingCancel
          user.subscriptionChance =
            item.subscriptionChance !== undefined
              ? `${(item.subscriptionChance * 100).toFixed(0)}%`
              : '0%'
          user.experience = getUserExperience(user.experienceId)
          user.subscriptionStatus = item.subscriptionStatus
          user.rawSubscriptionChance = item.subscriptionChance
          user.cancelChance =
            item.subscriptionChance !== undefined
              ? `${((1 - item.subscriptionChance) * 100).toFixed(0)}%`
              : '0%'
          user.subscriptionTime = getSubscriptionTime(user.subscriptionDate)
          user.lastLogin = item.updatedAt ? DateTime.fromISO(item.updatedAt).toFormat(
            'dd/MM/yyyy'
          ) : 'Indefinido'
          user.remainDays = Math.floor(
            DateTime.fromFormat(item.subscriptionValidUntilDay, 'yyyy-MM-dd')
              .diffNow('days')
              .get('days')
          )

          user.remainDays = `${user.remainDays} dia${
            user.remainDays === 1 ? '' : 's'
          }`
          user.lastContactDate = item.lastContactDate
            ? DateTime.fromISO(item.lastContactDate).toFormat('dd/MM/yyyy')
            : 'Nenhum Contato'

          return user
        })

        setUserData(userData)
        setFilteredUserData(userData)
        setPaginatedUserData(userData)
        setUserDataSearchResult(userData)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  // const dateInitialState = dateInput => {
  //   if (dateInput === 'firstDateInput') {
  //     if (location.state && location.state.firstDateInput) {
  //       return location.state.firstDateInput
  //     }
  //   }
  //   if (dateInput === 'secondDateInput') {
  //     if (location.state && location.state.secondDateInput) {
  //       return location.state.secondDateInput
  //     }
  //   }
  //   return null
  // }

  let [cancelChanceFilter, setCancelChanceFilter] = useState(
    filterInitialState('cancelChance')
  )
  let [awaitingCancelFilter, setAwaitingCancelFilter] = useState(
    filterInitialState('awaitingCancel')
  )
  let [successChanceFilter, setSuccessChanceFilter] = useState(
    filterInitialState('successChance')
  )

  // let [secondDateInput, setSecondDateInput] = useState(
  //   dateInitialState('secondDateInput')
  // )

  const filterList = [
    {
      label: 'Chance de cancelar',
      filterState: cancelChanceFilter,
      setFilter: value => setCancelChanceFilter(value)
    },
    {
      label: 'Cancelamento agendado',
      filterState: awaitingCancelFilter,
      setFilter: value => setAwaitingCancelFilter(value)
    },
    {
      label: 'Chance de sucesso',
      filterState: successChanceFilter,
      setFilter: value => setSuccessChanceFilter(value)
    }
  ]

  const fetchUserContactHistory = useCallback(
    async consultant => {
      const contactHistoryToMap = await getConsultantContactHistory(
        props.token,
        consultant.id,
        'consultant'
      )
      const contactHistory = contactHistoryToMap.map(contact => ({
        id: contact.id,
        message: contact.contactDetails,
        responsibleUser: contact.responsibleUser,
        date: DateTime.fromISO(contact.contactDate).toFormat('dd/MM/yyyy')
      }))
      setSelectedUserContactHistoryPopup({ ...consultant, contactHistory })
    },
    [props.token]
  )

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Acompanhamento de Assinantes' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'experience',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'remainDays',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'lastLogin',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'cancelChance',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'subscriptionTime',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'currentPlan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'lastContactDate',
        className: '',
        passObjectToAction: true,
        action: async consultant => {
          setSelectedUserContactHistoryPopup({
            ...consultant,
            contactHistory: []
          })
          setPopupLoading(true)
          setShowContactHistoryPopup(true)

          try {
            await fetchUserContactHistory(consultant)
          } catch {
            errorToast(
              'Ocorreu um erro ao carregar o histórico de contato desse usuário, tente novamente'
            )
            setShowContactHistoryPopup(false)
          } finally {
            setPopupLoading(false)
          }

          // setSelectedUserContactHistoryPopup(user)
          // TODO: open popup logic passing user.contactHistory
        },
        icon: <BallotOutlined className='ballot-table-data-icon' />
      }
    ],
    [navigate, fetchUserContactHistory]
  )

  useEffect(() => {
    const filteredUserData = userDataSearchResult.filter(item => {
      // TODO: define the desired date to be considered in this period filter
      // if (item.createdAt < firstDateInput || item.createdAt > secondDateInput) {
      //   return false
      // }

      if (cancelChanceFilter) {
        if (item.rawSubscriptionChance >= 0.5) {
          return false
        }
      }

      if (awaitingCancelFilter) {
        if (!item.isAwaitingCancel) {
          return false
        }
      }

      if (successChanceFilter) {
        if (item.rawSubscriptionChance < 0.5) {
          return false
        }
      }

      // if (notConvertedFilter) {
      //   // TODO: make the right logic for users who do not convert. This depends on how data will come from backend.
      //   if (
      //     item.currentPlan === 'Não assina' &&
      //     item.subscriptionDate !== null
      //   ) {
      //     return true
      //   }
      // }

      return true
    })

    // If the key of the clicked header indicates a date field, set isDateField as 'true' to correctly order the dates in sortDataByKey().
    const isDateField =
      userTableKeys[selectedSortHeader].key === 'lastLogin' ||
      userTableKeys[selectedSortHeader].key === 'lastContactDate'

    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredUserData(filteredUserData)
  }, [
    cancelChanceFilter,
    awaitingCancelFilter,
    successChanceFilter,
    // notConvertedFilter,
    userDataSearchResult,
    // firstDateInput,
    // secondDateInput,
    selectedSortHeader,
    sortOrientation,
    userTableKeys
  ])

  useEffect(() => {
    fetchSubscribers(props)
  }, [props])

  // TODO: add submit add contact message logic
  const addEditContactItemPopup = () => {
    setNewContactItemDate('')
    setNewContactItemMessage('')
    setShowContactHistoryPopup(false)
    setShowAddContactItemPopup(true)
  }

  const deleteContactItem = async () => {
    // TODO: add popup confirmation
    setPopupLoading(true)
    try {
      await deleteConsultantContact(props.token, contactToDeleteId)
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchSubscribers(props)
      successToast('Contato deletado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowDeleteContactItemPopup(false)
      setPopupLoading(false)
    }
  }

  const editContactItem = async () => {
    setPopupLoading(true)

    try {
      await editConsultantContact(props.token, contactToEdit, {
        contactDetails: editContactItemMessage,
        contactDate: editContactItemDate
      })
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchSubscribers(props)
      setShowEditContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato editado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const submitAddContactItem = async () => {
    setPopupLoading(true)

    const dataToPost = {
      contactDate: newContactItemDate,
      contactDetails: newContactItemMessage,
      consultantId: selectedUserContactHistoryPopup.id
    }

    try {
      await addConsultantContact(props.token, dataToPost)
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchSubscribers(props)
      setShowAddContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato criado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowAddContactItemPopup(false)
      setPopupLoading(false)
    }
  }

  const contactHistoryPopupContent = selectedUser => {
    const contactCardContent = contactItem => {
      return (
        <div className='px-4 py-4 grid grid-cols-8'>
          <div className='col-span-7'>
            <div className='mb-6'>
              <p className='big-paragraph font-medium'>
                {contactItem.date} - {contactItem.responsibleUser}
              </p>
            </div>
            <div>
              <p>"{contactItem.message}"</p>
            </div>
          </div>
          <div className='flex flex-col'>
            <div
              onClick={() => {
                setContactToDeleteId(contactItem.id)
                setShowDeleteContactItemPopup(true)
              }}
              className='mb-4 self-end cursor-pointer'
            >
              <DeleteOutline className='delete-card-icon' />
            </div>
            <div
              className='self-end cursor-pointer'
              onClick={() => {
                setContactToEdit(contactItem.id)
                setEditContactItemDate(
                  DateTime.fromFormat(contactItem.date, 'dd/MM/yyyy').toFormat(
                    'yyyy-MM-dd'
                  )
                )
                setEditContactItemMessage(contactItem.message)
                setShowContactHistoryPopup(false)
                setShowEditContactItemPopup(true)
              }}
            >
              <img src={EditIcon} alt='Editar usuário' />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='grid grid-cols-1 max-w-[670px] m-auto max-h-[300px] overflow-auto p-2'>
          {selectedUser.contactHistory.length > 0 ? (
            selectedUser.contactHistory.map((contactItem, key) => {
              return (
                <div key={key} className='mb-6'>
                  <BasicCard cardContent={contactCardContent(contactItem)} />
                </div>
              )
            })
          ) : (
            <div>
              <p>Usuário não possui histórico de contato.</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const CustomAddButton = () => {
    return (
      <button
        className='testing-consultant-add-contact-item-button'
        onClick={addEditContactItemPopup}
      >
        <AddRounded className='basic-table-add-icon' />
      </button>
    )
  }

  const addContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={newContactItemDate}
            onChange={e => setNewContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={newContactItemMessage}
            onChange={e => setNewContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - newContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const editContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={editContactItemDate}
            onChange={e => setEditContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={editContactItemMessage}
            onChange={e => setEditContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - editContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const deleteContactItemContent = () => {
    return <div className='text-xl'>Deseja realmente deletar este contato?</div>
  }

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Acompanhamento de assinantes - Tabelas</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          filterList={filterList}
          // firstDateInput={firstDateInput}
          // setSecondDateInput={setSecondDateInput}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {showContactHistoryPopup && (
        <SimpleActionPopup
          title={`Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={contactHistoryPopupContent(selectedUserContactHistoryPopup)}
          customSecondActionButton={<CustomAddButton />}
          onClosePopup={() => setShowContactHistoryPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showAddContactItemPopup && (
        <SimpleActionPopup
          title={`Adicionar Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={addContactItemContent(selectedUserContactHistoryPopup)}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddContactItem}
          onClosePopup={() => setShowAddContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showEditContactItemPopup && (
        <SimpleActionPopup
          title={`Editar Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={editContactItemContent(selectedUserContactHistoryPopup)}
          secondActionButtonText={'EDITAR'}
          onPressSecondActionButton={editContactItem}
          onClosePopup={() => setShowEditContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showDeleteContactItemPopup && (
        <SimpleActionPopup
          title={`Deletar contato - ${selectedUserContactHistoryPopup.name}`}
          content={deleteContactItemContent(selectedUserContactHistoryPopup)}
          firstActionButtonText={'CANCELAR'}
          secondActionButtonText={'DELETAR'}
          onPressSecondActionButton={deleteContactItem}
          onPressFirstActionButton={() => setShowDeleteContactItemPopup(false)}
          onClosePopup={() => setShowDeleteContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Subscribers)
