import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimpleActionPopup from './SimpleActionPopup'
import { connect } from 'react-redux'
import { sortDataByKey } from '../helpers/SalesHelpers'
import { DateTime } from 'luxon'
import {
  addNewIndication,
  changeConsultant,
  convertLead,
  getConsultants
} from '../services/utils'
import { convertBlobToBase64 } from '../helpers/ImageHelpers'
import { errorToast, successToast } from '../helpers/ToastHelper'
import { SearchOutlined, WhatsApp, ErrorOutline } from '@material-ui/icons'
import BasicTable from './BasicTable'
import SendWhatsappMessage from '../services/SendWhatsappMessage'

const userTableHeaders = ['Nome', 'E-mail', 'Telefone', 'Criado em']

const SelectConsultantPopup = ({
  showPopup,
  setShowPopup,
  type,
  setUsers,
  refetch: refetchSelectConsultant,
  token
}) => {
  let [popupLoading, setPopupLoading] = useState(false)

  const location = useLocation()
  let navigate = useNavigate()

  let [user, setUser] = useState(location.state?.user)

  let [
    isChangeConsultantPopupShowing,
    setIsChangeConsultantPopupShowing
  ] = useState(type === 'indications' ? false : showPopup)

  let [
    isChangeConsultantConfirmationPopupShowing,
    setIsChangeConsultantConfirmationPopupShowing
  ] = useState(type === 'indications' ? showPopup : false)

  let [hasSelectedConsultant, setHasSelectedConsultant] = useState(false)
  let [selectedConsultant, setSelectedConsultant] = useState(null)

  let [
    changeConsultantConfirmationFile,
    setChangeConsultantConfirmationFile
  ] = useState(null)

  let [userData, setUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')

  // Variables for popups
  let [title, setTitle] = useState('')
  let [textButton, setTextButton] = useState('')

  // Variables for popup indications
  let [addButtonType, setAddButtonType] = useState(false)
  let [consultantWhoIndicated, setConsultantWhoIndicated] = useState(null)
  let [indicatedConsultant, setIndicatedConsultant] = useState(null)
  let [consultantWhoReferred, setConsultantWhoReferred] = useState(null)

  const userTableKeys = useMemo(
    () => [
      {
        key: 'name',
        className: 'min-w-[120px]',
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phone',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          SendWhatsappMessage(phoneNumber, '')
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      }
    ],
    []
  )

  const fetchConsultants = useCallback(async () => {
    try {
      const consultants = await getConsultants(token)

      const transformedConsultants = consultants.map(consultant => {
        return {
          id: consultant.id,
          name: consultant.name,
          phone: consultant.phone,
          email: consultant.email,
          referralId: consultant.referralId,
          createdAt: DateTime.fromISO(consultant.createdAt).toFormat(
            'dd/MM/yyyy'
          )
        }
      })

      setUserData(transformedConsultants)
      setFilteredUserData(transformedConsultants)
      setPaginatedUserData(transformedConsultants)
      setUserDataSearchResult(transformedConsultants)
      setPopupLoading(false)
    } catch (err) {
      console.error(err)
    } finally {
      setPopupLoading(false)
    }
  }, [token])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  const rowStyle = consultant => {
    if (selectedConsultant && selectedConsultant.id === consultant.id) {
      return {
        cursor: 'pointer',
        backgroundColor: 'var(--base-2)'
      }
    }

    return {
      cursor: 'pointer'
    }
  }

  const selectConsultantPopupContent = () => {
    const filterFunction = value => {
      setUserDataSearchResult(
        userData.filter(item => {
          return (
            item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.email.toLowerCase().includes(value.toLowerCase())
          )
        })
      )
    }

    return (
      <div className='change-consultant-popup-container'>
        <div className='search-bar-container change-consultant-popup-searchbar-container'>
          <SearchOutlined className='search-bar-category-icon' />
          <input
            onChange={e => filterFunction(e.target.value)}
            className='search-bar-input change-consultant-popup-searchbar'
            type='text'
            placeholder='Buscar por nome ou e-mail'
          />
        </div>
        <BasicTable
          itemsPerPage={3}
          filteredData={filteredUserData}
          tableHeaders={userTableHeaders}
          tableKeys={userTableKeys}
          paginatedData={paginatedUserData}
          setPaginatedData={setPaginatedUserData}
          onClickSortHeader={onClickSortHeader}
          selectedSortHeader={selectedSortHeader}
          sortOrientation={sortOrientation}
          rowStyle={rowStyle}
          onRowClick={consultant => {
            setSelectedConsultant(consultant)
            setHasSelectedConsultant(true)
          }}
        />
      </div>
    )
  }

  const selectConsultant = () => {
    if (!hasSelectedConsultant) {
      return
    }

    setIsChangeConsultantPopupShowing(false)
    setIsChangeConsultantConfirmationPopupShowing(true)
  }

  const indicationsPopupContent = () => {
    return (
      <div className='update-consultant-confirmation'>
        <div className='add-new-indication-text-button'>
          <h5 className='my-2'>Consultora que indicou</h5>
          <div className='add-new-indication-text-button-edit'>
            <p>{consultantWhoIndicated?.name}</p>
            <button
              onClick={() => {
                // This decision was made because of the atypical way the popup works, so loading works correctly.
                if (userData.length === 0) {
                  setPopupLoading(true)
                  fetchConsultants()
                }
                setIsChangeConsultantPopupShowing(true)
                setIsChangeConsultantConfirmationPopupShowing(false)
                setUserDataSearchResult(userData)
                setAddButtonType('buttonAddConsultantWhoIndicated')
              }}
              className={`add-new-indication-screen-button ${consultantWhoIndicated !==
                null && 'add-new-indication-screen-button-edit ml-6'}`}
            >
              {consultantWhoIndicated ? 'TROCAR' : 'ADICIONAR'}
            </button>
          </div>
        </div>
        <div className='add-new-indication-text-button'>
          <h5 className='my-2'>Consultora indicada</h5>
          <div className='add-new-indication-text-button-edit'>
            <p>{indicatedConsultant?.name}</p>
            {indicatedConsultant?.referralId ? (
              <>
                <button
                  onClick={() => {
                    setIsChangeConsultantConfirmationPopupShowing(false)
                    setIsChangeConsultantPopupShowing(true)
                    setConsultantWhoReferred(null)
                    setUserDataSearchResult(userData)
                    setAddButtonType('buttonAddIndicatedConsultant')
                  }}
                  className={`add-new-indication-screen-button ${indicatedConsultant !==
                    null && 'add-new-indication-screen-button-edit ml-6'}`}
                >
                  {indicatedConsultant ? 'TROCAR' : 'ADICIONAR'}
                </button>
                <p className='ml-4 add-new-indication-text'>
                  <ErrorOutline className='mr-1' />
                  Consultora já indicada pela consultora
                  <u
                    className='ml-1 cursor-pointer'
                    onClick={() =>
                      navigate('/consultoras/detalhes', {
                        state: {
                          user: { id: consultantWhoReferred.id },
                          pageOrigin: 'Indicações'
                        }
                      })
                    }
                  >
                    {consultantWhoReferred.name}
                  </u>
                  !
                </p>
              </>
            ) : (
              <button
                onClick={() => {
                  // This decision was made because of the atypical way the popup works, so loading works correctly.
                  if (userData.length === 0) {
                    setPopupLoading(true)
                    fetchConsultants()
                  }
                  setIsChangeConsultantConfirmationPopupShowing(false)
                  setIsChangeConsultantPopupShowing(true)
                  setUserDataSearchResult(userData)
                  setConsultantWhoReferred(null)
                  setAddButtonType('buttonAddIndicatedConsultant')
                }}
                className={`add-new-indication-screen-button ${indicatedConsultant !==
                  null && 'add-new-indication-screen-button-edit ml-6'}`}
              >
                {indicatedConsultant ? 'TROCAR' : 'ADICIONAR'}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  const consultantChangeOrLeadPopupContent = () => {
    return (
      <div className='update-consultant-confirmation'>
        {type === 'lead' ? <h3>Lead</h3> : <h3>Cliente</h3>}
        <p>{user.name}</p>
        {type !== 'lead' && (
          <>
            <h3>Consultora Atual</h3>
            <p>{user.consultantName}</p>
          </>
        )}
        {type === 'lead' ? (
          <h3>Converteu em consultora</h3>
        ) : (
          <h3>Nova consultora selecionada</h3>
        )}
        <div className='edit-client-screen-consultant-name update-consultant-confirmation-popup'>
          <p>{selectedConsultant.name}</p>
          <button
            onClick={() => {
              setIsChangeConsultantConfirmationPopupShowing(false)
              setIsChangeConsultantPopupShowing(true)
            }}
            className='edit-client-screen-button'
          >
            TROCAR
          </button>
        </div>
        <br />
        {type !== 'lead' && (
          <>
            <h3>Anexe uma imagem com a solicitação de troca da consultora:</h3>
            <div className='change-consultant-label-container'>
              <label
                htmlFor='change-consultant-file'
                className='change-consultant-file-label'
              >
                ANEXAR SOLICITAÇÃO
              </label>
              <p>
                {changeConsultantConfirmationFile &&
                  changeConsultantConfirmationFile.name}
              </p>
            </div>

            <input
              id='change-consultant-file'
              type='file'
              onChange={e =>
                e.target.files.length > 0
                  ? setChangeConsultantConfirmationFile(e.target.files[0])
                  : setChangeConsultantConfirmationFile(null)
              }
            />
          </>
        )}
      </div>
    )
  }

  const confirmationPopupContent = () => {
    if (type === 'indications') {
      return indicationsPopupContent()
    }
    if (type === 'editClient' || type === 'lead') {
      return consultantChangeOrLeadPopupContent()
    }
  }

  const handleChangeConsultant = async () => {
    if (type === 'editClient') {
      if (!changeConsultantConfirmationFile) {
        return errorToast(
          'Selecione a imagem com a solicitação de troca da consultora!'
        )
      }

      setPopupLoading(true)

      try {
        const base64File = await convertBlobToBase64(
          changeConsultantConfirmationFile
        )

        const selectedConsultantId = selectedConsultant.id
        const selectedConsultantName = selectedConsultant.name

        const body = {
          consultantId: selectedConsultantId,
          image: base64File
        }

        await changeConsultant(token, user.id, body)

        setUser({
          ...user,
          consultantId: selectedConsultantId,
          consultantName: selectedConsultantName
        })

        setChangeConsultantConfirmationFile(null)

        successToast('Troca de consultora realizada com sucesso!')
      } catch (err) {
        console.error(err)
      } finally {
        setIsChangeConsultantConfirmationPopupShowing(false)
        setPopupLoading(false)
        setShowPopup(false)
      }
    }
    if (type === 'lead') {
      setPopupLoading(true)
      try {
        const selectedConsultantId = selectedConsultant.id

        const body = {
          status: 'converted',
          consultantId: selectedConsultantId
        }

        await convertLead(token, user.id, body)

        setUser({
          ...user,
          consultantId: selectedConsultantId
        })

        navigate('/vendas/crm')
        successToast('Lead convertido com sucesso!')
      } catch (err) {
        console.log(err)
      } finally {
        setIsChangeConsultantConfirmationPopupShowing(false)
        setShowPopup(false)
        setPopupLoading(false)
      }
    }
    if (type === 'indications') {
      if (!changeConsultantConfirmationFile) {
        !consultantWhoIndicated
          ? errorToast('Campo consultora que indicou vazio!')
          : !indicatedConsultant
          ? errorToast('Campo consultora indicada vazio!')
          : consultantWhoReferred
          ? errorToast(
              'Esta consultora já foi indicada, selecione outra consultora!'
            )
          : errorToast('Por favor, preencha os campos!')
      } else {
        setPopupLoading(true)
        try {
          const body = {
            consultantWhoReferred: consultantWhoIndicated.id,
            consultantReferredId: indicatedConsultant.id
          }

          await addNewIndication(token, body)
          await refetchSelectConsultant()
          successToast('Indicação adicionada com sucesso!')
        } catch (err) {
          console.log(err)
        } finally {
          setIsChangeConsultantConfirmationPopupShowing(false)
          setShowPopup(false)
          setPopupLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    if (type === 'lead') {
      setTitle('Lead Convertido: Selecionar Consultora')
      setTextButton('Converter Lead')
      setChangeConsultantConfirmationFile('lead')
    }
    if (type === 'editClient') {
      setTitle('Selecione a nova consultora')
      setTextButton('SALVAR ALTERAÇÕES')
      setUsers(user)
    }
    if (type === 'indications') {
      setTitle('Selecione a nova consultora')
      setTextButton('Adicionar Indicação')
      setChangeConsultantConfirmationFile(null)
    }
  }, [type, setUsers, user])

  useEffect(() => {
    const copyUserDataSearchResult = [...userDataSearchResult]
    copyUserDataSearchResult.sort(
      sortDataByKey(userTableKeys[selectedSortHeader].key, sortOrientation)
    )

    setFilteredUserData(copyUserDataSearchResult)
  }, [userDataSearchResult, selectedSortHeader, sortOrientation, userTableKeys])

  useEffect(() => {
    if (type !== 'indications') {
      setPopupLoading(true)
      fetchConsultants()
    }
  }, [fetchConsultants, type])

  useEffect(() => {
    if (!consultantWhoIndicated && consultantWhoReferred) {
      setChangeConsultantConfirmationFile(null)
    }
    if (indicatedConsultant && !consultantWhoReferred) {
      setChangeConsultantConfirmationFile('indications')
    }
  }, [consultantWhoIndicated, consultantWhoReferred, indicatedConsultant])

  return (
    <div className='content-screen-container general-screen-container'>
      {isChangeConsultantPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={selectConsultantPopupContent()}
          onClosePopup={() => setShowPopup(false)}
          title={title}
          firstActionButtonText='SELECIONAR'
          firstActionButtonStyle={
            hasSelectedConsultant
              ? {
                  background: 'var(--linear-success-1)',
                  color: 'black'
                }
              : {
                  background: 'var(--base-4)',
                  color: 'white',
                  cursor: 'initial'
                }
          }
          onPressFirstActionButton={() => {
            if (type === 'indications') {
              setTitle('Adicionar Nova Indicação')
              // Clicked button is 'add consultant who referred' set the selected consultant in the table to 'consultantWhoIndicated
              if (addButtonType === 'buttonAddConsultantWhoIndicated') {
                setConsultantWhoIndicated(selectedConsultant)
              } else {
                // Clicked button is 'add referred consultant' check if in the referred consultant there is a referralId
                if (selectedConsultant.referralId) {
                  // Filter the consultant you referred using the referralId
                  const consultantWhoIndicated = userData.filter(
                    consultantId =>
                      consultantId.id === selectedConsultant.referralId
                  )
                  setChangeConsultantConfirmationFile(null)
                  setConsultantWhoReferred(consultantWhoIndicated[0])
                }
                setIndicatedConsultant(selectedConsultant)
              }
            }
            selectConsultant()
          }}
        />
      )}
      {isChangeConsultantConfirmationPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={confirmationPopupContent()}
          onClosePopup={() => setShowPopup(false)}
          title={title}
          secondActionButtonStyle={
            changeConsultantConfirmationFile
              ? {
                  width: 260,
                  background: 'var(--linear-success-1)',
                  color: 'black'
                }
              : {
                  width: 260,
                  background: 'var(--base-4)',
                  color: 'white',
                  cursor: 'initial'
                }
          }
          firstActionButtonStyle={{ width: 260 }}
          secondActionButtonText={textButton}
          onPressSecondActionButton={handleChangeConsultant}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() => setShowPopup(false)}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    token: user.token
  }
}

export default connect(mapStateToProps)(SelectConsultantPopup)
