import './styles/global.css'
import Router from './services/Router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { persistor, store } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { persistor } from 'redux-persist'

function App () {
  // let persistor = persistStore(store)
  return (
    <div className='flex app-container'>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router />
          <ToastContainer />
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
