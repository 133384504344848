import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import '../styles/components/FormButton.css'

const FormButton = ({ onSubmit, loading, type, className, children }) => {
  return (
    <button
      onSubmit={onSubmit}
      type={type || 'submit'}
      className={`form-button-container
        ${loading ? 'form-button-loading' : ''}
        ${className || ''}`}
    >
      {loading ? <TailSpin color='white' height='20' width='20' /> : children}
    </button>
  )
}

export default FormButton
