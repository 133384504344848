import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { DateRangeOutlined } from '@material-ui/icons'

import { DatePicker } from './DateTimePickers'
import '../styles/components/PeriodSelection.css'

const PeriodSelection = ({
  placeholder,
  label,
  firstDateInput,
  setFirstDateInput,
  secondDateInput,
  setSecondDateInput,
  onDone
}) => {
  let [showPeriodSelectionCard, setShowPeriodSelectionCard] = useState(false)

  useEffect(() => {
    !firstDateInput && setFirstDateInput('2019-01-01')
    !secondDateInput &&
      setSecondDateInput(DateTime.now().toFormat('yyyy-MM-dd'))
  }, [firstDateInput, secondDateInput, setFirstDateInput, setSecondDateInput])

  return (
    <div className='period-selection-container'>
      <div className='period-selection-label'>
        <p>{label ? label : 'Período: '}</p>
      </div>
      <div className='period-selection-div'>
        <div className='period-selection-button-container'>
          <button
            className='period-selection-button'
            onClick={() => setShowPeriodSelectionCard(!showPeriodSelectionCard)}
          >
            <div className='period-selection-icon-container'>
              <DateRangeOutlined className='period-selection-icon' />
            </div>
            <div className='period-selection-placeholder'>
              <p>{placeholder ? placeholder : 'Selecione o Período'}</p>
            </div>
          </button>
        </div>
        {/* TODO: make it responsive */}
        {showPeriodSelectionCard && (
          <div className='period-selection-card'>
            <div className='period-selection-separator' />
            <div className='period-selection-label-data-container'>
              <p className='mr-1'>De</p>
              <div className='mx-1 period-selection-data-container'>
                <DatePicker
                  className='period-selection-date-picker'
                  value={firstDateInput}
                  onChange={e => setFirstDateInput(e.target.value)}
                  max='2030-12-31'
                  min='2017-12-31'
                />
              </div>
            </div>
            <div className='period-selection-label-data-container'>
              <p className='mr-1'>Até</p>
              <div className='period-selection-data-container'>
                <DatePicker
                  className='period-selection-date-picker'
                  value={secondDateInput}
                  onChange={e => setSecondDateInput(e.target.value)}
                  max='2030-12-31'
                  min='2017-12-31'
                />
              </div>
            </div>
            <div className='flex justify-end mb-2 mt-6'>
              <button
                onClick={
                  onDone ? onDone : () => setShowPeriodSelectionCard(false)
                }
                className='period-selection-done-button'
              >
                Concluído
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PeriodSelection
