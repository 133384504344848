import React from 'react'

import BasicCard from './BasicCard'
import SearchBar from './SearchBar'
import PeriodSelection from './PeriodSelection'

import { CheckBoxOutlineBlank } from '@material-ui/icons'
import { CheckRounded } from '@material-ui/icons'

import '../styles/components/FilterCard.css'

const FilterCard = ({
  itemList,
  setFilteredItemsList,
  filterList,
  firstDateInput,
  setFirstDateInput,
  secondDateInput,
  setSecondDateInput,
  onDoneDate,
  bottomContent,
  onSearchBarSubmit,
  onChangeSearchBar,
  searchBarSubmitEnabled,
  searchBarPlaceHolder
}) => {
  const checkBox = isSelected => {
    return (
      <div className='check-box-div'>
        <CheckBoxOutlineBlank className='check-box check-box-icon' />
        {isSelected && <CheckRounded className='check-box check-icon' />}
      </div>
    )
  }

  const filterItem = (filter, key) => {
    return (
      <div
        key={key}
        className='filter-card-filter-item'
        onClick={() => filter.setFilter(!filter.filterState)}
      >
        {checkBox(filter.filterState)}
        <p className='filter-card-filter-item-text'>{filter.label}</p>
        {filter.icon}
      </div>
    )
  }

  const filterCardContent = () => {
    return (
      <div className='filter-card-content'>
        <div className='filter-card-content-sec1'>
          <div className='filter-card-content-left'>
            {filterList && (
              <div className='filter-card-filters'>
                <div className='grid grid-cols-1 gap-x-14 md:grid-cols-2'>
                  {filterList.map((filter, key) => {
                    return filterItem(filter, key)
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='filter-card-content-right'>
            <div className='filter-card-search-bar-container'>
              <SearchBar
                placeholder={searchBarPlaceHolder && searchBarPlaceHolder}
                onChange={onChangeSearchBar}
                itemList={itemList}
                setFilteredItemsList={setFilteredItemsList}
              />
              {onSearchBarSubmit && (
                <button
                  disabled={!searchBarSubmitEnabled}
                  onClick={onSearchBarSubmit}
                  className='search-bar-button'
                >
                  Buscar
                </button>
              )}
            </div>
            <div className='mt-6'>
              {setFirstDateInput && setSecondDateInput && (
                <PeriodSelection
                  firstDateInput={firstDateInput}
                  setFirstDateInput={setFirstDateInput}
                  secondDateInput={secondDateInput}
                  setSecondDateInput={setSecondDateInput}
                  onDone={onDoneDate}
                />
              )}
            </div>
          </div>
        </div>
        {bottomContent}
      </div>
    )
  }

  return <BasicCard cardContent={filterCardContent()} />
}

export default FilterCard
