import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'

import { connect } from 'react-redux'
import { getUserExperience, getDaysLeft } from '../helpers/SalesHelpers'
import { errorToast, successToast } from '../helpers/ToastHelper'
import {
  getLastLogin,
  getTotalUserExperience,
  textToZipCode,
  getSubscriptionTextIndicator,
  getSuccessRateTextIndicator,
  getProgessBarColor,
  getPlanName
} from '../helpers/ConsultantDetailsHelpers'
import SelectConsultantPopup from '../components/SelectConsultantPopup'
import SendWhatsappMessage from '../services/SendWhatsappMessage'

import FormInput from '../components/FormInput'
import Gauge from '../components/Gauge'
import BasicCard from '../components/BasicCard'
import SimpleActionPopup from '../components/SimpleActionPopup'
import { DatePicker } from '../components/DateTimePickers'
import ProgressBar from '../components/ProgressBar'
import CancellationReasonPopUp from '../components/CancellationReasonPopUp'

import {
  WhatsApp,
  EditOutlined,
  BallotOutlined,
  AddRounded,
  DeleteOutline
} from '@material-ui/icons'
import EditIcon from '../assets/images/edit.svg'
import {
  addConsultantContact,
  deleteConsultantContact,
  editConsultantContact,
  getConsultantContactHistory,
  getUserDetailedData,
  extendUserSubscription,
  editConsultantEmail,
  convertLead
} from '../services/utils'

import '../styles/containers/ConsultantDetails.css'
import SubscriptionHistoryPopup from '../components/SubscriptionHistoryPopup'

const ConsultantDetails = props => {
  let location = useLocation()
  let navigate = useNavigate()

  let [showContactHistoryPopup, setShowContactHistoryPopup] = useState(false)
  let [showAddContactItemPopup, setShowAddContactItemPopup] = useState(false)
  let [showEditContactItemPopup, setShowEditContactItemPopup] = useState(false)
  let [showDeleteContactItemPopup, setShowDeleteContactItemPopup] = useState(
    false
  )
  let [
    showExtendUserSubscriptionPopup,
    setShowExtendUserSubscriptionPopup
  ] = useState(false)
  let [
    showSubscriptionHistoryPopup,
    setShowSubscriptionHistoryPopup
  ] = useState(false)
  let [
    showEditConsultantEmailPopup,
    setShowEditConsultantEmailPopup
  ] = useState(false)

  let [popupLoading, setPopupLoading] = useState(false)

  //variables for popup reason cancellation
  let [showReasonCancellationPopup, setShowReasonCancellationPopup] = useState(
    false
  )
  let [
    selectedUserCancellationReasonPopup,
    setSelectedUserCancellationReasonPopup
  ] = useState(null)
  let [reasonCancellationType, setReasonCancellationType] = useState(false)

  let [newContactItemDate, setNewContactItemDate] = useState('')
  let [newContactItemMessage, setNewContactItemMessage] = useState('')

  let [contactToEdit, setContactToEdit] = useState('')
  let [editContactItemDate, setEditContactItemDate] = useState('')
  let [editContactItemMessage, setEditContactItemMessage] = useState('')

  let [previousConsultantEmail, setPreviousConsultantEmail] = useState('')
  let [newConsultantEmail, setNewConsultantEmail] = useState('')

  let [contactToDeleteId, setContactToDeleteId] = useState(0)

  let [isLoading, setIsLoading] = useState(true)
  let [user, setUser] = useState(null)

  let [
    isLeadLossConfirmationPopupShowing,
    setIsLeadLossConfirmationPopupShowing
  ] = useState(false)

  /* Change popup consultant states */
  let [showPopup, setShowPopup] = useState(false)

  const fetchUser = useCallback(async () => {
    const transformUserData = userData => {
      let transformedUser = userData

      transformedUser.firstLetter = userData.name.slice(0, 1).toUpperCase()
      transformedUser.daysLeft =
        userData.validUntilDay && getDaysLeft(userData.validUntilDay, true)
      transformedUser.subscriptionPeriod = getTotalUserExperience(
        0,
        userData.daysSubscribed
      )

      if (userData.currentPlan === 'Teste') {
        transformedUser.rateIndicator = userData.subscriptionChance
      } else {
        transformedUser.rateIndicator = userData.successRate
      }

      transformedUser.lastLoginDate =
        userData.lastLogin && getLastLogin(userData.lastLogin)
      if (userData.daysSubscribed) {
        transformedUser.experience = `Consultora há ${getTotalUserExperience(
          userData.experienceId,
          userData.daysSubscribed
        )}`
      } else {
        transformedUser.experience = `${getUserExperience(
          userData.experienceId
        )} de experiência`
      }

      if (userData.contactHistory.length > 0) {
        userData.lastContactDate = `Último contato em ${DateTime.fromISO(
          transformedUser.contactHistory[0].date
        ).toFormat('dd/MM/yyyy')}`
      } else {
        transformedUser.lastContactDate = 'Nenhum contato feito'
      }

      transformedUser.address = getUserAddress(userData)

      return transformedUser
    }

    const transformLeadData = userData => {
      let transformedUser = userData

      transformedUser.firstLetter = userData.name.slice(0, 1).toUpperCase()

      if (userData.contactHistory.length > 0) {
        userData.lastContactDate = `Último contato em ${DateTime.fromISO(
          transformedUser.contactHistory[0].date
        )
          .toUTC()
          .toFormat('dd/MM/yyyy')}`
      } else {
        transformedUser.lastContactDate = 'Nenhum contato feito'
      }

      transformedUser.address = getUserAddress(userData)

      return transformedUser
    }

    if (location.state.user) {
      await getUserDetailedData(
        props.token,
        location.state.user.id,
        location.state.user.isLead
      )
        .then(consultantDetailed => {
          const isTest =
            (consultantDetailed.subscriptionPlanIdentifier === 1 || consultantDetailed.subscriptionPlanIdentifier === 334) &&
            (consultantDetailed.subscriptionStatus === 'active' ||
              consultantDetailed.subscriptionStatus === 'awaitingCancel') &&
            (!consultantDetailed.subscriptionValidUntilDay ||
              DateTime.fromISO(
                consultantDetailed.subscriptionValidUntilDay
              ).diffNow() > 0)
          let userData
          let user

          if (!consultantDetailed.adminId) {
            userData = {
              id: consultantDetailed.id,
              name: consultantDetailed.name,
              email: consultantDetailed.email,
              phoneNumber: consultantDetailed.phone,
              city: consultantDetailed.city,
              state: consultantDetailed.state,
              status: consultantDetailed.status,
              consultantId: consultantDetailed.consultantId,
              zipCode: consultantDetailed.zipCode,
              subscriptionDate: DateTime.fromISO(
                consultantDetailed.subscriptionCreatedAt
              ).toFormat('yyyy-MM-dd'),
              currentPlan: isTest
                ? 'Teste'
                : getPlanName(consultantDetailed.subscriptionPlanIdentifier),
              createdAt: DateTime.fromISO(
                consultantDetailed.createdAt
              ).toFormat('yyyy-MM-dd'),
              experienceId: consultantDetailed.experienceId,
              validUntilDay:
                consultantDetailed.subscriptionValidUntilDay &&
                DateTime.fromISO(consultantDetailed.subscriptionValidUntilDay),
              lastLogin: null,
              // consultantDetailed.updatedAt &&
              // DateTime.fromISO(consultantDetailed.updatedAt).toFormat(
              //   'yyyy-MM-dd'
              // ),
              subscriptionChance: consultantDetailed.subscriptionChance * 100,
              subscriptionStatus: consultantDetailed.subscriptionStatus,
              successRate: consultantDetailed.subscriptionChance * 100,
              daysSubscribed: DateTime.now()
                .diff(
                  DateTime.fromISO(consultantDetailed.subscriptionCreatedAt),
                  'day'
                )
                .get('days'),
              contactHistory: consultantDetailed.contactHistory.map(
                contact => ({
                  id: contact.id,
                  date: DateTime.fromISO(contact.contactDate).toFormat(
                    'yyyy-MM-dd'
                  ),
                  message: contact.contactDetails,
                  responsibleUser: contact.responsibleUser
                })
              ),
              cancellationReasonHistory: consultantDetailed.cancellationReasonHistory.map(
                reason => ({
                  id: reason.id,
                  date: DateTime.fromISO(reason.createdAt)
                    .toUTC()
                    .toFormat('dd/MM/yyyy'),
                  reason: reason.cancellationShortReason,
                  details: reason.cancellationDetails,
                  responsibleUser: reason.responsibleUser
                })
              ),
              clientData: [
                {
                  clientCount: consultantDetailed.clientDetails.createdClients,
                  clientInfo: 'Clientes adicionadas',
                  idealNumber: isTest ? 3 : 5
                },
                {
                  clientCount:
                    consultantDetailed.clientDetails.confirmedClients,
                  clientInfo: 'Clientes com contas confirmadas',
                  idealNumber: Math.ceil(
                    isTest
                      ? 0.5 * consultantDetailed.clientDetails.createdClients
                      : 0.7 * consultantDetailed.clientDetails.createdClients
                  )
                }
              ],
              featureUsageData: [
                {
                  featureCount:
                    consultantDetailed.clientDetails.personalColoring,
                  featureInfo: 'Clientes com Coloração',
                  idealNumber: isTest
                    ? Math.ceil(
                        0.5 * consultantDetailed.clientDetails.createdClients
                      )
                    : Math.ceil(
                        0.6 * consultantDetailed.clientDetails.createdClients
                      )
                },
                {
                  featureCount: consultantDetailed.clientDetails.virtualCloset,
                  featureInfo: 'Clientes com Closet Virtual',
                  idealNumber: isTest
                    ? Math.ceil(
                        0.5 * consultantDetailed.clientDetails.createdClients
                      )
                    : Math.ceil(
                        0.6 * consultantDetailed.clientDetails.createdClients
                      )
                },
                {
                  featureCount: consultantDetailed.clientDetails.customAboutMe,
                  featureInfo: 'Clientes com Sobre Mim',
                  idealNumber: isTest
                    ? Math.ceil(
                        0.5 * consultantDetailed.clientDetails.createdClients
                      )
                    : Math.ceil(
                        0.5 * consultantDetailed.clientDetails.createdClients
                      )
                }
              ],
              totalClients: consultantDetailed.clientDetails.createdClients
            }

            // If the user is not a test user, add 2 new 'featureUsageData', which is the list of 'Style Guide' and 'Shopping List'.
            // This is because the back-end was removed from the calculation of chance of signing, these two functionalities that the user (Testing) did not have access to.
            if (!isTest){
              userData= {
                ...userData,
                featureUsageData: [
                  ...userData.featureUsageData,
                  {
                    featureCount: consultantDetailed.clientDetails.moodboardTotal,
                    featureInfo: 'Clientes com Guia Template Mosty',
                    idealNumber: isTest
                      ? Math.ceil(
                          0.5 * consultantDetailed.clientDetails.createdClients
                        )
                      : Math.ceil(
                          0.5 * consultantDetailed.clientDetails.createdClients
                        )
                  },
                  {
                    featureCount: consultantDetailed.clientDetails.shopListTotal,
                    featureInfo: 'Clientes com Lista de Compras',
                    idealNumber: isTest
                      ? Math.ceil(
                          0.5 * consultantDetailed.clientDetails.createdClients
                        )
                      : Math.ceil(
                          0.5 * consultantDetailed.clientDetails.createdClients
                        )
                  }
                ]
              }
            }

            user = transformUserData(userData)
          } else {
            userData = {
              id: consultantDetailed.id,
              name: consultantDetailed.name,
              email: consultantDetailed.email,
              phoneNumber: consultantDetailed.phone,
              instagram: consultantDetailed.instagram,
              city: consultantDetailed.city,
              state: consultantDetailed.state,
              status: consultantDetailed.status,
              consultantId: consultantDetailed.consultantId,
              zipCode: consultantDetailed.zipCode,
              contactHistory: consultantDetailed.contactHistory.map(
                contact => ({
                  id: contact.id,
                  date: DateTime.fromISO(contact.contactDate)
                    .toUTC()
                    .toFormat('yyyy-MM-dd'),
                  message: contact.contactDetails,
                  responsibleUser: contact.responsibleUser
                })
              ),
              adminId: consultantDetailed.adminId
            }
            user = transformLeadData(userData)
          }
          setUser(user)
          setSelectedUserCancellationReasonPopup(user)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => setIsLoading(false))
    } else {
      navigate('/')
    }
  }, [location.state.user, navigate, props.token])

  const getUserAddress = userData => {
    let infoToJoin = []
    let cityAndState = []

    if (userData.city) {
      cityAndState.push(userData.city)
    }

    if (userData.state) {
      cityAndState.push(userData.state)
    }

    const cityAndStateJoined = cityAndState.join(' - ')

    if (userData.streetName) {
      infoToJoin.push(userData.streetName)
    }

    if (userData.streetNumber) {
      infoToJoin.push(userData.streetNumber)
    }

    if (userData.complement) {
      infoToJoin.push(userData.complement)
    }

    if (userData.district) {
      infoToJoin.push(userData.district)
    }

    if (cityAndStateJoined) {
      infoToJoin.push(cityAndStateJoined)
    }

    if (userData.zipCode) {
      infoToJoin.push(textToZipCode(userData.zipCode))
    }

    return infoToJoin.length === 0 ? undefined : infoToJoin.join(', ')
  }

  const contactHistoryPopupContent = selectedUser => {
    const contactCardContent = contactItem => {
      return (
        <div className='px-4 py-4 grid grid-cols-8'>
          <div className='col-span-7'>
            <div className='mb-6'>
              <p className='big-paragraph font-medium'>
                {DateTime.fromFormat(contactItem.date, 'yyyy-MM-dd').toFormat(
                  'dd/MM/yyyy'
                )}{' '}
                - {contactItem.responsibleUser}
              </p>
            </div>
            <div>
              <p>"{contactItem.message}"</p>
            </div>
          </div>
          <div className='flex flex-col'>
            <div
              onClick={() => {
                setContactToDeleteId(contactItem.id)
                setShowDeleteContactItemPopup(true)
              }}
              className='mb-4 self-end cursor-pointer'
            >
              <DeleteOutline className='delete-card-icon' />
            </div>
            <div
              className='self-end cursor-pointer'
              onClick={() => {
                setContactToEdit(contactItem.id)
                setEditContactItemDate(contactItem.date)
                setEditContactItemMessage(contactItem.message)
                setShowContactHistoryPopup(false)
                setShowEditContactItemPopup(true)
              }}
            >
              <img src={EditIcon} alt='Editar usuário' />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='grid grid-cols-1 max-w-[670px] m-auto max-h-[300px] overflow-auto p-2'>
          {selectedUser.contactHistory.length > 0 ? (
            selectedUser.contactHistory.map((contactItem, key) => {
              return (
                <div key={key} className='mb-6'>
                  <BasicCard cardContent={contactCardContent(contactItem)} />
                </div>
              )
            })
          ) : (
            <div>
              <p>Usuário não possui histórico de contato.</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const CustomAddButton = type => {
    return (
      <button
        className='testing-consultant-add-contact-item-button'
        onClick={addEditContactItemPopup}
      >
        <AddRounded className='basic-table-add-icon' />
      </button>
    )
  }

  const fetchUserContactHistory = async userId => {
    const contactHistoryToMap = await getConsultantContactHistory(
      props.token,
      userId,
      user.adminId ? 'lead' : 'consultant'
    )

    const contactHistory = contactHistoryToMap.map(contact => ({
      id: contact.id,
      message: contact.contactDetails,
      responsibleUser: contact.responsibleUser,
      date: DateTime.fromISO(contact.contactDate)
        .toUTC()
        .toFormat('yyyy-MM-dd')
    }))

    setUser({
      ...user,
      contactHistory
    })
  }

  // TODO: add submit add contact message logic
  const addEditContactItemPopup = () => {
    setShowContactHistoryPopup(false)
    setShowAddContactItemPopup(true)
  }

  const deleteContactItem = async () => {
    setPopupLoading(true)
    try {
      await deleteConsultantContact(props.token, contactToDeleteId)
      await fetchUserContactHistory(location.state.user.id)
      successToast('Contato deletado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowDeleteContactItemPopup(false)
      setPopupLoading(false)
    }
  }

  const editContactItem = async () => {
    setPopupLoading(true)

    try {
      await editConsultantContact(props.token, contactToEdit, {
        contactDetails: editContactItemMessage,
        contactDate: editContactItemDate
      })
      await fetchUserContactHistory(location.state.user.id)

      setShowEditContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato editado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const submitAddContactItem = async () => {
    setPopupLoading(true)
    let dataToPost = {
      contactDate: newContactItemDate,
      contactDetails: newContactItemMessage
    }

    if (!user.adminId) {
      dataToPost.consultantId = location.state.user.id
    } else {
      dataToPost.leadId = location.state.user.id
    }

    try {
      await addConsultantContact(props.token, dataToPost)
      await fetchUserContactHistory(location.state.user.id)
      setShowAddContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato criado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const extendUserSubscriptionPeriod = async () => {
    setPopupLoading(true)

    const userValidUntilDay = location.state.user.validUntilDay
    const userValidUntilDayDiffNow =
      userValidUntilDay &&
      DateTime.fromFormat(userValidUntilDay, 'yyyy-MM-dd').diffNow()

    if (userValidUntilDay != null && !(userValidUntilDayDiffNow <= 7)) {
      setPopupLoading(false)
      return errorToast(
        'O usuário deve ter a data de validade da assinatura nos próximos 7 dias para o teste poder ser estendido'
      )
    }

    try {
      await extendUserSubscription(props.token, location.state.user.id)
      await fetchUser()
      setShowExtendUserSubscriptionPopup(false)
      successToast('Teste estendido com sucesso!')
    } catch (e) {
      console.log(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const addContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={newContactItemDate}
            onChange={e => setNewContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={newContactItemMessage}
            onChange={e => setNewContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - newContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const editContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={editContactItemDate}
            onChange={e => setEditContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={editContactItemMessage}
            onChange={e => setEditContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - editContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const deleteContactItemContent = () => {
    return <div className='text-xl'>Deseja realmente deletar este contato?</div>
  }

  const extendUserSubscriptionContent = () => {
    return (
      <div className='text-xl'>
        Deseja realmente estender o período de teste deste usuário?
      </div>
    )
  }

  const clientCardContent = ({ clientCount, clientInfo, idealNumber }) => {
    return (
      <div className='py-4 px-4 max-w-[200px]' style={{ height: '100%' }}>
        <div className='flex flex-col items-center mb-6'>
          <p className='consultant-details-client-count mb-2'>{clientCount}</p>
          <p className='consultant-details-client-info text-center'>
            {clientInfo}
          </p>
        </div>
        <div className='flex flex-col items-center mb-2'>
          <p className='small-paragraph consultant-details-client-ideal'>
            Mínimo ideal: {idealNumber}
          </p>
          <p className='small-paragraph consultant-details-client-ideal'>
            Faltam:{' '}
            {clientCount >= idealNumber ? '0' : idealNumber - clientCount}
          </p>
        </div>
      </div>
    )
  }

  const featureUsageInformation = (data, key) => {
    return (
      <div key={key}>
        <p className='mb-2'>{data.featureInfo}</p>
        <ProgressBar
          bgcolor={getProgessBarColor(
            data.featureCount,
            data.idealNumber,
            user.totalClients
          )}
          count={data.featureCount}
          total={user.totalClients}
          ideal={data.idealNumber !== 0 ? data.idealNumber : null}
        />
      </div>
    )
  }

  const editConsultantEmailPopupContent = user => {
    return (
      <div className='edit-consultant-email-popup-container'>
        <p className='edit-consultant-email-popup-subtitle'>Editar email</p>
        <p className='edit-consultant-email-popup-warning'>
          <strong>Cuidado!</strong> Após a troca, a consultora só conseguirá{' '}
          <strong>acessar a conta</strong> através deste{' '}
          <strong>novo e-mail! Confira os dados</strong> antes de finalizar a
          troca.
        </p>

        <FormInput
          placeholder='emailantigo@gmail.com'
          label='E-mail antigo'
          value={previousConsultantEmail}
          type='text'
          onChange={e => setPreviousConsultantEmail(e.target.value)}
        />
        <FormInput
          placeholder='emailnovo@gmail.com'
          label='Novo e-mail'
          value={newConsultantEmail}
          type='text'
          onChange={e => setNewConsultantEmail(e.target.value)}
        />
      </div>
    )
  }

  const handleEditConsultantEmail = async () => {
    if (previousConsultantEmail !== user.email) {
      return errorToast(`O antigo e-mail da consultora está incorreto!`)
    }

    if (!newConsultantEmail) {
      return errorToast('Digite o novo e-mail da consultora!')
    }

    setPopupLoading(true)

    try {
      await editConsultantEmail(props.token, user.id, {
        email: newConsultantEmail
      })
      await fetchUser()

      setPreviousConsultantEmail('')
      setNewConsultantEmail('')

      setShowEditConsultantEmailPopup(false)

      successToast('E-mail editado com sucesso!')
    } catch (err) {
      console.error(err)
    } finally {
      setPopupLoading(false)
    }
  }

  const leadLossConfirmationPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja{' '}
          <strong>finalizar esse lead</strong> e marcá-lo como{' '}
          <strong>"perdido"</strong>?
        </p>
      </div>
    )
  }

  const handleLeadLoss = async () => {
    setPopupLoading(true)
    try {
      const body = {
        status: 'lost'
      }

      await convertLead(props.token, user.id, body)

      navigate('/vendas/crm')
      successToast('Lead finalizado com sucesso!')
    } catch (err) {
      console.error(err)
    } finally {
      setIsLeadLossConfirmationPopupShowing(false)
      setPopupLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  if (isLoading) return <div>Carregando...</div>

  return (
    <div className='content-screen-container grid grid-cols-1 md:grid-cols-3 overflow-auto'>
      <div className='general-screen-container col-span-2 overflow-auto min-h-[700px]'>
        <div className='main-screen-header-container'>
          <h2>{location.state.pageOrigin} - Detalhes</h2>
        </div>
        <div className='mb-4'>
          <h4 className='my-data-section-header'>Dados sobre Clientes</h4>
        </div>
        <div className='flex overflow-auto px-2 py-2 mb-8'>
          {user.clientData ? (
            user.clientData.map((data, key) => {
              return (
                <div key={key} className='mr-4'>
                  <BasicCard cardContent={clientCardContent(data)} />
                </div>
              )
            })
          ) : (
            <p>
              Usuário não possui dados de clientes no momento, pois
              provavelmente é um Lead.
            </p>
          )}
        </div>
        <div className='mb-4'>
          <h4 className='my-data-section-header'>
            Dados sobre Uso de Funcionalidades
          </h4>
        </div>
        <div className=''>
          {user.featureUsageData ? (
            user.featureUsageData.map((data, key) => {
              return featureUsageInformation(data, key)
            })
          ) : (
            <p>
              Usuário não possui dados de funcionalidades no momento, pois
              provavelmente é um Lead.
            </p>
          )}
        </div>
      </div>
      <div className='col-span-1 consultant-details-side-card-container'>
        <div className='flex items-center mt-8 mb-8'>
          <div className='consultant-details-photo-container'>
            {user.firstLetter}
          </div>
          <p className='font-medium ml-3'>{user.name}</p>
        </div>
        <div className='font-poppins'>
          {user.phoneNumber && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <div onClick={() => SendWhatsappMessage(user.phoneNumber, '')}>
                <p className='ml-4 small-paragraph underline cursor-pointer'>
                  {user.phoneNumber}
                  <WhatsApp className='whatsapp-table-data-icon consultant-details-icon mt-[-4px]' />
                </p>
              </div>
            </div>
          )}
          {user.email && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <div
                onClick={() => {
                  // TODO: Add edit e-mail popup and function
                }}
              >
                <p
                  className='ml-4 small-paragraph break-all underline cursor-pointer'
                  onClick={() => {
                    setPreviousConsultantEmail('')
                    setNewConsultantEmail('')
                    setShowEditConsultantEmailPopup(true)
                  }}
                >
                  {user.email}
                  <EditOutlined className='whatsapp-table-data-icon consultant-details-icon mt-[-4px]' />
                </p>
              </div>
            </div>
          )}
          {user.currentPlan && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <div
                onClick={() => {
                  // TODO: Add edit user plan popup and function
                }}
              >
                <p
                  onClick={() => setShowSubscriptionHistoryPopup(true)}
                  className='ml-4 small-paragraph underline cursor-pointer'
                >
                  {user.subscriptionStatus === 'cancelled' ? (
                    <>
                      Assinatura do plano <b>{user.currentPlan}</b> cancelada.
                    </>
                  ) : (
                    <>
                      <b>{user.daysLeft}</b>
                      {user.currentPlan === 'Teste'
                        ? ' de teste'
                        : ` no plano ${user.currentPlan}`}
                    </>
                  )}

                  <EditOutlined className='whatsapp-table-data-icon consultant-details-icon mt-[-4px]' />
                </p>
              </div>
            </div>
          )}
          {user.cancellationReasonHistory &&
            user.currentPlan !== 'Teste' &&
            user.currentPlan !== 'Plano Especial Gratuito' &&
            (user.cancellationReasonHistory.length !== 0 ? (
              <div className='flex mb-4'>
                <div className='item-thin-trace' />
                <div
                  onClick={() => {
                    setShowReasonCancellationPopup(true)
                    setReasonCancellationType('reasonCancellation')
                  }}
                >
                  <p className='ml-4 small-paragraph underline cursor-pointer'>
                    {user.cancellationReasonHistory[0]?.reason}
                    <EditOutlined className='whatsapp-table-data-icon consultant-details-icon mt-[-4px]' />
                  </p>
                </div>
              </div>
            ) : (
              <div className='flex mb-4'>
                <div className='item-thin-trace' />
                <div
                  onClick={() => {
                    setShowReasonCancellationPopup(true)
                    setReasonCancellationType('add')
                  }}
                >
                  <p className='ml-4 small-paragraph underline cursor-pointer'>
                    Adicionar Motivo de Cancelamento
                    <AddRounded className='cancellations-add-icon consultant-details-icon' />
                  </p>
                </div>
              </div>
            ))}
          {user.lastLoginDate && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <p className='ml-4 small-paragraph'>
                Último login há {user.lastLoginDate}
              </p>
            </div>
          )}
          {user.subscriptionPeriod && user.currentPlan !== 'Teste' && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <p className='ml-4 small-paragraph'>
                Assina há {user.subscriptionPeriod}
              </p>
            </div>
          )}
          {user.experience && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <p className='ml-4 small-paragraph'>{user.experience}</p>
            </div>
          )}
          <div className='flex mb-4'>
            <div className='item-thin-trace' />
            <div
              onClick={() => {
                setShowContactHistoryPopup(true)

                // TODO: Add edit e-mail popup and function
              }}
            >
              <p className='ml-4 small-paragraph underline cursor-pointer'>
                {user.lastContactDate}
                <BallotOutlined className='ballot-table-data-icon consultant-details-icon mt-[-4px]' />
              </p>
            </div>
          </div>
          {user.address && (
            <div className='flex mb-4'>
              <div className='item-thin-trace' />
              <p className='ml-4 small-paragraph'>{user.address}</p>
            </div>
          )}
          {user.status &&
            (user.status === 'lost' ? (
              <div className='flex mb-4'>
                <div className='item-thin-trace' />
                <p className='ml-4 small-paragraph'>Lead perdido</p>
              </div>
            ) : user.status === 'converted' ? (
              <div className='flex mb-4'>
                <div className='item-thin-trace' />
                <p className='ml-4 small-paragraph'>
                  Lead Convertido.&nbsp;
                  <u
                    className='underline cursor-pointer'
                    onClick={() => {
                      navigate('/consultoras/detalhes', {
                        state: {
                          user: { id: user.consultantId, isLead: false },
                          pageOrigin: 'CRM'
                        }
                      })
                    }}
                  >
                    Clique aqui para ver a consultora correspondente
                  </u>
                </p>
              </div>
            ) : null)}
        </div>
        {user.currentPlan === 'Teste' && (
          <div className='mt-6 mb-6'>
            <button
              className='consultant-details-extend-trial-button success-button'
              onClick={() => setShowExtendUserSubscriptionPopup(true)}
            >
              ESTENDER TESTE
            </button>
          </div>
        )}
        {!user.featureUsageData && user.status === 'open' && (
          <>
            <div className='mt-6 mb-6'>
              <button
                className='consultant-details-extend-trial-button success-button'
                onClick={() => {
                  setShowPopup(true)
                }}
              >
                Finalizar Lead: Convertido
              </button>
            </div>
            <div className='mt-6 mb-6'>
              <button
                className='consultant-details-extend-trial-button danger-button'
                onClick={() => setIsLeadLossConfirmationPopupShowing(true)}
              >
                Finalizar Lead: Perdido
              </button>
            </div>
          </>
        )}
        {(user.rateIndicator || user.rateIndicator === 0) && (
          <div>
            <p className='text-center font-poppins mb-4'>Indicador</p>
            <div className='flex justify-center'>
              <Gauge value={user.rateIndicator} />
            </div>
            <div className='mt-8 flex flex-col items-center'>
              <p className='small-paragraph font-poppins mb-2'>
                {user.currentPlan === 'Teste'
                  ? getSubscriptionTextIndicator(user.rateIndicator)
                  : getSuccessRateTextIndicator(user.rateIndicator)}
              </p>
              <p className='small-paragraph font-poppins mb-2'>
                {user.currentPlan === 'Teste'
                  ? `${user.rateIndicator.toFixed(0)}% de chance de assinar`
                  : `${(100 - user.rateIndicator).toFixed(
                      0
                    )}% de chance de cancelar`}
              </p>
            </div>
          </div>
        )}
      </div>
      {showContactHistoryPopup && (
        <SimpleActionPopup
          title={`Histórico de Contato - ${user.name}`}
          content={contactHistoryPopupContent(user)}
          customSecondActionButton={<CustomAddButton />}
          onClosePopup={() => setShowContactHistoryPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showAddContactItemPopup && (
        <SimpleActionPopup
          title={`Adicionar Histórico de Contato - ${user.name}`}
          content={addContactItemContent(user)}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddContactItem}
          onClosePopup={() => setShowAddContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showEditContactItemPopup && (
        <SimpleActionPopup
          title={`Editar Histórico de Contato - ${location.state.user.name}`}
          content={editContactItemContent(location.state.user)}
          secondActionButtonText={'EDITAR'}
          onPressSecondActionButton={editContactItem}
          onClosePopup={() => setShowEditContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showDeleteContactItemPopup && (
        <SimpleActionPopup
          title={`Deletar contato - ${location.state.user.name}`}
          content={deleteContactItemContent(location.state.user)}
          firstActionButtonText={'CANCELAR'}
          secondActionButtonText={'DELETAR'}
          onPressSecondActionButton={deleteContactItem}
          onPressFirstActionButton={() => setShowDeleteContactItemPopup(false)}
          onClosePopup={() => setShowDeleteContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showExtendUserSubscriptionPopup && (
        <SimpleActionPopup
          title={`Estender teste - ${location.state.user.name}`}
          content={extendUserSubscriptionContent(location.state.user)}
          firstActionButtonText={'CANCELAR'}
          secondActionButtonText={'ESTENDER'}
          onPressSecondActionButton={extendUserSubscriptionPeriod}
          onPressFirstActionButton={() =>
            setShowExtendUserSubscriptionPopup(false)
          }
          onClosePopup={() => setShowExtendUserSubscriptionPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showSubscriptionHistoryPopup &&
        location.state.user &&
        location.state.user.id && (
          <SubscriptionHistoryPopup
            refetch={fetchUser}
            userId={location.state.user.id}
            userName={user.name}
            setShowPopup={setShowSubscriptionHistoryPopup}
          />
        )}
      {showEditConsultantEmailPopup && location.state.user && (
        <SimpleActionPopup
          title={location.state.user.name}
          content={editConsultantEmailPopupContent(location.state.user)}
          secondActionButtonText='Trocar e-mail'
          onPressSecondActionButton={handleEditConsultantEmail}
          onClosePopup={() => setShowEditConsultantEmailPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showPopup && (
        <SelectConsultantPopup
          type={'lead'}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
      {isLeadLossConfirmationPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={leadLossConfirmationPopupContent()}
          onClosePopup={() => setIsLeadLossConfirmationPopupShowing(false)}
          title='Finalizar Lead: Perdido'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='LEAD PERDIDO'
          onPressSecondActionButton={handleLeadLoss}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setIsLeadLossConfirmationPopupShowing(false)
          }
        />
      )}
      {showReasonCancellationPopup && (
        <CancellationReasonPopUp
          user={selectedUserCancellationReasonPopup}
          refetch={fetchUser}
          type={reasonCancellationType}
          showPopup={showReasonCancellationPopup}
          setShowPopup={setShowReasonCancellationPopup}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(ConsultantDetails)
