import { DateTime } from 'luxon'

export const summarizeIuguCode = codIugu => {
  const startCode = codIugu?.toString().substring(0, 4)
  const endCode = codIugu?.toString().substring(28, 32)

  if (codIugu) {
    return startCode + '...' + endCode
  } else {
    return null
  }
}

export const summarizeCancellationReason = (
  lastCancellationShortReasonCreatedAt,
  lastCancellationShortReason
) => {
  if (lastCancellationShortReasonCreatedAt || lastCancellationShortReason) {
    const lastCancellationCreatedAt = DateTime.fromISO(
      lastCancellationShortReasonCreatedAt
    ).toFormat('dd/MM/yyyy')

    const cancellationReason =
      lastCancellationCreatedAt +
      ' - ' +
      lastCancellationShortReason.substring(0, 34)

    //The number 47 was used, as it is the maximum number of characters that best fit the table.
    return cancellationReason + (cancellationReason.length >= 47 ? '...' : '')
  } else {
    return null
  }
}
