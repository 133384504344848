import React from 'react'
import BasicCard from './BasicCard'
import { TailSpin } from 'react-loader-spinner'
import { CloseRounded } from '@material-ui/icons'

import '../styles/components/SimpleActionPopup.css'

const SimpleActionPopup = ({
  title,
  content,
  firstActionButtonStyle,
  firstActionButtonText,
  customFirstActionButton,
  onPressFirstActionButton,
  secondActionButtonStyle,
  customSecondActionButton,
  secondActionButtonText,
  onPressSecondActionButton,
  onClosePopup,
  popupLoading,
  containerStyle = {},
  showClose = true
}) => {
  const SimpleActionPopupContent = () => {
    return (
      <div className='simple-action-popup-container' style={containerStyle}>
        {popupLoading ? (
          <div className='simple-action-popup-loading-container'>
            <TailSpin color='var(--identity-6)' height='40' width='40' />
          </div>
        ) : (
          <div>
            <button
              className='simple-action-popup-close-button-container'
              onClick={onClosePopup}
            >
              {showClose && (
                <div className='simple-action-popup-close-icon-container'>
                  <CloseRounded className='simple-action-popup-close-icon' />
                </div>
              )}
            </button>
            <div className='simple-action-popup-title-container max-w-[670px]'>
              <h3>{title}</h3>
            </div>
            <div className='simple-action-popup-content-container'>
              {content}
            </div>
            <div
              className={`${
                firstActionButtonText && secondActionButtonText
                  ? 'simple-action-popup-action-buttons-container'
                  : 'simple-action-popup-action-buttons-container-right'
              } `}
            >
              {(firstActionButtonText || customFirstActionButton) && (
                <div className='simple-action-popup-first-action-button-container'>
                  {customFirstActionButton ? (
                    customFirstActionButton
                  ) : (
                    <button
                      onClick={onPressFirstActionButton}
                      className='simple-action-popup-button danger-button'
                      style={firstActionButtonStyle}
                    >
                      {firstActionButtonText}
                    </button>
                  )}
                </div>
              )}
              {(secondActionButtonText || customSecondActionButton) && (
                <div>
                  {customSecondActionButton ? (
                    customSecondActionButton
                  ) : (
                    <button
                      onClick={onPressSecondActionButton}
                      className='simple-action-popup-button success-button'
                      style={secondActionButtonStyle}
                    >
                      {secondActionButtonText}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
  return (
    <div className='simple-action-popup'>
      <div className='m-auto z-10'>
        <BasicCard cardContent={SimpleActionPopupContent()} />
      </div>
      <div
        onClick={() => {
          if (showClose) {
            onClosePopup()
          }
        }}
        className='simple-action-popup-backdrop'
      />
    </div>
  )
}

export default SimpleActionPopup
