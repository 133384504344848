import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import FilterCard from '../components/FilterCard'
import BasicTable from '../components/BasicTable'
import SimpleActionPopup from '../components/SimpleActionPopup'
import SendWhatsappMessage from '../services/SendWhatsappMessage'
import FormInput from '../components/FormInput'
import EditIcon from '../assets/images/edit.svg'
import { successToast } from '../helpers/ToastHelper'
import { areas, getAreaValue } from '../helpers/AreasHelpers'
import Loading from '../components/Loading'

import { WhatsApp } from '@material-ui/icons'

import '../styles/containers/ManageUsers.css'
import {
  createAdmin,
  deleteAdmin,
  getAdmins,
  updateAdmin
} from '../services/utils'

const userTableHeaders = ['ID', 'Nome', 'E-mail', 'Telefone', 'Setor', 'Editar']

const ManageUsers = props => {
  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [showAddUserPopup, setShowAddUserPopup] = useState(false)
  let [showEditUserPopup, setShowEditUserPopup] = useState(false)
  let [popupLoading, setPopupLoading] = useState(false)

  let [id, setId] = useState(0)
  let [name, setName] = useState('')
  let [email, setEmail] = useState('')
  let [phoneNumber, setPhoneNumber] = useState('')
  let [selectedArea, setSelectedArea] = useState(null)

  let [isLoading, setIsLoading] = useState(true)

  const userTableKeys = [
    {
      key: 'id',
      className: '',
      action: '',
      icon: ''
    },
    {
      key: 'name',
      className: '',
      action: '',
      icon: ''
    },
    {
      key: 'email',
      className: 'break-all',
      action: '',
      icon: ''
    },
    {
      key: 'phoneNumber',
      className: '',
      action: phoneNumber => {
        SendWhatsappMessage(phoneNumber, '')
      },
      icon: <WhatsApp className='whatsapp-table-data-icon' />
    },
    {
      key: 'area',
      className: '',
      action: '',
      icon: ''
    },
    {
      key: 'actionButton',
      className: '',
      action: user => {
        setShowEditUserPopup(true)
        setId(user.id)
        setName(user.name)
        setEmail(user.email)
        setPhoneNumber(user.phoneNumber)
        // TODO: add logic to get selected area as a value for the select input
        setSelectedArea(user.area)
      },
      icon: <img src={EditIcon} alt='Editar usuário' />
    }
  ]

  const submitAddUser = async () => {
    const dataToCreate = {
      name,
      email,
      phone: phoneNumber,
      area: selectedArea.value,
      streetName: 'street name'
    }

    setPopupLoading(true)

    try {
      await createAdmin(props.token, dataToCreate)
      await fetchAdmins(props)
      successToast('Usuário criado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowAddUserPopup(false)
      setPopupLoading(false)
    }
  }

  const submitEditUser = async () => {
    const dataToUpdate = {
      name,
      email,
      phone: phoneNumber,
      area: selectedArea.value
    }

    setPopupLoading(true)

    try {
      await updateAdmin(props.token, id, dataToUpdate)
      await fetchAdmins(props)
      successToast('Usuário editado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowEditUserPopup(false)
      setPopupLoading(false)
    }
  }

  // TODO: popup confirmation logic, to avoid missclicks
  const deleteUser = async () => {
    setPopupLoading(true)

    try {
      await deleteAdmin(props.token, id)
      await fetchAdmins(props)
      successToast('Usuário removido com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowEditUserPopup(false)
      setPopupLoading(false)
    }
  }

  const userPopupContent = () => {
    const userArea = {
      value: getAreaValue(selectedArea),
      label: selectedArea
    }

    return (
      <div className='manage-users-popup-content-container'>
        <div className='grid grid-cols-1 gap-x-14 md:grid-cols-2'>
          <FormInput
            placeholder='Nome'
            label='Nome'
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormInput
            placeholder='E-mail'
            label='E-mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormInput
            placeholder='Telefone'
            label='Telefone'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            mask={{
              format: '(##) #####-####'
            }}
          />
          <FormInput
            label='Setor'
            selectedOption={userArea}
            onChange={setSelectedArea}
            selectOptions={areas}
            placeholder='Setor'
          />
        </div>
      </div>
    )
  }

  const fetchAdmins = async userProps => {
    setIsLoading(true)
    getAdmins(userProps.token)
      .then(admins => {
        const userData = admins.map(admin => ({
          id: admin.id,
          name: admin.name,
          email: admin.email,
          phoneNumber: admin.phone,
          area: areas.find(area => area.value === admin.adminArea.area).label
        }))

        setUserData(userData)
        setFilteredUserData(userData)
        setPaginatedUserData(userData)
      })
      .catch(err => {
        console.error(err)
      }) // TODO: Make error handling like the mosty-client app
      .finally(() => setIsLoading(false))
  }

  // TODO: verify if should have a loading spinner or the user data will come from redux
  useEffect(() => {
    fetchAdmins(props)
  }, [props])

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Gerenciar Usuários</h2>
      </div>
      <FilterCard
        itemList={userData}
        setFilteredItemsList={list => setFilteredUserData(list)}
      />
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Usuários</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onPressAddButton={() => {
              setName('')
              setEmail('')
              setPhoneNumber('')
              setSelectedArea('')
              setShowAddUserPopup(true)
            }}
          />
        )}
      </div>
      {showAddUserPopup && (
        <SimpleActionPopup
          title={'Adicionar Usuário'}
          content={userPopupContent()}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddUser}
          onClosePopup={() => setShowAddUserPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showEditUserPopup && (
        <SimpleActionPopup
          title={'Editar Usuário'}
          content={userPopupContent()}
          firstActionButtonText={'Apagar Usuário'}
          onPressFirstActionButton={deleteUser}
          secondActionButtonText={'Salvar'}
          onPressSecondActionButton={submitEditUser}
          onClosePopup={() => setShowEditUserPopup(false)}
          popupLoading={popupLoading}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(ManageUsers)
