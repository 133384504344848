import { combineReducers, createStore } from 'redux'
import userReducer from './userReducer'

import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  user: userReducer
})

const persistConfig = {
  key: 'root',
  storage,
  debug: true
}

const store = createStore(persistReducer(persistConfig, reducers))

const persistor = persistStore(store)

export { persistor, store }
