import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import { sortDataByKey } from '../../helpers/SalesHelpers'
import '../../styles/containers/Sales/CRM.css'
import { getConsultantsChangeHistory } from '../../services/utils'
import { DateTime } from 'luxon'
import Loading from '../../components/Loading'
import DocumentViewPopup from '../../components/DocumentViewPopup'

const userTableHeaders = [
  'Nome',
  'E-mail',
  'Consultora Antiga',
  'Nova Consultora',
  'Trocada em',
  'Trocada por admin',
  'Doc. Solicitação'
]

const ConsultantsChangeHistory = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [isLoading, setIsLoading] = useState(true)

  let [selectedSortHeader, setSelectedSortHeader] = useState(4)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  let [showDocumentViewPopup, setShowDocumentViewPopup] = useState(false)
  let [userName, setUserName] = useState('')
  let [documentId, setDocumentId] = useState('')

  const location = useLocation()

  const fetchConsultantsChangeHistory = async userProps => {
    setIsLoading(true)
    getConsultantsChangeHistory(userProps.token)
      .then(clients => {
        const userData = clients.map(item => {
          const user = {
            name: item.name || 'Indefinido',
            email: item.email || 'Indefinido',
            previousConsultantName: item.previousConsultantName,
            previousConsultantId: item.previousConsultantId,
            newConsultantName: item.newConsultantName,
            newConsultantId: item.newConsultantId,
            changedByAdmin: item.adminName,
            consultantChangedDate: DateTime.fromISO(item.updatedAt).toFormat(
              'dd/MM/yyyy'
            ),
            documentId: item.documentId,
            doc: 'Clique para visualizar '
          }
          return user
        })

        setUserData(userData)
        setFilteredUserData(userData)
        setPaginatedUserData(userData)
        setUserDataSearchResult(userData)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  const dateInitialState = dateInput => {
    if (dateInput === 'firstDateInput') {
      if (location.state && location.state.firstDateInput) {
        return location.state.firstDateInput
      }
    }
    if (dateInput === 'secondDateInput') {
      if (location.state && location.state.secondDateInput) {
        return location.state.secondDateInput
      }
    }
    return null
  }

  let [firstDateInput, setFirstDateInput] = useState(
    dateInitialState('firstDateInput')
  )
  let [secondDateInput, setSecondDateInput] = useState(
    dateInitialState('secondDateInput')
  )

  const userTableKeys = useMemo(
    () => [
      {
        key: 'name',
        className: 'min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'previousConsultantName',
        className: 'break-all min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: {
              user: { id: user.previousConsultantId },
              pageOrigin: 'Histórico de Trocas de Consultoras'
            }
          })
        },
        icon: ''
      },
      {
        key: 'newConsultantName',
        className: 'break-all min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: {
              user: { id: user.newConsultantId },
              pageOrigin: 'Histórico de Trocas de Consultoras'
            }
          })
        },
        icon: ''
      },
      {
        key: 'consultantChangedDate',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'changedByAdmin',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'doc',
        className: '',
        passObjectToAction: true,
        action: user => {
          setUserName(user.name)
          setDocumentId(user.documentId)
          setShowDocumentViewPopup(true)
        },
        icon: ''
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const filteredUserData = userDataSearchResult.filter(item => {
      // TODO: define the desired date to be considered in this period filter
      if (item.createdAt < firstDateInput || item.createdAt > secondDateInput) {
        return true
      }
      return true
    })

    const isDateField =
      userTableKeys[selectedSortHeader].key === 'consultantChangedDate'

    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredUserData(filteredUserData)
  }, [
    userDataSearchResult,
    firstDateInput,
    secondDateInput,
    selectedSortHeader,
    sortOrientation,
    userTableKeys
  ])

  useEffect(() => {
    fetchConsultantsChangeHistory(props)
  }, [props])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Histórico de Trocas de Consultoras</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          firstDateInput={firstDateInput}
          setFirstDateInput={setFirstDateInput}
          secondDateInput={secondDateInput}
          setSecondDateInput={setSecondDateInput}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Clientes</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {showDocumentViewPopup && (
        <DocumentViewPopup
          title={'Troca de Consultoras: ' + userName}
          documentId={documentId}
          setShowPopup={setShowDocumentViewPopup}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(ConsultantsChangeHistory)
