import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import EditIcon from '../../assets/images/edit.svg'
import CopyIcon from '../../assets/images/copy.svg'

import { sortDataByKey } from '../../helpers/SalesHelpers'
import { successToast } from '../../helpers/ToastHelper'
import { summarizeIuguCode } from '../../helpers/ClientSucessHelpers'

import '../../styles/containers/Sales/CRM.css'
import '../../styles/containers/ClientSuccess/Subscriptions.css'
import Loading from '../../components/Loading'
import BasicTable from '../../components/BasicTable'
import { getSubscriptions } from '../../services/utils'
import { DateTime } from 'luxon'
import {
  getPlanName,
  getPlanStatus
} from '../../helpers/ConsultantDetailsHelpers'
import SubscriptionHistoryPopup from '../../components/SubscriptionHistoryPopup'

const userTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Validade',
  'Plano',
  'Status',
  'Cupom',
  'Data de criação',
  'Código IUGU',
  ''
]

const Subscriptions = props => {
  let navigate = useNavigate()

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  let [subscriptionData, setSubscriptionData] = useState([])
  let [filteredSubscriptionData, setFilteredSubscriptionData] = useState([])
  let [paginatedSubscriptionData, setPaginatedSubscriptionData] = useState([])
  let [
    subscriptionDataSearchResult,
    setSubscriptionDataSearchResult
  ] = useState([])

  let [isLoading, setIsLoading] = useState(true)

  const location = useLocation()

  const filterInitialState = filter => {
    if (location.state && location.state.selectedOnlyFilter) {
      if (filter === location.state.selectedOnlyFilter) {
        return true
      }
      return false
    }
    return true
  }

  let [activeFilter, setActiveFilter] = useState(
    filterInitialState('activeFilter')
  )
  let [manuallyReleasedFilter, setManuallyReleasedFilter] = useState(
    filterInitialState('manuallyReleased')
  )
  let [canceledFilter, setCanceledFilter] = useState(
    filterInitialState('canceledFilter')
  )
  let [awaitingCancelFilter, setAwaitingCancelFilter] = useState(
    filterInitialState('awaitingCancel')
  )
  let [inTestFilter, setInTestFilter] = useState(filterInitialState('inTest'))
  let [couponFilter, setCouponFilter] = useState(
    filterInitialState('setCouponFilter')
  )

  /* Subscription History popup states */
  let [currentPopupUserId, setCurrentPopupUserId] = useState(0)
  let [currentPopupUserName, setCurrentPopupUserName] = useState('')
  let [showPopup, setShowPopup] = useState(false)

  const filterList = [
    {
      label: 'Ativas',
      filterState: activeFilter,
      setFilter: value => setActiveFilter(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#D1F1BF' }}
        ></div>
      )
    },
    {
      label: 'Liberadas Manualmente',
      filterState: manuallyReleasedFilter,
      setFilter: value => setManuallyReleasedFilter(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#ECD0F1' }}
        ></div>
      )
    },
    {
      label: 'Canceladas',
      filterState: canceledFilter,
      setFilter: value => setCanceledFilter(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#FFC3C3' }}
        ></div>
      )
    },
    {
      label: 'Cancelamento Agendado',
      filterState: awaitingCancelFilter,
      setFilter: value => setAwaitingCancelFilter(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#FFF9C2' }}
        ></div>
      )
    },
    {
      label: 'Em teste',
      filterState: inTestFilter,
      setFilter: value => setInTestFilter(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#DDE6FF' }}
        ></div>
      )
    },
    {
      label: 'Cupom',
      filterState: couponFilter,
      setFilter: value => setCouponFilter(value)
    }
  ]

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          user.subscriptionId = user.id
          user.id = user.userId
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'CRM' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'validUntilDay',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'plan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'couponCode',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'summarizedIuguCode',
        positionIcon: 'left',
        passObjectToAction: true,
        className: '',
        action: subscription => {
          if (subscription.codeIugu) {
            successToast('Código Iugu copiado com sucesso!')
            navigator.clipboard.writeText(subscription.codeIugu)
          } else {
            return null
          }
        },
        icon: (
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        )
      },
      {
        key: 'actionButton',
        className: '',
        action: subscription => {
          setCurrentPopupUserId(subscription.userId)
          setCurrentPopupUserName(subscription.name)
          setShowPopup(true)
        },
        icon: (
          <button className='subscription-edit-button'>
            <img src={EditIcon} alt='edit' />
          </button>
        )
      }
    ],
    [navigate]
  )

  const fetchSubscriptions = useCallback(async () => {
    await getSubscriptions(props.token)
      .then(subscriptions => {
        const formattedSubscriptions = subscriptions.map(subscription => {
          return {
            id: subscription.id || 'Indefinido',
            name: subscription.name || 'Indefinido',
            email: subscription.email || 'Indefinido',
            validUntilDay: subscription.validUntilDay
              ? DateTime.fromISO(subscription.validUntilDay).toFormat(
                  'dd/MM/yyyy'
                )
              : 'Indefinido',
            plan: subscription.planIdentifier
              ? getPlanName(subscription.planIdentifier)
              : 'Indefinido',
            status: getPlanStatus(
              subscription.status,
              subscription.paymentMethodIdentifier,
              subscription.validUntilDay
            ),
            couponCode: subscription.couponCode || '',
            createdAt: subscription.createdAt
              ? DateTime.fromISO(subscription.createdAt).toFormat('dd/MM/yyyy')
              : 'Indefinido',
            nextBillingDay: subscription.nextBillingDay,
            userId: subscription.userId,
            summarizedIuguCode: summarizeIuguCode(
              subscription.externalSubscriptionId
            ),
            codeIugu: subscription.externalSubscriptionId
          }
        })
        setSubscriptionData(formattedSubscriptions)
        setFilteredSubscriptionData(formattedSubscriptions)
        setPaginatedSubscriptionData(formattedSubscriptions)
        setSubscriptionDataSearchResult(formattedSubscriptions)
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }, [props.token])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  const rowStyle = subscription => {
    if (
      subscription.status === 'Ativo' &&
      subscription.nextBillingDay !== null
    ) {
      return { backgroundColor: '#D1F1BF' }
    }

    if (
      (subscription.status === 'Ativo' ||
        subscription.status === 'Aguardando cancelamento') &&
      subscription.nextBillingDay === null &&
      subscription.plan !== 'Teste'
    ) {
      return { backgroundColor: '#ECD0F1' }
    }

    if (subscription.status === 'Cancelado') {
      return { backgroundColor: '#FFC3C3' }
    }

    if (
      subscription.status === 'Aguardando cancelamento' &&
      subscription.plan !== 'Teste' &&
      subscription.nextBillingDay !== null
    ) {
      return { backgroundColor: '#FFF9C2' }
    }

    if (
      subscription.status === 'Aguardando cancelamento' &&
      subscription.plan === 'Teste'
    ) {
      return { backgroundColor: '#DDE6FF' }
    }

    if (subscription.couponCode !== '') {
      return {}
    }
    return {}
  }

  const selectAllFilters = () => {
    setActiveFilter(true)
    setCanceledFilter(true)
    setManuallyReleasedFilter(true)
    setAwaitingCancelFilter(true)
    setInTestFilter(true)
    setCouponFilter(true)
  }

  const unselectAllFilters = () => {
    setActiveFilter(false)
    setCanceledFilter(false)
    setManuallyReleasedFilter(false)
    setAwaitingCancelFilter(false)
    setInTestFilter(false)
    setCouponFilter(false)
  }

  const filterCardBottomContent = () => {
    return (
      <div className='filter-buttons'>
        <button className='filter-button-select-all' onClick={selectAllFilters}>
          Selecionar Todos
        </button>
        <button
          className='filter-button-unselect-all'
          onClick={unselectAllFilters}
        >
          Desmarcar Todos
        </button>
      </div>
    )
  }

  useEffect(() => {
    const filteredSubscriptionData = subscriptionDataSearchResult.filter(
      subscription => {
        if (
          activeFilter &&
          subscription.status === 'Ativo' &&
          subscription.nextBillingDay !== null
        ) {
          return true
        }

        if (
          manuallyReleasedFilter &&
          (subscription.status === 'Ativo' ||
            subscription.status === 'Aguardando cancelamento') &&
          subscription.nextBillingDay === null &&
          subscription.plan !== 'Teste'
        ) {
          return true
        }

        if (canceledFilter && subscription.status === 'Cancelado') {
          return true
        }

        if (
          awaitingCancelFilter &&
          subscription.status === 'Aguardando cancelamento' &&
          subscription.plan !== 'Teste' &&
          subscription.nextBillingDay !== null
        ) {
          return true
        }

        if (
          inTestFilter &&
          subscription.status === 'Aguardando cancelamento' &&
          subscription.plan === 'Teste'
        ) {
          return true
        }

        if (couponFilter && subscription.couponCode !== '') {
          return true
        }
        return false
      }
    )

    // If the key of the clicked header indicates a date field, set isDateField as 'true' to correctly order the dates in sortDataByKey().
    const isDateField =
      userTableKeys[selectedSortHeader].key === 'validUntilDay' ||
      userTableKeys[selectedSortHeader].key === 'createdAt'

    filteredSubscriptionData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredSubscriptionData(filteredSubscriptionData)
  }, [
    activeFilter,
    manuallyReleasedFilter,
    canceledFilter,
    awaitingCancelFilter,
    inTestFilter,
    couponFilter,
    subscriptionDataSearchResult,
    selectedSortHeader,
    sortOrientation,
    userTableKeys
  ])

  useEffect(() => {
    fetchSubscriptions()
  }, [fetchSubscriptions])

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Sucesso do cliente - Assinaturas</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={subscriptionData}
          setFilteredItemsList={list => setSubscriptionDataSearchResult(list)}
          filterList={filterList}
          bottomContent={filterCardBottomContent()}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>
            Assinaturas das Consultoras
          </h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredSubscriptionData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedSubscriptionData}
            setPaginatedData={setPaginatedSubscriptionData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
            rowStyle={rowStyle}
          />
        )}
      </div>
      {showPopup && (
        <SubscriptionHistoryPopup
          refetch={fetchSubscriptions}
          userId={currentPopupUserId}
          userName={currentPopupUserName}
          setShowPopup={setShowPopup}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Subscriptions)
