import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'

import { sortDataByKey } from '../../helpers/SalesHelpers'
import {
  getPlanName,
  getPlanStatus
} from '../../helpers/ConsultantDetailsHelpers'
import { successToast } from '../../helpers/ToastHelper'
import { summarizeIuguCode } from '../../helpers/ClientSucessHelpers'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'

import CopyIcon from '../../assets/images/copy.svg'
import BasicTable from '../../components/BasicTable'

import { WhatsApp, ChevronRightOutlined } from '@material-ui/icons'
import Loading from '../../components/Loading'

import {
  getLastCancelledSubscriptions,
  getLastCreatedSubscriptions
} from '../../services/utils'

import '../../styles/containers/Sales/SalesDashboard.css'

const lastCancelledTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Plano',
  'Status',
  'Cancelada em',
  'Assinou em',
  'Código Iugu'
]
const lastCreatedTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Plano',
  'Status',
  'Cupom',
  'Assinou em',
  'Código Iugu'
]

const ClientSucessDashboard = props => {
  let navigate = useNavigate()
  let [isLoading, setIsLoading] = useState(true)

  //Table last created subscriptions
  let [lastCreatedUsers, setLastCreatedUsers] = useState([])
  let [paginatedLastCreatedUsers, setPaginatedLastCreatedUsers] = useState([])
  let [lastCreatedUsersData, setLastCreatedUsersData] = useState([])

  let [
    selectedSortHeaderLastCreated,
    setSelectedSortHeaderLastCreated
  ] = useState(0)
  let [sortOrientationLastCreated, setSortOrientationLastCreated] = useState(
    'DESC'
  )

  //Table last cancelled subscriptions
  let [lastCancelledUsers, setLastCancelledUsers] = useState([])
  let [paginatedLastCancelledUsers, setPaginatedLastCancelledUsers] = useState(
    []
  )
  let [lastCancelledUsersData, setLastCancelledUsersData] = useState([])

  let [
    selectedSortHeaderLastCancelled,
    setSelectedSortHeaderLastCancelled
  ] = useState(6)
  let [
    sortOrientationLastCancelled,
    setSortOrientationLastCancelled
  ] = useState('DESC')

  const lastCancelledTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: {
              user: { id: user.userId },
              pageOrigin: 'Consultoras em Teste'
            }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          SendWhatsappMessage(phoneNumber, '')
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'plan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'updatedAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'summarizedIuguCode',
        positionIcon: 'left',
        passObjectToAction: true,
        className: '',
        action: subscription => {
          if (subscription.codeIugu) {
            successToast('Código Iugu copiado com sucesso!')
            navigator.clipboard.writeText(subscription.codeIugu)
          } else {
            return null
          }
        },
        icon: (
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        )
      }
    ],
    [navigate]
  )
  const lastCreatedTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user: { id: user.userId }, pageOrigin: 'Dashboard' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'plan',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'Cupom',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'createdAt',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'summarizedIuguCode',
        positionIcon: 'left',
        passObjectToAction: true,
        className: '',
        action: subscription => {
          if (subscription.codeIugu) {
            successToast('Código Iugu copiado com sucesso!')
            navigator.clipboard.writeText(subscription.codeIugu)
          } else {
            return null
          }
        },
        icon: (
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        )
      }
    ],
    [navigate]
  )

  useEffect(() => {
    async function getSalesDashboardInfo () {
      try {
        const lastCreated = await getLastCreatedSubscriptions(props.token)
        const lastCancelled = await getLastCancelledSubscriptions(props.token)

        // handling last created users info
        const lastCreatedUsers = lastCreated.map(item => {
          const user = {
            id: item.id || 'Indefinido',
            name: item.name || 'Indefinido',
            email: item.email || 'Indefinido',
            validUntilDay: item.validUntilDay
              ? DateTime.fromISO(item.validUntilDay).toFormat('dd/MM/yyyy')
              : 'Indefinido',
            plan: item.planIdentifier
              ? getPlanName(item.planIdentifier)
              : 'Indefinido',
            status: getPlanStatus(
              item.status,
              item.paymentMethodIdentifier,
              item.validUntilDay
            ),
            couponCode: item.couponCode || '',
            createdAt: item.createdAt
              ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')
              : 'Indefinido',
            userId: item.userId,
            summarizedIuguCode: summarizeIuguCode(item.externalSubscriptionId),
            codeIugu: item.externalSubscriptionId
          }
          return user
        })
        setLastCreatedUsers(lastCreatedUsers)
        setPaginatedLastCreatedUsers(lastCreatedUsers)

        // handling last cancelled users info
        const lastCancelledUsers = lastCancelled.map(item => {
          const user = {
            id: item.id || 'Indefinido',
            name: item.name || 'Indefinido',
            email: item.email || 'Indefinido',
            phoneNumber: item.phone || 'Indefinido',
            plan: item.planIdentifier
              ? getPlanName(item.planIdentifier)
              : 'Indefinido',
            status: getPlanStatus(
              item.status,
              item.paymentMethodIdentifier,
              item.validUntilDay
            ),
            updatedAt: item.updatedAt
              ? DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy')
              : 'Indefinido',
            createdAt: item.createdAt
              ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')
              : 'Indefinido',
            userId: item.userId,
            summarizedIuguCode: summarizeIuguCode(item.externalSubscriptionId),
            codeIugu: item.externalSubscriptionId
          }
          return user
        })
        setLastCancelledUsers(lastCancelledUsers)
        setPaginatedLastCancelledUsers(lastCancelledUsers)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    getSalesDashboardInfo()
  }, [props.token])

  useEffect(() => {
    // If the key of the clicked header indicates a date field, set isDateFieldCreated as 'true' to correctly order the dates in sortDataByKey().
    const isDateFieldCreated =
      lastCreatedTableKeys[selectedSortHeaderLastCreated].key === 'createdAt'

    const isDateFieldCancelled =
      lastCancelledTableKeys[selectedSortHeaderLastCancelled].key ===
        'updatedAt' ||
      lastCancelledTableKeys[selectedSortHeaderLastCancelled].key ===
        'createdAt'

    const copyLastCreatedUsers = [...lastCreatedUsers]
    const copyLastCancelledUsers = [...lastCancelledUsers]

    copyLastCreatedUsers.sort(
      sortDataByKey(
        lastCreatedTableKeys[selectedSortHeaderLastCreated].key,
        sortOrientationLastCreated,
        isDateFieldCreated
      )
    )

    copyLastCancelledUsers.sort(
      sortDataByKey(
        lastCancelledTableKeys[selectedSortHeaderLastCancelled].key,
        sortOrientationLastCancelled,
        isDateFieldCancelled
      )
    )

    setLastCreatedUsersData(copyLastCreatedUsers)
    setLastCancelledUsersData(copyLastCancelledUsers)
  }, [
    lastCancelledTableKeys,
    lastCancelledUsers,
    lastCreatedTableKeys,
    lastCreatedUsers,
    selectedSortHeaderLastCancelled,
    selectedSortHeaderLastCreated,
    sortOrientationLastCancelled,
    sortOrientationLastCreated
  ])

  const onClickSortHeader = (selectedHeader, table) => {
    let orientation = 'ASC'

    if (table === 'lastCreated') {
      if (selectedSortHeaderLastCreated === selectedHeader) {
        if (sortOrientationLastCreated === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderLastCreated(selectedHeader)
      setSortOrientationLastCreated(orientation)
    }

    if (table === 'lastCancelled') {
      if (selectedSortHeaderLastCancelled === selectedHeader) {
        if (sortOrientationLastCancelled === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderLastCancelled(selectedHeader)
      setSortOrientationLastCancelled(orientation)
    }
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Dashboard Sucesso do Cliente</h2>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='col-span-3'>
            <div className='mb-4'>
              <h4 className='my-data-section-header'>
                Últimas assinaturas canceladas
              </h4>
            </div>
            <BasicTable
              itemsPerPage={8}
              filteredData={lastCancelledUsersData}
              tableHeaders={lastCancelledTableHeaders}
              tableKeys={lastCancelledTableKeys}
              paginatedData={paginatedLastCancelledUsers}
              setPaginatedData={setPaginatedLastCancelledUsers}
              onClickSortHeader={onClickSortHeader}
              selectedSortHeader={selectedSortHeaderLastCancelled}
              sortOrientation={sortOrientationLastCancelled}
              tableType={'lastCancelled'}
            />
            <div
              className='flex justify-end cursor-pointer mt-2 mb-8'
              onClick={() => {
                navigate('/sucesso-do-cliente/cancelamentos')
              }}
            >
              <p className='sales-dashboard-see-all-text'>
                Ver todos os usuários cancelados{' '}
              </p>
              <ChevronRightOutlined className='sales-dashboard-right-icon' />
            </div>
          </div>
          <div className='col-span-3'>
            <div className='mb-4'>
              <h4 className='my-data-section-header'>
                Últimas assinaturas criadas
              </h4>
            </div>
            <BasicTable
              itemsPerPage={8}
              filteredData={lastCreatedUsersData}
              tableHeaders={lastCreatedTableHeaders}
              tableKeys={lastCreatedTableKeys}
              paginatedData={paginatedLastCreatedUsers}
              setPaginatedData={setPaginatedLastCreatedUsers}
              onClickSortHeader={onClickSortHeader}
              selectedSortHeader={selectedSortHeaderLastCreated}
              sortOrientation={sortOrientationLastCreated}
              tableType={'lastCreated'}
            />
            <div
              className='flex justify-end cursor-pointer mt-2 mb-8'
              onClick={() => {
                navigate('/sucesso-do-cliente/assinaturas')
              }}
            >
              <p className='sales-dashboard-see-all-text'>
                Ver todas as assinaturas criadas{' '}
              </p>
              <ChevronRightOutlined className='sales-dashboard-right-icon' />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(ClientSucessDashboard)
