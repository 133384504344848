import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { DateTime } from 'luxon'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import BasicCard from '../../components/BasicCard'
import SimpleActionPopup from '../../components/SimpleActionPopup'
import { DatePicker } from '../../components/DateTimePickers'
import FormInput from '../../components/FormInput'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import {
  getUserExperience,
  getDaysLeft,
  sortDataByKey,
  sortDaysLeft
} from '../../helpers/SalesHelpers'
import { errorToast, successToast } from '../../helpers/ToastHelper'
import {
  WhatsApp,
  BallotOutlined,
  AddRounded,
  DeleteOutline
} from '@material-ui/icons'
import EditIcon from '../../assets/images/edit.svg'

import '../../styles/containers/Sales/TestingConsultants.css'
import {
  addConsultantContact,
  deleteConsultantContact,
  getConsultantContactHistory,
  getTestingConsultants,
  editConsultantContact,
  extendUserSubscription
} from '../../services/utils'
import Loading from '../../components/Loading'

const userTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Experiência',
  'Dias Restantes',
  'Último login',
  'Chance de assinar',
  'Último Contato',
  'Estender Teste'
]

const TestingConsultants = props => {
  let navigate = useNavigate()

  const transformUserData = userData => {
    return userData.map(user => {
      user.experience = getUserExperience(user.experienceId)
      user.daysLeft = user.validUntilDay
        ? getDaysLeft(user.validUntilDay)
        : 'Indeterminado'
      if (user.contactHistory.length > 0) {
        user.lastContactDate = DateTime.fromISO(
          user.contactHistory[0].date
        ).toFormat('dd/MM/yyyy')
      } else if (user.lastContactDate) {
        user.lastContactDate = DateTime.fromISO(user.lastContactDate).toFormat(
          'dd/MM/yyyy'
        )
      } else {
        user.lastContactDate = 'Nenhum contato feito'
      }
      if (user.lastLogin) {
        user.lastLoginDate = DateTime.fromISO(user.lastLogin).toFormat(
          'dd/MM/yyyy'
        )
      } else {
        user.lastLoginDate = 'Indefinido'
      }
      user.subscriptionChancePercentage =
        '' + (user.subscriptionChance * 100).toFixed(0) + '%'
      return user
    })
  }

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [showContactHistoryPopup, setShowContactHistoryPopup] = useState(false)
  let [showAddContactItemPopup, setShowAddContactItemPopup] = useState(false)
  let [showEditContactItemPopup, setShowEditContactItemPopup] = useState(false)
  let [
    showExtendUserSubscriptionPopup,
    setShowExtendUserSubscriptionPopup
  ] = useState(false)
  let [
    showDeleteContactItemConfirmationPopup,
    setShowDeleteContactItemConfirmationPopup
  ] = useState(false)
  let [
    selectedUserContactHistoryPopup,
    setSelectedUserContactHistoryPopup
  ] = useState(null)
  let [popupLoading, setPopupLoading] = useState(false)
  let [isLoading, setIsLoading] = useState(true)

  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')

  let [userToExtendSubscription, setUserToExtendSubscription] = useState(null)

  const location = useLocation()

  const filterInitialState = filter => {
    if (location.state && location.state.selectedFilters) {
      if (location.state.selectedFilters.includes(filter)) {
        return true
      }
      return false
    }
    return true
  }

  const fetchUserContactHistory = useCallback(
    async consultant => {
      const contactHistoryToMap = await getConsultantContactHistory(
        props.token,
        consultant.id,
        'consultant'
      )
      const contactHistory = contactHistoryToMap.map(contact => ({
        id: contact.id,
        message: contact.contactDetails,
        responsibleUser: contact.responsibleUser,
        date: DateTime.fromISO(contact.contactDate).toFormat('dd/MM/yyyy')
      }))
      setSelectedUserContactHistoryPopup({ ...consultant, contactHistory })
    },
    [props.token]
  )

  const fetchTestingConsultants = useCallback(async () => {
    try {
      await getTestingConsultants(props.token).then(testingConsultants => {
        const userDataToTransform = testingConsultants.map(consultant => ({
          id: consultant.id,
          name: consultant.name,
          email: consultant.email,
          phoneNumber: consultant.phone,
          experienceId: consultant.experienceId,
          validUntilDay: consultant.subscriptionValidUntilDay,
          createdAt: DateTime.fromISO(consultant.createdAt).toFormat(
            'yyyy-MM-dd'
          ),
          lastContactDate: consultant.lastContactDate,
          lastLogin: consultant.updatedAt ? DateTime.fromISO(consultant.updatedAt).toFormat(
            'dd/MM/yyyy'
          ) : 'Indefinido',
          subscriptionChance: consultant.subscriptionChance,
          contactHistory: []
        }))

        const userData = transformUserData(userDataToTransform)

        setUserData(userData)
        setFilteredUserData(userData)
        setUserDataSearchResult(userData)
        setPaginatedUserData(userData)
      })
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }, [props.token])

  let [haveContactFilter, setHaveContactFilter] = useState(
    filterInitialState('contact')
  )
  let [
    haveHigherSubscriptionChance,
    setHaveHigherSubscriptionChance
  ] = useState(filterInitialState('higherSubscriptionChance')) // Today this chance is >= 60%
  let [haveLowerSubscriptionChance, setHaveLowerSubscriptionChance] = useState(
    filterInitialState('lowerSubscriptionChance')
  ) // Today this chance is < 60%

  let [firstDateInput, setFirstDateInput] = useState(null)
  let [secondDateInput, setSecondDateInput] = useState(null)

  let [newContactItemDate, setNewContactItemDate] = useState('')
  let [newContactItemMessage, setNewContactItemMessage] = useState('')

  let [contactToEdit, setContactToEdit] = useState('')
  let [editContactItemDate, setEditContactItemDate] = useState('')
  let [editContactItemMessage, setEditContactItemMessage] = useState('')

  const filterList = [
    {
      label: 'Contatadas',
      filterState: haveContactFilter,
      setFilter: value => setHaveContactFilter(value)
    },
    {
      label: 'Chance alta de assinar',
      filterState: haveHigherSubscriptionChance,
      setFilter: value => setHaveHigherSubscriptionChance(value)
    },
    {
      label: 'Chance baixa de assinar',
      filterState: haveLowerSubscriptionChance,
      setFilter: value => setHaveLowerSubscriptionChance(value)
    }
  ]

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'experience',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'daysLeft',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'lastLogin',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'subscriptionChancePercentage',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'lastContactDate',
        className: '',
        passObjectToAction: true,
        action: async consultant => {
          setSelectedUserContactHistoryPopup({
            ...consultant,
            contactHistory: []
          })
          setPopupLoading(true)
          setShowContactHistoryPopup(true)

          try {
            await fetchUserContactHistory(consultant)
          } catch {
            errorToast(
              'Ocorreu um erro ao carregar o histórico de contato desse usuário, tente novamente'
            )
            setShowContactHistoryPopup(false)
          } finally {
            setPopupLoading(false)
          }

          // setSelectedUserContactHistoryPopup(user)
          // TODO: open popup logic passing user.contactHistory
        },
        icon: <BallotOutlined className='ballot-table-data-icon' />
      },
      {
        key: 'actionButton',
        className: '',
        action: user => {
          setUserToExtendSubscription(user)
          setShowExtendUserSubscriptionPopup(true)
        },
        icon: (
          <button className='testing-consultant-add-button'>
            <AddRounded className='testing-consultant-add-icon' />
          </button>
        )
      }
    ],
    [navigate, fetchUserContactHistory]
  )

  useEffect(() => {
    const filteredUserData = userDataSearchResult.filter(item => {
      // TODO: define the desired date to be considered in this period filter
      if (item.createdAt < firstDateInput || item.createdAt > secondDateInput) {
        return false
      }

      if (haveContactFilter) {
        if (item.lastContactDate !== 'Nenhum contato feito') {
          return true
        }
      }

      if (haveHigherSubscriptionChance) {
        if (item.subscriptionChance >= 0.6) {
          return true
        }
      }

      if (haveLowerSubscriptionChance) {
        if (item.subscriptionChance < 0.6) {
          return true
        }
      }

      return false
    })

    // If the key of the clicked header indicates a date field, set isDateField as 'true' to correctly order the dates in sortDataByKey().
    const isDateField =
      userTableKeys[selectedSortHeader].key === 'lastContactDate' ||
      userTableKeys[selectedSortHeader].key === 'contactHistory' ||
      userTableKeys[selectedSortHeader].key === 'lastLogin'

    userTableKeys[selectedSortHeader].key === 'daysLeft'
      ? filteredUserData.sort(
          sortDaysLeft(userTableKeys[selectedSortHeader].key, sortOrientation)
        )
      : filteredUserData.sort(
          sortDataByKey(
            userTableKeys[selectedSortHeader].key,
            sortOrientation,
            isDateField
          )
        )

    setFilteredUserData(filteredUserData)
  }, [
    haveContactFilter,
    haveHigherSubscriptionChance,
    haveLowerSubscriptionChance,
    userDataSearchResult,
    firstDateInput,
    secondDateInput,
    selectedSortHeader,
    sortOrientation,
    userTableKeys
  ])

  useEffect(() => {
    setIsLoading(true)
    fetchTestingConsultants()
  }, [fetchTestingConsultants])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  // TODO: add submit add contact message logic
  const addEditContactItemPopup = () => {
    setNewContactItemDate('')
    setNewContactItemMessage('')
    setShowContactHistoryPopup(false)
    setShowAddContactItemPopup(true)
  }

  const deleteContactItem = async () => {
    setPopupLoading(true)

    try {
      await deleteConsultantContact(props.token, contactToEdit)
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchTestingConsultants()
      successToast('Contato apagado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowDeleteContactItemConfirmationPopup(false)
      setPopupLoading(false)
    }
  }

  const deleteContactItemConfirmationPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja{' '}
          <strong>apagar esse contato</strong>?
        </p>
      </div>
    )
  }

  const editContactItem = async () => {
    setPopupLoading(true)

    try {
      await editConsultantContact(props.token, contactToEdit, {
        contactDetails: editContactItemMessage,
        contactDate: editContactItemDate
      })
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchTestingConsultants()
      setShowEditContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato editado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const extendUserSubscriptionPeriod = async () => {
    setPopupLoading(true)

    const userValidUntilDay = userToExtendSubscription.validUntilDay
    const userValidUntilDayDiffNow =
      userValidUntilDay &&
      DateTime.fromFormat(userValidUntilDay, 'yyyy-MM-dd').diffNow()

    if (userValidUntilDay != null && !(userValidUntilDayDiffNow <= 7)) {
      setPopupLoading(false)
      return errorToast(
        'O usuário deve ter a data de validade da assinatura nos próximos 7 dias para o teste poder ser estendido'
      )
    }

    try {
      await extendUserSubscription(props.token, userToExtendSubscription.id)
      await fetchTestingConsultants()
      setShowExtendUserSubscriptionPopup(false)
      successToast('Teste estendido com sucesso!')
    } catch (e) {
      console.log(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const submitAddContactItem = async () => {
    setPopupLoading(true)

    const dataToPost = {
      contactDate: newContactItemDate,
      contactDetails: newContactItemMessage,
      consultantId: selectedUserContactHistoryPopup.id
    }

    try {
      await addConsultantContact(props.token, dataToPost)
      await fetchUserContactHistory(selectedUserContactHistoryPopup)
      await fetchTestingConsultants()
      setShowAddContactItemPopup(false)
      setShowContactHistoryPopup(true)
      successToast('Contato criado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const contactHistoryPopupContent = selectedUser => {
    const contactCardContent = contactItem => {
      return (
        <div className='px-4 py-4 grid grid-cols-8'>
          <div className='col-span-7'>
            <div className='mb-6'>
              <p className='big-paragraph font-medium'>
                {contactItem.date} - {contactItem.responsibleUser}
              </p>
            </div>
            <div>
              <p>"{contactItem.message}"</p>
            </div>
          </div>
          <div className='flex flex-col'>
            <div
              onClick={() => {
                setContactToEdit(contactItem.id)
                setShowDeleteContactItemConfirmationPopup(true)
              }}
              className='mb-4 self-end cursor-pointer'
            >
              <DeleteOutline className='delete-card-icon' />
            </div>
            <div
              className='self-end cursor-pointer'
              onClick={() => {
                setContactToEdit(contactItem.id)
                setEditContactItemDate(
                  DateTime.fromFormat(contactItem.date, 'dd/MM/yyyy').toFormat(
                    'yyyy-MM-dd'
                  )
                )
                setEditContactItemMessage(contactItem.message)
                setShowContactHistoryPopup(false)
                setShowEditContactItemPopup(true)
              }}
            >
              <img src={EditIcon} alt='Editar usuário' />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='grid grid-cols-1 max-w-[670px] m-auto max-h-[300px] overflow-auto p-2'>
          {selectedUser.contactHistory.length > 0 ? (
            selectedUser.contactHistory.map((contactItem, key) => {
              return (
                <div key={key} className='mb-6'>
                  <BasicCard cardContent={contactCardContent(contactItem)} />
                </div>
              )
            })
          ) : (
            <div>
              <p>Usuário não possui histórico de contato.</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const CustomAddButton = () => {
    return (
      <button
        className='testing-consultant-add-contact-item-button'
        onClick={addEditContactItemPopup}
      >
        <AddRounded className='basic-table-add-icon' />
      </button>
    )
  }

  const addContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={newContactItemDate}
            onChange={e => setNewContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={newContactItemMessage}
            onChange={e => setNewContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - newContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const editContactItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Data*</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px]'
            value={editContactItemDate}
            onChange={e => setEditContactItemDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
        </div>
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes*'
            rows={4}
            maxLength={255}
            value={editContactItemMessage}
            onChange={e => setEditContactItemMessage(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes: {255 - editContactItemMessage.length}
          </p>
        </div>
      </div>
    )
  }

  const extendUserSubscriptionContent = () => {
    return (
      <div className='text-xl'>
        Deseja realmente estender o período de teste deste usuário?
      </div>
    )
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Consultoras em Teste</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          filterList={filterList}
          firstDateInput={firstDateInput}
          setFirstDateInput={setFirstDateInput}
          secondDateInput={secondDateInput}
          setSecondDateInput={setSecondDateInput}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {showContactHistoryPopup && (
        <SimpleActionPopup
          title={`Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={contactHistoryPopupContent(selectedUserContactHistoryPopup)}
          customSecondActionButton={<CustomAddButton />}
          onClosePopup={() => setShowContactHistoryPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showAddContactItemPopup && (
        <SimpleActionPopup
          title={`Adicionar Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={addContactItemContent(selectedUserContactHistoryPopup)}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddContactItem}
          onClosePopup={() => setShowAddContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showEditContactItemPopup && (
        <SimpleActionPopup
          title={`Editar Histórico de Contato - ${selectedUserContactHistoryPopup.name}`}
          content={editContactItemContent(selectedUserContactHistoryPopup)}
          secondActionButtonText={'EDITAR'}
          onPressSecondActionButton={editContactItem}
          onClosePopup={() => setShowEditContactItemPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showExtendUserSubscriptionPopup && (
        <SimpleActionPopup
          title={`Estender teste - ${userToExtendSubscription.name}`}
          content={extendUserSubscriptionContent()}
          firstActionButtonText={'CANCELAR'}
          secondActionButtonText={'ESTENDER'}
          onPressSecondActionButton={extendUserSubscriptionPeriod}
          onPressFirstActionButton={() =>
            setShowExtendUserSubscriptionPopup(false)
          }
          onClosePopup={() => setShowExtendUserSubscriptionPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showDeleteContactItemConfirmationPopup && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={deleteContactItemConfirmationPopupContent()}
          onClosePopup={() => setShowDeleteContactItemConfirmationPopup(false)}
          title='Apagar contato feito'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='APAGAR'
          onPressSecondActionButton={() => deleteContactItem()}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setShowDeleteContactItemConfirmationPopup(false)
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(TestingConsultants)
