import React from 'react'
import '../styles/components/FormRadio.css'

const FormRadio = props => {
  const { changed, id, isSelected, value } = props
  const label = props.value
  return (
    <div>
      <input
        id={id}
        onChange={changed}
        value={value}
        type='radio'
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default FormRadio
