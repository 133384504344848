import { useEffect, useState } from 'react'

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    innerHeight: window.innerHeight,
    outerHeight: window.outerHeight,
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth
  })

  useEffect(() => {
    function windowResizeEvent (e) {
      setDimensions({
        innerHeight: e.target.innerHeight,
        outerHeight: e.target.outerHeight,
        innerWidth: e.target.outerWidth,
        outerWidth: e.target.outerWidth
      })
    }

    window.addEventListener('resize', windowResizeEvent)

    return () => {
      window.removeEventListener('resize', windowResizeEvent)
    }
  }, [])

  return { dimensions }
}

export default useDimensions
