import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import SimpleActionPopup from '../../components/SimpleActionPopup'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import FormInput from '../../components/FormInput'

import { WhatsApp } from '@material-ui/icons'
import FunnelTop from '../../assets/images/funnel-top.svg'
import FunnelMid from '../../assets/images/funnel-mid.svg'
import FunnelBottom from '../../assets/images/funnel-bottom.svg'
import FunnelSquare from '../../assets/images/funnel-square.svg'

import '../../styles/containers/Sales/Funnel.css'
import { addLead, getCRM, getTestingConsultants } from '../../services/utils'
import { successToast } from '../../helpers/ToastHelper'
import {
  getSubscriptionTime,
  getUserExperience,
  sortDataByKey
} from '../../helpers/SalesHelpers'
import { getPlanName } from '../../helpers/ConsultantDetailsHelpers'

import { DateTime } from 'luxon'
import Loading from '../../components/Loading'

const userTableHeaders = ['ID', 'Nome', 'E-mail', 'Telefone', 'Plano']
const testingUserTableHeaders = [
  'ID',
  'Nome',
  'E-mail',
  'Chance',
  'Telefone',
  'Plano'
]

const Funnel = props => {
  let navigate = useNavigate()

  const transformUserData = userData => {
    return userData.map(user => {
      if (!user.currentPlan) {
        user.currentPlan = 'Não assina'
      }
      return user
    })
  }

  const getLeadUsers = users => {
    const lala = users.filter(user => {
      return user.isLead
    })
    return lala
  }

  const getTestingUsers = users => {
    return users.filter(user => {
      return user.currentPlan === 'Teste'
    })
  }

  const getSubscribersUsers = users => {
    return users.filter(user => {
      return user.currentPlan !== 'Não assina' && user.currentPlan !== 'Teste'
    })
  }

  const getNotConvertedUsers = users => {
    return users.filter(user => {
      return user.currentPlan === 'Não assina' && !user.isLead
    })
  }

  let [isLoading, setIsLoading] = useState(true)
  let [users, setUsers] = useState([])
  let [filteredUserData, setFilteredUserData] = useState(users)
  let [userDataSearchResult, setUserDataSearchResult] = useState(
    filteredUserData
  )

  let [leadUsers, setLeadUsers] = useState(getLeadUsers(users))
  let [paginatedLeadUsers, setPaginatedLeadUsers] = useState(leadUsers)

  let [testingUsers, setTestingUsers] = useState(getTestingUsers(users))
  let [paginatedTestingUsers, setPaginatedTestingUsers] = useState(testingUsers)

  let [subscribersUsers, setSubscribersUsers] = useState(
    getSubscribersUsers(users)
  )
  let [paginatedSubscribersUsers, setPaginatedSubscribersUsers] = useState(
    subscribersUsers
  )

  let [notConvertedUsers, setNotConvertedUsers] = useState(
    getNotConvertedUsers(users)
  )
  let [paginatedNotConvertedUsers, setPaginatedNotConvertedUsers] = useState(
    subscribersUsers
  )

  let [showAddLeadPopup, setShowAddLeadPopup] = useState(false)
  let [popupLoading, setPopupLoading] = useState(false)

  let [name, setName] = useState('')
  let [email, setEmail] = useState('')
  let [phoneNumber, setPhoneNumber] = useState('')
  let [instagram, setInstagram] = useState('')
  let [address, setAddress] = useState('')
  let [city, setCity] = useState('')
  let [state, setState] = useState('')

  let [subscribersFilter, setSubscribersFilter] = useState(true)
  let [testersFilter, setTestersFilter] = useState(true)
  let [leadsOpenFilter, setLeadsOpenFilter] = useState(true)
  let [notConvertedFilter, setNotConvertedFilter] = useState(true)
  let [leadsLostFilter, setLeadsLostFilter] = useState(false)
  let [leadsConvertedFilter, setLeadsConvertedFilter] = useState(false)

  let [firstDateInput, setFirstDateInput] = useState(null)
  let [secondDateInput, setSecondDateInput] = useState(null)

  //Items for table sort
  let [selectedSortHeaderLead, setSelectedSortHeaderLead] = useState(0)
  let [selectedSortHeaderTesting, setSelectedSortHeaderTesting] = useState(0)
  let [
    selectedSortHeaderSubscribers,
    setSelectedSortHeaderSubscribers
  ] = useState(0)
  let [
    selectedSortHeaderNotConverted,
    setSelectedSortHeaderNotConverted
  ] = useState(0)

  let [sortOrientationLead, setSortOrientationLead] = useState('ASC')
  let [testingSortOrientation, setTestingSortOrientation] = useState('DESC')
  let [sortOrientationSubscribers, setSortOrientationSubscribers] = useState(
    'ASC'
  )
  let [sortOrientationNotConverted, setSortOrientationNotConverted] = useState(
    'ASC'
  )

  const filterList = [
    {
      label: 'Assinantes',
      filterState: subscribersFilter,
      setFilter: value => setSubscribersFilter(value)
    },
    {
      label: 'Em Teste',
      filterState: testersFilter,
      setFilter: value => setTestersFilter(value)
    },
    {
      label: 'Leads Abertos',
      filterState: leadsOpenFilter,
      setFilter: value => setLeadsOpenFilter(value)
    },
    {
      label: 'Não assinou',
      filterState: notConvertedFilter,
      setFilter: value => setNotConvertedFilter(value)
    },
    {
      label: 'Leads Convertidos',
      filterState: leadsConvertedFilter,
      setFilter: value => setLeadsConvertedFilter(value)
    },
    {
      label: 'Leads Perdidos',
      filterState: leadsLostFilter,
      setFilter: value => setLeadsLostFilter(value)
    }
  ]

  const getUsers = useCallback(async () => {
    try {
      const testingConsultantsWithDetails = await getTestingConsultants(
        props.token
      )

      const crm = await getCRM(props.token)
      const userData = crm.map(item => {
        const user = {
          id: item.id,
          name: item.name,
          email: item.email,
          phoneNumber: item.phone,
          city: item.city || 'Não preenchido',
          state: item.state || 'Não preenchido',
          experienceId: item.experienceId,
          subscriptionDate: item.subscriptionCreatedAt
            ? DateTime.fromISO(item.subscriptionCreatedAt).toFormat(
                'yyyy-MM-dd'
              )
            : undefined,
          currentPlan:
            item.subscriptionPlanIdentifier === 1 &&
            (item.subscriptionStatus === 'active' ||
              item.subscriptionStatus === 'awaitingCancel') &&
            (!item.subscriptionValidUntilDay ||
              DateTime.fromISO(item.subscriptionValidUntilDay).diffNow()) > 0
              ? 'Teste'
              : item.subscriptionStatus === 'cancelled' ||
                (item.subscriptionPlanIdentifier === 5 &&
                  item.subscriptionStatus !== 'cancelled') ||
                item.email.includes('@liven') ||
                item.email.includes('casarotto')
              ? 'Não assina'
              : getPlanName(item.subscriptionPlanIdentifier),
          isLead: !item.subscriptionStatus,
          status: item.status,
          createdAt: DateTime.fromISO(item.createdAt).toFormat('yyyy-MM-dd')
        }

        const consultantDetails = testingConsultantsWithDetails.find(
          testingConsultant => testingConsultant.id === user.id
        )

        user.experience = getUserExperience(user.experienceId)
        user.subscriptionTime = getSubscriptionTime(user.subscriptionDate)
        user.subscriptionChance = consultantDetails
          ? (consultantDetails.subscriptionChance * 100).toFixed(0) + '%'
          : 'Indefinido'

        return user
      })

      const users = transformUserData(userData)

      setUsers(users)
      setFilteredUserData(users)
      setLeadUsers(getLeadUsers(users))
      setPaginatedLeadUsers(getLeadUsers(users))
      setTestingUsers(getTestingUsers(users))
      setPaginatedTestingUsers(getTestingUsers(users))
      setSubscribersUsers(getSubscribersUsers(users))
      setPaginatedSubscribersUsers(getSubscribersUsers(users))
      setNotConvertedUsers(getNotConvertedUsers(users))
      setPaginatedNotConvertedUsers(getNotConvertedUsers(users))
      setUserDataSearchResult(users)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [props.token])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const userTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'currentPlan',
        className: '',
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  const testingUserTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'subscriptionChance',
        className: '',
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        action: phoneNumber => {
          SendWhatsappMessage(phoneNumber, '')
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'currentPlan',
        className: '',
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const filteredUserData = userDataSearchResult.filter(item => {
      // TODO: define the desired date field to be considered in this period filter
      if (item.createdAt < firstDateInput || item.createdAt > secondDateInput) {
        return false
      }
      return true
    })
    const filteredUserDataForLeads = userDataSearchResult.filter(item => {
      // TODO: define the desired date to be considered in this period filter
      if (item.createdAt < firstDateInput || item.createdAt > secondDateInput) {
        return false
      }

      if (leadsOpenFilter) {
        if (item.status === 'open') {
          return true
        }
      }
      if (leadsConvertedFilter) {
        if (item.status === 'converted') {
          return true
        }
      }
      if (leadsLostFilter) {
        if (item.status === 'lost') {
          return true
        }
      }

      return false
    })

    const copyFilteredUserDataForTesters = [...filteredUserData]
    const copyFilteredUserDataForNotConverted = [...filteredUserData]
    const copyFilteredUserDataForSubscribers = [...filteredUserData]

    //Filter lead
    filteredUserData.sort(
      sortDataByKey(
        userTableKeys[selectedSortHeaderLead].key,
        sortOrientationLead
      )
    )

    //Filter Testers
    copyFilteredUserDataForTesters.sort(
      sortDataByKey(
        testingUserTableKeys[selectedSortHeaderTesting].key,
        testingSortOrientation
      )
    )

    //Filter Subscribers
    copyFilteredUserDataForSubscribers.sort(
      sortDataByKey(
        testingUserTableKeys[selectedSortHeaderSubscribers].key,
        sortOrientationSubscribers
      )
    )
    //Filter Not Converted
    copyFilteredUserDataForNotConverted.sort(
      sortDataByKey(
        testingUserTableKeys[selectedSortHeaderNotConverted].key,
        sortOrientationNotConverted
      )
    )

    setLeadUsers(getLeadUsers(filteredUserDataForLeads))
    setTestingUsers(getTestingUsers(copyFilteredUserDataForTesters))
    setSubscribersUsers(getSubscribersUsers(copyFilteredUserDataForSubscribers))
    setNotConvertedUsers(
      getNotConvertedUsers(copyFilteredUserDataForNotConverted)
    )
    // setFilteredUserData(filteredUserData)
  }, [
    userDataSearchResult,
    firstDateInput,
    secondDateInput,
    userTableKeys,
    testingUserTableKeys,
    selectedSortHeaderLead,
    testingSortOrientation,
    sortOrientationLead,
    selectedSortHeaderTesting,
    selectedSortHeaderSubscribers,
    sortOrientationSubscribers,
    selectedSortHeaderNotConverted,
    sortOrientationNotConverted,
    leadsOpenFilter,
    leadsConvertedFilter,
    leadsLostFilter
  ])

  const onClickSortHeader = (selectedHeader, table) => {
    let orientation = 'ASC'

    if (table === 'leads') {
      if (selectedSortHeaderLead === selectedHeader) {
        if (sortOrientationLead === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderLead(selectedHeader)
      setSortOrientationLead(orientation)
    }

    if (table === 'testers') {
      if (selectedSortHeaderTesting === selectedHeader) {
        if (testingSortOrientation === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderTesting(selectedHeader)
      setTestingSortOrientation(orientation)
    }

    if (table === 'subscribers') {
      if (selectedSortHeaderSubscribers === selectedHeader) {
        if (sortOrientationSubscribers === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderSubscribers(selectedHeader)
      setSortOrientationSubscribers(orientation)
    }

    if (table === 'notConverted') {
      if (selectedSortHeaderNotConverted === selectedHeader) {
        if (sortOrientationNotConverted === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }
      setSelectedSortHeaderNotConverted(selectedHeader)
      setSortOrientationNotConverted(orientation)
    }
  }

  const submitAddLead = async () => {
    const leadToAdd = {
      name,
      email,
      phone: phoneNumber,
      city,
      state,
      instagram,
      streetName: address
    }

    setPopupLoading(true)

    try {
      await addLead(props.token, leadToAdd)
      await getUsers(props.token)
      successToast('Lead adicionado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowAddLeadPopup(false)
      setPopupLoading(false)
    }

    setPopupLoading(false)
  }

  // Opens the CRM page with the selected filters
  const openFullTableCRM = selectedOnlyFilter => {
    navigate('/vendas/crm', {
      state: {
        selectedOnlyFilter,
        firstDateInput,
        secondDateInput
      }
    })
  }

  const leadPopupContent = () => {
    return (
      <div className='manage-users-popup-content-container'>
        <div className='grid grid-cols-1 gap-x-14 md:grid-cols-2'>
          <FormInput
            placeholder='Nome'
            label='Nome*'
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <FormInput
            placeholder='E-mail'
            label='E-mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FormInput
            placeholder='Telefone'
            label='Telefone'
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            mask={{
              format: '(##) #####-####'
            }}
          />
          <FormInput
            placeholder='Instagram'
            label='Instagram'
            value={instagram}
            onChange={e => setInstagram(e.target.value)}
          />
          <FormInput
            placeholder='Nome da rua, bairro, número, complemento'
            label='Endereço'
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
          <div className='grid grid-cols-3 gap-x-2'>
            <div className='col-span-2'>
              <FormInput
                placeholder='Cidade'
                label='Cidade'
                value={city}
                onChange={e => setCity(e.target.value)}
              />
            </div>
            <FormInput
              placeholder='ex: AC'
              label='Estado'
              value={state}
              onChange={e => setState(e.target.value)}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Funil de Vendas</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={users}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
          filterList={filterList}
          firstDateInput={firstDateInput}
          setFirstDateInput={setFirstDateInput}
          secondDateInput={secondDateInput}
          setSecondDateInput={setSecondDateInput}
        />
      </div>
      {/* TODO: Add responsive layout */}
      {isLoading ? (
        <Loading />
      ) : (
        <div className='normal-table-section'>
          <div className='grid grid-cols-1 gap-x-14 md:grid-cols-5'>
            {(leadsOpenFilter || leadsConvertedFilter || leadsLostFilter) && (
              <>
                <div className='col-span-2 flex justify-center items-center relative mb-8'>
                  <img src={FunnelTop} alt='Topo Funil' />
                  <div className='absolute flex flex-col items-center'>
                    <h3>{leadUsers.length}</h3>
                    <p>{leadUsers.length === 1 ? 'Lead' : 'Leads'}</p>
                  </div>
                </div>
                <div className='col-span-3 mb-8'>
                  <BasicTable
                    filteredData={leadUsers}
                    tableHeaders={userTableHeaders}
                    tableKeys={userTableKeys}
                    paginatedData={paginatedLeadUsers}
                    setPaginatedData={setPaginatedLeadUsers}
                    onPressAddButton={() => {
                      setName('')
                      setEmail('')
                      setPhoneNumber('')
                      setInstagram('')
                      setAddress('')
                      setCity('')
                      setState('')
                      setShowAddLeadPopup(true)
                    }}
                    openAction={() => openFullTableCRM('leads')}
                    onClickSortHeader={onClickSortHeader}
                    selectedSortHeader={selectedSortHeaderLead}
                    sortOrientation={sortOrientationLead}
                    tableType={'leads'}
                  />
                </div>
              </>
            )}
            {testersFilter && (
              <>
                <div className='col-span-2 flex justify-center items-center relative mb-8'>
                  <img src={FunnelMid} alt='Meio Funil' />
                  <div className='absolute flex flex-col items-center'>
                    <h3>{testingUsers.length}</h3>
                    <p>Testando</p>
                  </div>
                </div>
                <div className='col-span-3 mb-8'>
                  <BasicTable
                    filteredData={testingUsers}
                    tableHeaders={testingUserTableHeaders}
                    tableKeys={testingUserTableKeys}
                    paginatedData={paginatedTestingUsers}
                    setPaginatedData={setPaginatedTestingUsers}
                    openAction={() => openFullTableCRM('testers')}
                    onClickSortHeader={onClickSortHeader}
                    selectedSortHeader={selectedSortHeaderTesting}
                    sortOrientation={testingSortOrientation}
                    tableType={'testers'}
                  />
                </div>
              </>
            )}

            {subscribersFilter && (
              <>
                <div className='col-span-2 flex justify-center items-center relative mb-8'>
                  <img src={FunnelBottom} alt='Fundo Funil' />
                  <div className='absolute flex flex-col items-center mt-[-100px]'>
                    <h3 className='bottom-funnel-text'>
                      {subscribersUsers.length}
                    </h3>
                    <p className='bottom-funnel-text'>
                      {subscribersUsers.length === 1
                        ? 'Assinante'
                        : 'Assinantes'}
                    </p>
                  </div>
                </div>
                <div className='col-span-3 mb-8'>
                  <BasicTable
                    filteredData={subscribersUsers}
                    tableHeaders={userTableHeaders}
                    tableKeys={userTableKeys}
                    paginatedData={paginatedSubscribersUsers}
                    setPaginatedData={setPaginatedSubscribersUsers}
                    openAction={() => openFullTableCRM('subscribers')}
                    onClickSortHeader={onClickSortHeader}
                    selectedSortHeader={selectedSortHeaderSubscribers}
                    sortOrientation={sortOrientationSubscribers}
                    tableType={'subscribers'}
                  />
                </div>
              </>
            )}

            {notConvertedFilter && (
              <>
                <div className='col-span-2 flex justify-center items-center relative mb-8'>
                  <img src={FunnelSquare} alt='Não Assinantes' />
                  <div className='absolute flex flex-col items-center'>
                    <h3>{notConvertedUsers.length}</h3>
                    <p>
                      {notConvertedUsers.length === 1
                        ? 'Não assinou'
                        : 'Não assinaram'}
                    </p>
                  </div>
                </div>
                <div className='col-span-3 mb-8'>
                  <BasicTable
                    filteredData={notConvertedUsers}
                    tableHeaders={userTableHeaders}
                    tableKeys={userTableKeys}
                    paginatedData={paginatedNotConvertedUsers}
                    setPaginatedData={setPaginatedNotConvertedUsers}
                    openAction={() => openFullTableCRM('notConverted')}
                    onClickSortHeader={onClickSortHeader}
                    selectedSortHeader={selectedSortHeaderNotConverted}
                    sortOrientation={sortOrientationNotConverted}
                    tableType={'notConverted'}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {showAddLeadPopup && (
        <SimpleActionPopup
          title={'Adicionar Lead'}
          content={leadPopupContent()}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddLead}
          onClosePopup={() => setShowAddLeadPopup(false)}
          popupLoading={popupLoading}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Funnel)
