import React, { useState, useEffect } from 'react'
import SimpleActionPopup from './SimpleActionPopup'
import Select from 'react-select'
import FormInput from './FormInput'
import '../styles/components/SubscriptionHistoryPopup.css'
import CopyIcon from '../assets/images/copy.svg'
import BasicTable from './BasicTable'
import useDimensions from '../hooks/useDimensions'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'
import {
  cancelSubscription,
  createUserSubscription,
  extendUserSubscriptionAdmin,
  getUserSubscriptions,
  scheduleCancelSubscription,
  getCoupons
} from '../services/utils'
import { useCallback } from 'react'
import { getPlanName, getPlanStatus } from '../helpers/ConsultantDetailsHelpers'
import { errorToast, successToast } from '../helpers/ToastHelper'
import { summarizeIuguCode } from '../helpers/ClientSucessHelpers'

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '34px !important',
    minHeight: '34px !important',
    borderRadius: 20,
    border: state.isFocused
      ? '1px solid var(--identity-6) !important'
      : '1px solid var(--identity-4) !important',
    fontSize: 14,
    boxShadow: '-1px 2px 4px rgba(0, 0, 0, 0.25)'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px !important',
    padding: '0 6px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px !important'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'var(--identity-4)'
  })
}

const planOptions = [
  { value: 1, label: 'Teste Grátis' },
  { value: 5, label: 'Completo Gratuito' },
  { value: 2, label: '4 Estações' },
  { value: 16, label: '4 Estações Trimestral' },
  { value: 10, label: '4 Estações Semestral' },
  { value: 12, label: 'Cores' },
  { value: 13, label: 'Minimalista Mensal' },
  { value: 14, label: 'Minimalista Trimestral' },
  { value: 15, label: 'Minimalista Semestral' },
  { value: 3, label: 'Futurista' },
  { value: 4, label: '[LEGADO] Minimalista' },
  { value: 7, label: '[LEGADO] Minimalista 10 Clientes' },
  { value: 8, label: '4 Estações 10 Clientes' },
  { value: 9, label: '[LEGADO] Minimalista Semestral' },
  { value: 11, label: '4 Estações 5 Clientes' }
]
const planOptionsDev = [
  { value: 334, label: 'Teste' },
  { value: 765, label: 'Cores' },
  { value: 725, label: 'Minimalista Mensal' },
  { value: 956, label: 'Minimalista Trimestral' },
  { value: 955, label: 'Minimalista Semestral' },
  { value: 727, label: 'Quatro Estações' },
  { value: 958, label: 'Quatro Estações Trimestral' },
  { value: 957, label: 'Quatro Estações Semestral' }
]

const periodOptions = [
  { value: '7', label: '7 dias' },
  { value: '1', label: '1 mês' },
  { value: '3', label: '3 meses' },
  { value: '6', label: '6 meses' },
  { value: '12', label: '1 ano' },
  { value: '999', label: 'até 28/03/2031' }
]

const tableHeaders = [
  'Id',
  'Plano',
  'Status',
  'Próxima Cobrança',
  'Validade',
  'Criada em',
  'Cupom',
  'Código IUGU'
]

const tableKeys = [
  { key: 'id' },
  { key: 'plan' },
  { key: 'status' },
  { key: 'nextBillingDay' },
  { key: 'validUntil' },
  { key: 'createdAt' },
  { key: 'coupon' },
  {
    key: 'summarizedIuguCode',
    positionIcon: 'left',
    passObjectToAction: true,
    className: '',
    action: subscription => {
      //If iugu code is empty call errorToast
      if (subscription.iuguCode === '') {
        errorToast('Error ao copiar! Código Iugu vazio!')
        navigator.clipboard.writeText(' ')
      } else {
        successToast('Código Iugu copiado com sucesso!')
        navigator.clipboard.writeText(subscription.iuguCode)
      }
    },
    icon: (
      <button style={{ width: '25px' }}>
        <img src={CopyIcon} alt='copy' />
      </button>
    )
  }
]

const SubscriptionHistoryPopup = ({
  userId,
  userName,
  refetch: refetchSubscriptions,
  setShowPopup,
  token
}) => {
  let [loading, setLoading] = useState(true)
  let [coupon, setCoupon] = useState(null)
  let [plan, setPlan] = useState(null)
  let [iuguCode, setIuguCode] = useState('')
  let [period, setPeriod] = useState(null)
  let [selectedSortHeader, setSelectedSortHeader] = useState(0)
  let [sortOrientation, setSortOrientation] = useState('ASC')
  let [paginatedData, setPaginatedata] = useState([])
  let [subscriptionHistory, setSubscriptionHistory] = useState([])
  let [couponOptions, setCouponOptions] = useState([])

  // can be: cancel, scheduleCancel, null
  let [confirmMessage, setConfirmMessage] = useState(null)

  // can be: create, extend, cancel
  let [selectedMenu, setSelectedMenu] = useState('create')

  const { dimensions } = useDimensions()

  const fetchUserSubscriptions = useCallback(() => {
    getUserSubscriptions(token, userId)
      .then(subscriptions => {
        const subscriptionsToSet = subscriptions.map(subscription => ({
          id: subscription.id || 'Indefinido',
          validUntil: subscription.validUntilDay
            ? DateTime.fromISO(subscription.validUntilDay).toFormat(
                'dd/MM/yyyy'
              )
            : 'Indefinido',
          nextBillingDay: subscription.nextBillingDay
            ? DateTime.fromFormat(
                subscription.nextBillingDay,
                'yyyy-MM-dd'
              ).toFormat('dd/MM/yyyy')
            : 'Indefinido',
          plan: subscription.planIdentifier
            ? getPlanName(subscription.planIdentifier)
            : 'Indefinido',
          status: getPlanStatus(subscription.status),
          coupon: subscription.couponCode || '',
          createdAt: subscription.createdAt
            ? DateTime.fromISO(subscription.createdAt).toFormat('dd/MM/yyyy')
            : 'Indefinido',
          userId: subscription.userId,
          summarizedIuguCode: summarizeIuguCode(
            subscription.externalSubscriptionId
          ),
          iuguCode: subscription.externalSubscriptionId || ''
        }))

        setSubscriptionHistory(subscriptionsToSet)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [token, userId])

  const fetchCoupons = useCallback(() => {
    getCoupons(token, userId)
      .then(coupons => {  

        const couponsList = coupons.map(coupon => {
          return {
            value: coupon.code,
            label: coupon.code
          }
        })

        setCouponOptions(couponsList)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [token, userId])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  const rowStyle = subscription => {
    if (
      subscription.status === 'Ativo' &&
      subscription.validUntilDay &&
      DateTime.now().diff(DateTime.fromISO(subscription.validUntilDay)) < 0
    ) {
      return { backgroundColor: '#FFC186' }
    }

    if (
      subscription.status === 'Ativo' &&
      subscription.nextBillingDay !== 'Indefinido'
    ) {
      return { backgroundColor: '#D1F1BF' }
    }

    if (
      (subscription.status === 'Ativo' ||
        subscription.status === 'Aguardando cancelamento') &&
      subscription.nextBillingDay === 'Indefinido' &&
      subscription.plan !== 'Teste'
    ) {
      return { backgroundColor: '#ECD0F1' }
    }

    if (subscription.status === 'Cancelado') {
      return { backgroundColor: '#FFC3C3' }
    }

    if (
      subscription.status === 'Aguardando cancelamento' &&
      subscription.plan !== 'Teste' &&
      subscription.nextBillingDay !== 'Indefinido'
    ) {
      return { backgroundColor: '#FFF9C2' }
    }

    if (
      subscription.status === 'Aguardando cancelamento' &&
      subscription.plan === 'Teste'
    ) {
      return { backgroundColor: '#DDE6FF' }
    }

    if (subscription.couponCode !== '') {
      return {}
    }

    return {}
  }

  const handleCreateUserSubscription = () => {
    if (plan === null) {
      return errorToast('Selecione um plano!')
    }

    setLoading(true)

    const body = {
      planId: plan.value,
      couponCode: coupon && coupon.value,
      externalSubscriptionId: iuguCode
    }

    createUserSubscription(token, userId, body)
      .then(() => {
        setPlan(null)
        setCoupon(null)
        setIuguCode('')
        refetchSubscriptions()
        fetchUserSubscriptions()
        successToast('Assinatura criada com sucesso!')
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleExtendUserSubscription = () => {
    if (period === null) {
      return errorToast('Selecione o período!')
    }

    setLoading(true)

    const body = {
      extension: period.value
    }

    extendUserSubscriptionAdmin(token, userId, body)
      .then(() => {
        setPeriod(null)
        refetchSubscriptions()
        fetchUserSubscriptions()
        successToast('Periodo extendido.')
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleScheduleCancelSubscription = () => {
    setLoading(true)
    setConfirmMessage(null)

    scheduleCancelSubscription(token, userId)
      .then(() => {
        refetchSubscriptions()
        fetchUserSubscriptions()
        successToast('Cancelamento agendado.')
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleCancelSubscription = () => {
    setLoading(true)
    setConfirmMessage(null)

    cancelSubscription(token, userId)
      .then(() => {
        refetchSubscriptions()
        fetchUserSubscriptions()
        successToast('Assinatura cancelada.')
      })
      .catch(err => {
        console.error(err)
        setLoading(false)
      })
  }

  const renderCreateMenu = () => {
    return (
      <div className='subscription-popup-createmenu'>
        <div className='subscription-popup-createmenu-text'>
          <b>Cria</b> uma assinatura e <b>cancela todas as outras anteriores</b>{' '}
          da consultora. O plano define qual será o tempo liberado (Teste Grátis
          = 5 dias, trimestral = 3 meses, semestral = 6 meses e todas as outras
          = 1 mês).
        </div>
        <div className='subscription-popup-createmenu-dropdowns'>
          <Select
            className='react-select__control subscription-popup-createmenu-dropdown'
            classNamePrefix='react-select'
            styles={customSelectStyles}
            onChange={plan => setPlan(plan)}
            options={
              process.env.REACT_APP_ENV === 'development'
                ? planOptionsDev
                : planOptions
            }
            placeholder={'Selecione o plano'}
            noOptionsMessage={() => 'Sem opções'}
            value={plan}
          />
          <Select
            className='react-select__control subscription-popup-createmenu-dropdown'
            classNamePrefix='react-select'
            styles={customSelectStyles}
            onChange={coupon => setCoupon(coupon)}
            options={couponOptions}
            placeholder={'Selecione o cupom'}
            noOptionsMessage={() => 'Sem opções'}
            value={coupon}
          />
        </div>
        <div className='subscription-popup-createmenu-input'>
          <FormInput
            inputClassName={'form-input-white-background'}
            placeholder='Código Iugu'
            value={iuguCode}
            onChange={e => setIuguCode(e.target.value)}
            maxLength={35}
            bgColor={'white'}
          />
        </div>
        <button
          onClick={handleCreateUserSubscription}
          className='subscription-popup-createmenu-submit'
        >
          Criar Assinatura
        </button>
      </div>
    )
  }

  const renderExtendMenu = () => {
    return (
      <div className='subscription-popup-extendmenu'>
        <div className='subscription-popup-extendmenu-text'>
          <b>Estende</b> a validade de uma <b>assinatura</b> de acordo com o{' '}
          <b>período selecionado</b>. Caso o <b>pagamento</b> seja{' '}
          <b>integrado com o sistema</b>, a assinatura terá a próxima cobrança
          <b> atrasada</b> de acordo com o período selecionado.
        </div>
        <div className='subscription-popup-extendmenu-dropdowns'>
          <Select
            className='react-select__control subscription-popup-extendmenu-dropdown'
            classNamePrefix='react-select'
            styles={customSelectStyles}
            onChange={period => setPeriod(period)}
            options={periodOptions}
            placeholder={'Selecione o período'}
            noOptionsMessage={() => 'Sem opções'}
            value={period}
          />
        </div>
        <button
          onClick={handleExtendUserSubscription}
          className='subscription-popup-extendmenu-submit'
        >
          Estender Validade
        </button>
      </div>
    )
  }

  const renderCancelMenu = () => {
    return confirmMessage === 'scheduleCancel' ? (
      renderConfirmScheduleCancelMessage()
    ) : confirmMessage === 'cancel' ? (
      renderConfirmCancelMessage()
    ) : (
      <div className='subscription-popup-cancelmenu'>
        <div className='subscription-popup-cancelmenu-text'>
          <b>Cancelar Assinatura:</b> cancela imediatamente todas as assinaturas
          da consultora
          <br />
          <b>Agendar Cancelamento:</b> muda o status da última assinatura para
          "Aguardando Cancelamento".
          <br />A assinatura é cancelada no dia seguinte ao dia da validade.
        </div>
        <div className='subscription-popup-cancelmenu-buttons'>
          <button
            onClick={() => setConfirmMessage('scheduleCancel')}
            className='subscription-popup-cancelmenu-submit subscription-schedule-btn'
          >
            Agendar Cancelamento
          </button>
          <button
            onClick={() => setConfirmMessage('cancel')}
            className='subscription-popup-cancelmenu-submit subscription-cancel-btn'
          >
            Cancelar Assinatura
          </button>
        </div>
      </div>
    )
  }

  const renderConfirmScheduleCancelMessage = () => {
    return (
      <div className='subscription-popup-cancelmenu'>
        <div className='subscription-popup-confirmmessage'>
          Deseja realmente <b>agendar o cancelamento</b> da assinatura?
        </div>
        <div className='subscription-popup-cancelmenu-buttons'>
          <button
            onClick={() => setConfirmMessage(null)}
            className='subscription-popup-cancelmenu-submit subscription-cancel-btn'
          >
            Não agendar
          </button>
          <button
            onClick={handleScheduleCancelSubscription}
            className='subscription-popup-cancelmenu-submit subscription-confirm-btn'
          >
            Agendar
          </button>
        </div>
      </div>
    )
  }

  const renderConfirmCancelMessage = () => {
    return (
      <div className='subscription-popup-cancelmenu'>
        <div className='subscription-popup-confirmmessage'>
          Deseja realmente <b>cancelar</b> a assinatura?
        </div>
        <div className='subscription-popup-cancelmenu-buttons'>
          <button
            onClick={() => setConfirmMessage(null)}
            className='subscription-popup-cancelmenu-submit subscription-cancel-btn'
          >
            Não cancelar
          </button>
          <button
            onClick={handleCancelSubscription}
            className='subscription-popup-cancelmenu-submit subscription-confirm-btn'
          >
            Cancelar
          </button>
        </div>
      </div>
    )
  }

  const popupContent = () => {
    return (
      <div className='subscription-popup-container'>
        <h4>Edição da última assinatura</h4>
        <div className='subscription-popup-menu'>
          <div className='subscription-popup-menu-options'>
            <div
              onClick={() => setSelectedMenu('create')}
              className={`subscription-popup-menu-option${
                selectedMenu === 'create' ? ' selected' : ''
              }`}
            >
              Criar assinatura
            </div>
            <div
              onClick={() => setSelectedMenu('extend')}
              className={`subscription-popup-menu-option${
                selectedMenu === 'extend' ? ' selected' : ''
              }`}
            >
              Estender assinatura
            </div>
            <div
              onClick={() => setSelectedMenu('cancel')}
              className={`subscription-popup-menu-option${
                selectedMenu === 'cancel' ? ' selected' : ''
              }`}
            >
              Cancelar assinatura
            </div>
          </div>
          {selectedMenu === 'cancel'
            ? renderCancelMenu()
            : selectedMenu === 'create'
            ? renderCreateMenu()
            : renderExtendMenu()}
        </div>
        <br />
        <h4 className='mt-20'>Histórico de assinaturas</h4>
        <h5 className='subtitles-label'>Legendas</h5>
        <div className='subtitles-container'>
          <div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#D1F1BF' }}
              />
              Ativo
            </div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#FFC3C3' }}
              />
              Cancelado
            </div>
          </div>
          <div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#ECD0F1' }}
              />
              Liberado Manualmente
            </div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#FFF9C2' }}
              />
              Aguardando Cancelamento
            </div>
          </div>
          <div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#DDE6FF' }}
              />
              Teste
            </div>
            <div className='subtitles-item'>
              <div
                className='subtitle-ball'
                style={{ background: '#FFC186' }}
              />
              Contatar equipe de tecnologia
            </div>
          </div>
        </div>
        <div className='subscription-popup-table'>
          <BasicTable
            itemsPerPage={3}
            tableHeaders={tableHeaders}
            tableKeys={tableKeys}
            filteredData={subscriptionHistory}
            paginatedData={paginatedData}
            setPaginatedData={setPaginatedata}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
            rowStyle={rowStyle}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    fetchUserSubscriptions()
    fetchCoupons()
  }, [fetchUserSubscriptions, fetchCoupons])

  return (
    <SimpleActionPopup
      title={userName}
      content={popupContent()}
      onClosePopup={() => setShowPopup(false)}
      popupLoading={loading}
      containerStyle={{
        marginTop: 20,
        maxHeight: dimensions.innerHeight - 20 - 20,
        overflow: 'auto'
      }}
    />
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    token: user.token
  }
}

export default connect(mapStateToProps)(SubscriptionHistoryPopup)
