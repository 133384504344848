import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'

import FormInput from '../components/FormInput'
import FormButton from '../components/FormButton'
import { successToast } from '../helpers/ToastHelper'
import '../styles/containers/MyData.css'
import { getMe, selfUpdateUser } from '../services/utils'
import Loading from '../components/Loading'

const MyData = props => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)

  const submitMyDataUpdate = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      await selfUpdateUser(props.token, {
        name,
        phone: phoneNumber
      }).then(response => {
        props.setUser(response)
      })
      successToast('Sucesso')
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    // TODO: remove this request, because the user will come from the redux state
    getMe(props.token)
      .then(user => {
        setName(user.name)
        setEmail(user.email)
        setPhoneNumber(user.phone)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [props])

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Meus Dados</h2>
      </div>
      <div className='my-data-section-header-container'>
        <h4 className='my-data-section-header'>Informações</h4>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className='my-data-input-container'>
          <form onSubmit={submitMyDataUpdate}>
            <div className='grid grid-cols-1 gap-x-14 md:grid-cols-2'>
              <FormInput
                placeholder='Nome'
                label='Nome'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <FormInput
                placeholder='E-mail'
                label='E-mail'
                value={email}
                onChange={e => setEmail(e.target.value)}
                readOnly
              />
              <FormInput
                placeholder='Telefone'
                label='Telefone'
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                mask={{
                  format: '(##) #####-####'
                }}
              />
            </div>
            <div className='my-data-button-container'>
              <FormButton loading={loading} className={'success-button'}>
                ATUALIZAR DADOS
              </FormButton>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps, actions)(MyData)
