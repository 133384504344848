import { useCallback, useEffect } from 'react'
import { useState } from 'react'
import {
  NavigateNextOutlined,
  NavigateBeforeOutlined
} from '@material-ui/icons'

import '../styles/components/Pagination.css'

const Pagination = ({ itemList, setPaginatedItemList, itemsPerPage = 4 }) => {
  const maxPagesShowed = 5

  let [currrentPage, setCurrentPage] = useState(1) // begins at page 1
  let [pages, setPages] = useState([])
  let [lastPageNumber, setLastPageNumber] = useState(
    Math.ceil(itemList.length / itemsPerPage)
  )
  // If the total number of pages is less than maxPagesShowed, then we need to recalculate what we will show to the user
  let [currentMaxPagesShowed, setCurrentMaxPagesShowed] = useState(
    maxPagesShowed
  )

  // Creates the first pages to show to user when component is loaded
  const createFirstPagesArray = useCallback(() => {
    setPaginatedItemList(itemList.slice(0, itemsPerPage))
    let pageNumber = 1
    let newPages = []
    for (
      let i = 0;
      i < itemList.length && pageNumber <= maxPagesShowed;
      i += itemsPerPage
    ) {
      newPages.push({ page: pageNumber++ })
    }
    setPages(newPages)
  }, [itemList, setPaginatedItemList, itemsPerPage])

  // Creates the last pages for when user clicks to go to the end of the list
  const createLastPagesArray = () => {
    setPaginatedItemList(
      itemList.slice(
        (lastPageNumber - 1) * itemsPerPage,
        lastPageNumber * itemsPerPage
      )
    )
    let pageNumber = Math.ceil(itemList.length / itemsPerPage)
    let newPages = []
    for (
      let i = itemList.length;
      i > 0 && pageNumber > lastPageNumber - maxPagesShowed;
      i -= itemsPerPage
    ) {
      newPages.unshift({ page: pageNumber-- })
    }
    setPages(newPages)
  }

  // Every time itemList changes, we need to recalculate all limit variables. The current page will be set to the first.
  useEffect(() => {
    createFirstPagesArray()
    const calculatedLastPageNumber = Math.ceil(itemList.length / itemsPerPage)
    setLastPageNumber(calculatedLastPageNumber)
    setCurrentPage(1)
    if (calculatedLastPageNumber < maxPagesShowed) {
      setCurrentMaxPagesShowed(calculatedLastPageNumber)
    }
  }, [itemList, createFirstPagesArray, itemsPerPage])

  const goToNext = () => {
    if (itemList.length > currrentPage * itemsPerPage) {
      setCurrentPage(++currrentPage)
      setPaginatedItemList(
        itemList.slice(
          (currrentPage - 1) * itemsPerPage,
          currrentPage * itemsPerPage
        )
      )
      if (currrentPage - 1 === pages[currentMaxPagesShowed - 1].page) {
        let newPages = pages.slice(1, currentMaxPagesShowed)
        newPages.push({ page: currrentPage })
        setPages(newPages)
      }
    }
  }
  const goBack = () => {
    if (currrentPage > 1) {
      setCurrentPage(--currrentPage)
      setPaginatedItemList(
        itemList.slice(
          (currrentPage - 1) * itemsPerPage,
          currrentPage * itemsPerPage
        )
      )
      if (currrentPage + 1 === pages[0].page) {
        let newPages = pages.slice(0, currentMaxPagesShowed - 1)
        newPages.unshift({ page: currrentPage })
        setPages(newPages)
      }
    }
  }

  const goToStart = () => {
    setCurrentPage(1)
    createFirstPagesArray()
  }

  const goToEnd = () => {
    setCurrentPage(lastPageNumber)
    createLastPagesArray()
  }
  return pages.length === 0 ? (
    <div></div>
  ) : (
    <div className='pagination-container flex rounded-lg'>
      <button
        onClick={goToStart}
        className='h-8 border border-r-0 pagination-border pagination-button  
               px-4 rounded-l-lg '
      >
        <div className='flex'>
          <div className='pagination-double-arrow-left'>
            <NavigateBeforeOutlined className='pagination-arrow-icon' />
          </div>
          <div>
            <NavigateBeforeOutlined className='pagination-arrow-icon' />
          </div>
        </div>
      </button>

      <button
        onClick={goBack}
        className='h-8 border border-r-0 pagination-border pagination-button  
               px-4 '
      >
        <NavigateBeforeOutlined className='pagination-arrow-icon' />
      </button>
      {pages.map((pg, i) => (
        <button
          key={i}
          onClick={() => {
            setPaginatedItemList(
              itemList.slice(
                (pg.page - 1) * itemsPerPage,
                pg.page * itemsPerPage
              )
            )
            setCurrentPage(pg.page)
          }}
          className={`h-8 border border-r-0 pagination-border pagination-button
               w-8 ${currrentPage === pg.page &&
                 'pagination-button-selected text-white'}`}
        >
          {pg.page}
        </button>
      ))}
      <button
        onClick={goToNext}
        className='h-8 border border-r-0 pagination-border pagination-button
               px-4'
      >
        <NavigateNextOutlined className='pagination-arrow-icon' />
      </button>
      <button
        onClick={goToEnd}
        className='h-8 border  pagination-border pagination-button
               px-4 rounded-r-lg'
      >
        <div className='flex'>
          <div className='pagination-double-arrow-left'>
            <NavigateNextOutlined className='pagination-arrow-icon' />
          </div>
          <div>
            <NavigateNextOutlined className='pagination-arrow-icon' />
          </div>
        </div>
      </button>
    </div>
  )
}

export default Pagination
