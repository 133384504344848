import React, { useState, useEffect, useCallback } from 'react'
import '../styles/components/SubscriptionHistoryPopup.css'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import EditIcon from '../assets/images/edit.svg'
import { AddRounded, DeleteOutline } from '@material-ui/icons'

import BasicCard from './BasicCard'
import FormInput from './FormInput'
import FormRadio from './FormRadio'
import SimpleActionPopup from './SimpleActionPopup'

import { CheckBoxOutlineBlank } from '@material-ui/icons'
import { CheckRounded } from '@material-ui/icons'

import {
  addCancellationReason,
  getCancellationReasonHistory,
  deleteCancellationReason,
  editCancellationReason
} from '../services/utils'
import { errorToast, successToast } from '../helpers/ToastHelper'

const checkBox = isSelected => {
  return (
    <div className='check-box-div'>
      <CheckBoxOutlineBlank className='check-box check-box-icon' />
      {isSelected && <CheckRounded className='check-box check-icon' />}
    </div>
  )
}

const CancellationReasonPopup = ({
  user,
  type,
  showPopup,
  setShowPopup,
  refetch: refetchCancellationReason,
  token
}) => {
  let [popupLoading, setPopupLoading] = useState(false)

  //variables for popup add reason cancellation
  let [
    showAddReasonCancellationPopup,
    setShowAddReasonCancellationPopup
  ] = useState(false)
  let [
    selectedUserCancellationReasonPopup,
    setSelectedUserCancellationReasonPopup
  ] = useState(user)
  let [newReasonCancellationItem, setNewReasonCancellationItem] = useState('')
  let [textOther, setTextOther] = useState('')
  let [
    newReasonCancellationItemDetails,
    setNewReasonCancellationItemDetails
  ] = useState('')

  //variables for popup reason cancellation history
  let [
    showHistoryCancellationReasonPopUp,
    setShowHistoryCancellationReasonPopUp
  ] = useState(false)
  let [
    showDeleteCancellationReasonConfirmationPopup,
    setShowDeleteCancellationReasonConfirmationPopup
  ] = useState(false)

  //Variables for popup edit reason cancellation
  let [
    showEditCancellationReasonItemPopup,
    setShowEditCancellationReasonItemPopup
  ] = useState(false)
  let [cancellationReasonToEdit, setCancellationReasonToEdit] = useState('')

  const [sendMessageWhatsapp, setSendMessageWhatsapp] = useState(true);

  const fetchCancellationReasonHistory = useCallback(
    async cancellationReason => {
      const contactHistoryToMap = await getCancellationReasonHistory(
        token,
        cancellationReason.id
      )
      const historyCancellationReason = contactHistoryToMap.map(contact => ({
        id: contact.id,
        reason: contact.cancellationShortReason,
        details: contact.cancellationDetails,
        responsibleUser: contact.responsibleUser,
        sendMessageWhatsapp: contact.sendMessageWhatsapp,
        date: DateTime.fromISO(contact.updatedAt).toFormat('dd/MM/yyyy')
      }))
      setSelectedUserCancellationReasonPopup({
        ...cancellationReason,
        cancellationReasonHistory: historyCancellationReason
      })
    },
    [token]
  )

  const radioChangeHandler = e => {
    setNewReasonCancellationItem(e.target.value)
  }

  const addReasonCancellationItemContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Selecione um motivo*:</p>
          </div>
          <FormRadio
            changed={radioChangeHandler}
            id='1'
            isSelected={
              newReasonCancellationItem ===
              'Mudou de área ou trabalha com mais de uma e não consegue conciliar no momento'
            }
            value='Mudou de área ou trabalha com mais de uma e não consegue conciliar no momento'
          />
          <FormRadio
            changed={radioChangeHandler}
            id='2'
            isSelected={newReasonCancellationItem === 'Corte de despesas'}
            value='Corte de despesas'
          />
          <FormRadio
            changed={radioChangeHandler}
            id='3'
            isSelected={
              newReasonCancellationItem ===
              'Pausa na carreira/ ou atendimentos, por algum motivo pessoal'
            }
            value='Pausa na carreira/ ou atendimentos, por algum motivo pessoal'
          />
          <FormRadio
            changed={radioChangeHandler}
            id='4'
            isSelected={
              newReasonCancellationItem === 'Não está usando o Aplicativo'
            }
            value='Não está usando o Aplicativo'
          />
          <FormRadio
            changed={radioChangeHandler}
            id='5'
            isSelected={
              newReasonCancellationItem === 'Não se adaptou ao Aplicativo'
            }
            value='Não se adaptou ao Aplicativo'
          />
          <FormRadio
            changed={radioChangeHandler}
            id='6'
            isSelected={newReasonCancellationItem === 'Outro'}
            value='Outro'
          />
          {newReasonCancellationItem === 'Outro' && (
            <div>
              <p className='small-paragraph ml-[70px] mt-[-20px] italic'>
                (insira um resumo curto):
              </p>
              <div className='ml-[50px]'>
                <FormInput
                  value={textOther}
                  onChange={e => setTextOther(e.target.value)}
                  rows={1}
                  maxLength={255}
                />
              </div>
            </div>
          )}
        </div>
        <br />
        <div>
          <FormInput
            placeholder='Detalhes - até 255 caracteres'
            label='Detalhes'
            rows={4}
            maxLength={255}
            value={newReasonCancellationItemDetails}
            onChange={e => setNewReasonCancellationItemDetails(e.target.value)}
          />
          <p className='small-paragraph mt-[-28px] italic'>
            Caracteres restantes:{' '}
            {255 - newReasonCancellationItemDetails.length}
          </p>
        </div>
        <div className="ml-2 mt-6">
          <div 
            className="w-full flex items-center cursor-pointer" 
            onClick={() => setSendMessageWhatsapp(!sendMessageWhatsapp)}
          >
            {checkBox(sendMessageWhatsapp)}
            <p className="ml-4">Enviar mensagens automáticas no WhatsApp.</p>
          </div>
        </div>
      </div>
    )
  }

  const submitAddReasonCancellationItem = async () => {
    setPopupLoading(true)

    let dataToPost = {
      cancellationShortReason:
        newReasonCancellationItem === 'Outro'
          ? textOther
          : newReasonCancellationItem,
      consultantId: selectedUserCancellationReasonPopup.id,
      sendMessageWhatsapp
    }
    if (newReasonCancellationItemDetails) {
      dataToPost = {
        ...dataToPost,
        cancellationDetails: newReasonCancellationItemDetails
      }
    }
    try {
      if (dataToPost.cancellationShortReason) {
        await addCancellationReason(token, dataToPost)
        await fetchCancellationReasonHistory(
          selectedUserCancellationReasonPopup
        )
        await refetchCancellationReason()
        setShowAddReasonCancellationPopup(false)
        setShowHistoryCancellationReasonPopUp(true)
        successToast('Motivo de cancelamento adicionado com sucesso!')
      } else {
        errorToast('Selecione um motivo!')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const historyCancellationReasonPopupContent = selectedUser => {
    const cancellationReasonCardContent = historyItem => {
      return (
        <div className='px-4 py-4 grid grid-cols-8'>
          <div className='col-span-7'>
            <div className='mb-6'>
              <p className='big-paragraph font-medium'>
                {historyItem.date} - {historyItem.responsibleUser}
              </p>
            </div>
            <div>
              <p>
                <b>Motivo:</b> {historyItem.reason}
              </p>
              <p>
                <b>Detalhes:</b> "{historyItem.details}"
              </p>
            </div>
          </div>
          <div className='flex flex-col'>
            <div
              onClick={() => {
                setCancellationReasonToEdit(historyItem.id)
                setShowHistoryCancellationReasonPopUp(false)
                setShowDeleteCancellationReasonConfirmationPopup(true)
              }}
              className='mb-4 self-end cursor-pointer'
            >
              <DeleteOutline className='delete-card-icon' />
            </div>
            <div
              className='self-end cursor-pointer'
              onClick={() => {
                setCancellationReasonToEdit(historyItem.id)
                setShowHistoryCancellationReasonPopUp(false)
                setNewReasonCancellationItem(historyItem.reason)
                setNewReasonCancellationItemDetails(
                  historyItem.details ? historyItem.details : ''
                )
                setSendMessageWhatsapp(historyItem.sendMessageWhatsapp === 1 ? true : false)
                setShowEditCancellationReasonItemPopup(true)
              }}
            >
              <img src={EditIcon} alt='Editar usuário' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className='grid grid-cols-1 max-w-[670px] m-auto max-h-[300px] overflow-auto p-2'>
          {selectedUser.length > 0 ? (
            selectedUser.map((historyItem, key) => {
              return (
                <div key={key} className='mb-6'>
                  <BasicCard
                    cardContent={cancellationReasonCardContent(historyItem)}
                  />
                </div>
              )
            })
          ) : (
            <div>
              <p>Usuário não possui histórico de motivos de cancelamento.</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const deleteCancellationReasonConfirmationPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja{' '}
          <strong>apagar esse motivo de cancelamento</strong>?
        </p>
      </div>
    )
  }

  const deleteCancellationReasonItem = async () => {
    setPopupLoading(true)

    try {
      await deleteCancellationReason(token, cancellationReasonToEdit)
      await fetchCancellationReasonHistory(selectedUserCancellationReasonPopup)
      await refetchCancellationReason()
      successToast('Motivo de cancelamento deletado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setShowDeleteCancellationReasonConfirmationPopup(false)
      setShowHistoryCancellationReasonPopUp(true)
      setShowAddReasonCancellationPopup(false)
      setPopupLoading(false)
    }
  }

  const submitEditCancellationReasonItem = async () => {
    setPopupLoading(true)

    let dataToPost = {
      cancellationShortReason:
        newReasonCancellationItem === 'Outro'
          ? textOther
          : newReasonCancellationItem,
      sendMessageWhatsapp
    }
    if (newReasonCancellationItemDetails) {
      dataToPost = {
        ...dataToPost,
        cancellationDetails: newReasonCancellationItemDetails
      }
    }

    try {
      await editCancellationReason(token, cancellationReasonToEdit, dataToPost)
      await fetchCancellationReasonHistory(selectedUserCancellationReasonPopup)
      await refetchCancellationReason()
      setShowEditCancellationReasonItemPopup(false)
      setShowHistoryCancellationReasonPopUp(true)
      successToast('Motivo de Cancelamento editado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
      setShowAddReasonCancellationPopup(false)
    }
  }

  const addCancellationReasonItemPopup = () => {
    setNewReasonCancellationItem('')
    setNewReasonCancellationItemDetails('')
    setTextOther('')
    setShowHistoryCancellationReasonPopUp(false)
    setShowAddReasonCancellationPopup(true)
  }

  const CustomAddButton = () => {
    return (
      <button
        className='cancellations-add-reason-item-button'
        onClick={addCancellationReasonItemPopup}
      >
        <AddRounded className='basic-table-add-icon' />
      </button>
    )
  }

  useEffect(() => {
    if (type === 'add') {
      setShowAddReasonCancellationPopup(showPopup)
    } else {
      const historyReasonCancellation = async () => {
        setPopupLoading(true)
        setShowHistoryCancellationReasonPopUp(showPopup)
        try {
          await fetchCancellationReasonHistory(user)
        } catch {
          errorToast(
            'Ocorreu um erro ao carregar o histórico de contato desse usuário, tente novamente'
          )
          setShowHistoryCancellationReasonPopUp(false)
        } finally {
          setPopupLoading(false)
        }
      }
      historyReasonCancellation()
    }
  }, [fetchCancellationReasonHistory, showPopup, type, user])

  return (
    <div>
      {showHistoryCancellationReasonPopUp && (
        <SimpleActionPopup
          title={`Histórico de Motivo de Cancelamento - ${user.name}`}
          content={historyCancellationReasonPopupContent(
            selectedUserCancellationReasonPopup.cancellationReasonHistory
          )}
          customSecondActionButton={<CustomAddButton />}
          onClosePopup={() => setShowPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showAddReasonCancellationPopup && (
        <SimpleActionPopup
          title={`${user.name} - Adicionar Motivo Cancelamento`}
          content={addReasonCancellationItemContent()}
          secondActionButtonText={'ADICIONAR'}
          onPressSecondActionButton={submitAddReasonCancellationItem}
          onClosePopup={() => setShowPopup(false)}
          popupLoading={popupLoading}
        />
      )}
      {showDeleteCancellationReasonConfirmationPopup && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={deleteCancellationReasonConfirmationPopupContent()}
          onClosePopup={() => setShowPopup(false)}
          title='Apagar motivo de cancelamento'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='APAGAR'
          onPressSecondActionButton={() => deleteCancellationReasonItem()}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() => setShowPopup(false)}
        />
      )}
      {showEditCancellationReasonItemPopup && (
        <SimpleActionPopup
          title={`${user.name} - Editar Motivo Cancelamento`}
          content={addReasonCancellationItemContent()}
          secondActionButtonText={'EDITAR'}
          onPressSecondActionButton={submitEditCancellationReasonItem}
          onClosePopup={() => {
            setShowEditCancellationReasonItemPopup(false)
            setShowHistoryCancellationReasonPopUp(true)
          }}
          popupLoading={popupLoading}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    token: user.token
  }
}

export default connect(mapStateToProps)(CancellationReasonPopup)
