import { DateTime } from 'luxon'

export const getUserExperience = experienceId => {
  switch (experienceId) {
    case 1:
      return 'Até 6 meses'
    case 2:
      return 'Entre 6 e 12 meses'
    case 3:
      return 'Entre 1 e 2 anos'
    case 4:
      return 'Mais de 2 anos'
    default:
      return 'Não preenchido'
  }
}

export const getSubscriptionTime = date => {
  if (!date) {
    return 'Não assina'
  }

  const timeDifference = DateTime.now().diff(DateTime.fromISO(date), [
    'years',
    'months',
    'days',
    'hours'
  ])

  let timeDifferenceString = ''

  if (timeDifference.values.years) {
    if (timeDifference.values.years === 1) {
      timeDifferenceString += '1 ano'
    } else {
      timeDifferenceString += `${timeDifference.values.years} anos`
    }
  }

  if (timeDifference.values.months) {
    if (timeDifferenceString) {
      timeDifferenceString += ' e '
    }
    if (timeDifference.values.months === 1) {
      timeDifferenceString += '1 mês'
    } else {
      timeDifferenceString += `${timeDifference.values.months} meses`
    }
  }

  if (!timeDifferenceString) {
    timeDifferenceString = 'Menos de 1 mês'
  }

  return timeDifferenceString
}

export const getDaysLeft = (date, daysLeftComplement) => {
  const timeDifference = DateTime.fromISO(date).diff(DateTime.now(), [
    'days',
    'hours'
  ])

  let timeDifferenceString = ''

  const roundedHours = Math.ceil(timeDifference.values.hours)

  // This roundedHours logic is because a difference of one day was giving a result of 23.9 hours. If it falls in this case, we will return 1 day as a result.
  if (timeDifference.values.days === 0 && roundedHours !== 24) {
    if (timeDifference.values.hours <= 1) {
      timeDifferenceString += `1 hora${daysLeftComplement ? ' restante' : ''}`
    } else {
      timeDifferenceString += `${roundedHours} horas${
        daysLeftComplement ? ' restantes' : ''
      }`
    }
  } else if (
    timeDifference.values.days === 1 ||
    (timeDifference.values.days === 0 && roundedHours === 24)
  ) {
    timeDifferenceString += `1 dia${daysLeftComplement ? ' restante' : ''}`
  } else {
    timeDifferenceString += `${timeDifference.values.days} dias${
      daysLeftComplement ? ' restantes' : ''
    }`
  }
  return timeDifferenceString
}

export const getTimeDifference = date => {
  const timeDifference = DateTime.now().diff(DateTime.fromISO(date), [
    'days',
    'hours'
  ])

  let timeDifferenceString = ''

  const roundedHours = Math.ceil(timeDifference.values.hours)

  // This roundedHours logic is because a difference of one day was giving a result of 23.9 hours. If it falls in this case, we will return 1 day as a result.
  if (timeDifference.values.days === 0 && roundedHours !== 24) {
    if (timeDifference.values.hours <= 1) {
      timeDifferenceString += `1 hora`
    } else {
      timeDifferenceString += `${roundedHours} horas`
    }
  } else if (
    timeDifference.values.days === 1 ||
    (timeDifference.values.days === 0 && roundedHours === 24)
  ) {
    timeDifferenceString += `1 dia`
  } else {
    timeDifferenceString += `${timeDifference.values.days} dias`
  }

  return timeDifferenceString
}

export const sortDataByKey = (key, sortOrientation, isDateField) => {
  var sortOrder = 1

  if (sortOrientation === 'DESC') {
    sortOrder = -1
  }

  return function (a, b) {
    const el1 = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const el2 = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    if (isDateField === true) {
      if (el1 === 'INDEFINIDO') {
        return -sortOrder
      } else if (el2 === 'INDEFINIDO') {
        return sortOrder
      }

      const date = DateTime.fromFormat(el1, 'dd/MM/yyyy').ts
      const date2 = DateTime.fromFormat(el2, 'dd/MM/yyyy').ts

      var resultDate = date - date2
      return resultDate * sortOrder
    } else {
      var result = el1 < el2 ? -1 : el1 > el2 ? 1 : 0
      return result * sortOrder
    }
  }
}

export const sortDaysLeft = (key, sortOrientation) => {
  var sortOrder = 1

  if (sortOrientation === 'DESC') {
    sortOrder = -1
  }

  return function (a, b) {
    const el1 = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
    const el2 = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

    //We get a string like "1 day", so we need to split it into number and word to sort.
    const el1Splitted = el1.split(' ')
    const el2Splitted = el2.split(' ')

    //We can receive "day", "days" or "hour", "hours", due to the sort function we need to transform everything into "DAYS" or "HOURS"
    if (el1Splitted[1] === 'HORA') {
      el1Splitted[1] = 'HORAS'
    }
    if (el1Splitted[1] === 'DIA') {
      el1Splitted[1] = 'DIAS'
    }

    if (el2Splitted[1] === 'HORA') {
      el2Splitted[1] = 'HORAS'
    }
    if (el2Splitted[1] === 'DIA') {
      el2Splitted[1] = 'DIAS'
    }

    //The function below checks if the words are the same, if so it sorts by number, if they are different, it sorts by word.
    function sortDaysOrHours (a, b) {
      if (a[1] === b[1]) {
        return a[0] - b[0]
      } else {
        return a[1] > b[1] ? -1 : 1
      }
    }

    var result = sortDaysOrHours(el1Splitted, el2Splitted)
    return result * sortOrder
  }
}

export const getCouponTypeByValue = couponType => {
  switch (couponType) {
    case 10:
      return '1 mês gratis'
    case 20:
      return '2 mêses gratis'
    case 30:
      return '3 mêses gratis'
    default:
      return 'Indefinido'
  }
}

export const getCouponTypeByText = couponType => {
  switch (couponType) {
    case '1 mês gratis':
      return { value: 10, label: '1 mês gratis' }
    case '3 mêses gratis':
      return { value: 30, label: '3 mêses gratis' }
    default:
      return 'Indefinido'
  }
}
