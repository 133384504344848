export const areas = [
  { value: 'sales', label: 'Vendas' },
  { value: 'superAdmin', label: 'Super Admin' },
  { value: 'developer', label: 'Desenvolvedor' },
  { value: 'customerSuccess', label: 'Sucesso do Cliente' }
]

export const getAreaValue = label => {
  areas.find(area => {
    return area.label === label
  })
}
