import axios from 'axios'

export const uri =
  process.env.REACT_APP_ENV === 'development'
    ? 'https://mosty-gateway-dev-7txfdm6rba-uw.a.run.app/api/v2'
    : 'https://mosty-gateway-7txfdm6rba-uw.a.run.app/api/v2'

export const api = (options = { local: false, localPort: 4000 }) => {
  return axios.create({
    baseURL: options.local
      ? `http://localhost:${options.localPort}/api/v2`
      : uri
  })
}

export const auth = {
  waitingConfirmation: 5,
  user: 10,
  waitingConsultant: 25,
  consultant: 50,
  admin: 100
}
