import { createElement } from 'react'

export const DatePicker = ({
  value,
  onChange,
  max,
  min,
  style,
  dataTest = 'event-date-input',
  className
}) => {
  return createElement('input', {
    type: 'date',
    value: value,
    onInput: onChange,
    onChange,
    max,
    min,
    style,
    'data-test': dataTest,
    className
  })
}

export const TimePicker = ({
  value,
  onChange,
  style,
  dataTest = 'event-time-input',
  className
}) => {
  return createElement('input', {
    type: 'time',
    value: value,
    onInput: onChange,
    onChange,
    style,
    'data-test': dataTest,
    className
  })
}
