import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import SendWhatsappMessage from '../../services/SendWhatsappMessage'
import { sortDataByKey } from '../../helpers/SalesHelpers'
import { WhatsApp } from '@material-ui/icons'
import '../../styles/containers/Sales/indications.css'
import {
  getIndicatedConsultants,
  getConsultantsWhoIndicated
} from '../../services/utils'
import Loading from '../../components/Loading'
import { getPlanName } from '../../helpers/ConsultantDetailsHelpers'
import SelectConsultantPopup from '../../components/SelectConsultantPopup'

const userTableHeadersConsultantsWhoIndicated = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Nº de Indicações',
  'Nº de Indicações que assinaram'
]

const userTableHeadersIndicatedConsultants = [
  'ID',
  'Nome',
  'E-mail',
  'Telefone',
  'Consultora que indicou',
  'Plano que assinou'
]

const Indications = props => {
  let navigate = useNavigate()

  // Table consultants who indicated
  let [dataConsultantsWhoIndicated, setDataConsultantsWhoIndicated] = useState(
    []
  )
  let [filteredUserData, setFilteredUserData] = useState([])
  let [
    consultantsWhoIndicatedDataSearchResult,
    setConsultantsWhoIndicatedDataSearchResult
  ] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [selectedSortHeader, setSelectedSortHeader] = useState(5)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  let [showSelectConsultantPopup, setShowSelectConsultantPopup] = useState(
    false
  )

  // Table indicated consultants
  let [dataIndicatedConsultants, setDataIndicatedConsultants] = useState([])
  let [
    filteredDataIndicatedConsultants,
    setFilteredDataIndicatedConsultants
  ] = useState([])
  let [
    consultantsByIndicationDataSearchResult,
    setIndicatedConsultantsDataSearchResult
  ] = useState([])
  let [
    paginatedDataIndicatedConsultants,
    setPaginatedDataIndicatedConsultants
  ] = useState([])
  let [selectedSortHeaderIndicated, setSelectedSortHeaderIndicated] = useState(
    0
  )
  let [sortOrientationIndicated, setSortOrientationIndicated] = useState('DESC')

  let [isLoading, setIsLoading] = useState(true)

  const fetchConsultantsWhoIndicated = useCallback(async () => {
    try {
      await getConsultantsWhoIndicated(props.token).then(
        consultantsIndicated => {
          const userData = consultantsIndicated.map(consultant => ({
            id: consultant.id,
            name: consultant.name,
            email: consultant.email,
            phoneNumber: consultant.phone,
            nConsultantsIndicated: consultant.nConsultantsIndicated,
            nConsultantsIndicatedSigned:
              consultant.nConsultantsIndicatedSigned || 0
          }))
          setDataConsultantsWhoIndicated(userData)
          setFilteredUserData(userData)
          setConsultantsWhoIndicatedDataSearchResult(userData)
          setPaginatedUserData(userData)
        }
      )
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [props.token])

  const fetchIndicatedConsultants = useCallback(async () => {
    try {
      await getIndicatedConsultants(props.token).then(consultantsIndicated => {
        const userData = consultantsIndicated.map(consultant => ({
          id: consultant.id,
          name: consultant.name,
          email: consultant.email,
          phoneNumber: consultant.phone,
          consultantName: consultant.consultantWhoRecommendedName,
          consultantId: consultant.consultantWhoRecommendedId,
          planIdentifier:
            getPlanName(consultant.planIdentifier) !== 'Teste'
              ? getPlanName(consultant.planIdentifier)
              : 'Não Assinou'
        }))
        setDataIndicatedConsultants(userData)
        setFilteredDataIndicatedConsultants(userData)
        setIndicatedConsultantsDataSearchResult(userData)
        setPaginatedDataIndicatedConsultants(userData)
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [props.token])

  let [
    indicatedConsultantsWhoDidSign,
    setIndicatedConsultantsWhoDidSign
  ] = useState(true)
  let [
    indicatedConsultantsWhoDidNotSign,
    setIndicatedConsultantsWhoDidNotSign
  ] = useState(true)
  let [indicatedDidNotSign, setIndicatedDidNotSign] = useState(true)
  let [indicatedSigned, setIndicatedSigned] = useState(true)

  const filterList = [
    {
      label: 'Indicaram consultoras que não assinaram',
      filterState: indicatedConsultantsWhoDidNotSign,
      setFilter: value => setIndicatedConsultantsWhoDidNotSign(value)
    },
    {
      label: 'Indicaram consultoras que assinaram',
      filterState: indicatedConsultantsWhoDidSign,
      setFilter: value => setIndicatedConsultantsWhoDidSign(value)
    },
    {
      label: 'Indicadas que não assinaram',
      filterState: indicatedDidNotSign,
      setFilter: value => setIndicatedDidNotSign(value)
    },
    {
      label: 'Indicadas que assinaram',
      filterState: indicatedSigned,
      setFilter: value => setIndicatedSigned(value)
    }
  ]

  const consultantsWhoIndicatedTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Consultoras em Teste' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'nConsultantsIndicated',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'nConsultantsIndicatedSigned',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  const consultantsByIndicationTableKeys = useMemo(
    () => [
      {
        key: 'id',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user, pageOrigin: 'Indicações' }
          })
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'phoneNumber',
        className: 'break-all min-w-[120px]',
        passObjectToAction: false,
        action: phoneNumber => {
          if (phoneNumber) {
            SendWhatsappMessage(phoneNumber, '')
          } else {
            return null
          }
        },
        icon: <WhatsApp className='whatsapp-table-data-icon' />
      },
      {
        key: 'consultantName',
        className: '',
        passObjectToAction: true,
        action: user => {
          navigate('/consultoras/detalhes', {
            state: { user: { id: user.consultantId }, pageOrigin: 'Indicações' }
          })
        },
        icon: ''
      },
      {
        key: 'planIdentifier',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const filteredUserData = consultantsWhoIndicatedDataSearchResult.filter(
      item => {
        // This check was used to separate referring consultants from referring consultants, because the name and email search joins the two lists.
        if (!item.planIdentifier) {
          if (indicatedConsultantsWhoDidNotSign) {
            if (item.nConsultantsIndicatedSigned === 0) {
              return true
            }
          }
          if (indicatedConsultantsWhoDidSign) {
            if (item.nConsultantsIndicatedSigned >= 1) {
              return true
            }
          }
        }
        return false
      }
    )

    const filteredIndicatedData = consultantsByIndicationDataSearchResult.filter(
      item => {
        // This check was used to separate referring consultants from referring consultants, because the name and email search joins the two lists.
        if (item.planIdentifier) {
          if (indicatedSigned) {
            if (item.planIdentifier !== 'Não Assinou') {
              return true
            }
          }

          if (indicatedDidNotSign) {
            if (item.planIdentifier === 'Não Assinou') {
              return true
            }
          }
        }
        return false
      }
    )

    filteredUserData.sort(
      sortDataByKey(
        consultantsWhoIndicatedTableKeys[selectedSortHeader].key,
        sortOrientation
      )
    )

    filteredIndicatedData.sort(
      sortDataByKey(
        consultantsByIndicationTableKeys[selectedSortHeaderIndicated].key,
        sortOrientationIndicated
      )
    )

    setFilteredDataIndicatedConsultants(filteredIndicatedData)
    setFilteredUserData(filteredUserData)
  }, [
    consultantsWhoIndicatedDataSearchResult,
    selectedSortHeader,
    sortOrientation,
    consultantsWhoIndicatedTableKeys,
    consultantsByIndicationDataSearchResult,
    consultantsByIndicationTableKeys,
    selectedSortHeaderIndicated,
    sortOrientationIndicated,
    indicatedSigned,
    indicatedDidNotSign,
    indicatedConsultantsWhoDidNotSign,
    indicatedConsultantsWhoDidSign
  ])

  useEffect(() => {
    setIsLoading(true)
    fetchConsultantsWhoIndicated()
    fetchIndicatedConsultants()
  }, [fetchConsultantsWhoIndicated, fetchIndicatedConsultants])

  const onClickSortHeader = (selectedHeader, table) => {
    let orientation = 'ASC'

    if (table === 'constultantWhoIndicated') {
      if (selectedSortHeader === selectedHeader) {
        if (sortOrientation === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }

      setSelectedSortHeader(selectedHeader)
      setSortOrientation(orientation)
    }
    if (table === 'indicated') {
      if (selectedSortHeaderIndicated === selectedHeader) {
        if (sortOrientationIndicated === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }

      setSelectedSortHeaderIndicated(selectedHeader)
      setSortOrientationIndicated(orientation)
    }
  }

  const fetchDataTables = () => {
    setIsLoading(true)
    fetchConsultantsWhoIndicated()
    fetchIndicatedConsultants()
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Indicações de Consultoras</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={[
            ...dataIndicatedConsultants,
            ...dataConsultantsWhoIndicated
          ]}
          setFilteredItemsList={list => {
            setConsultantsWhoIndicatedDataSearchResult(list)
            setIndicatedConsultantsDataSearchResult(list)
          }}
          filterList={filterList}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras que Indicaram</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeadersConsultantsWhoIndicated}
            tableKeys={consultantsWhoIndicatedTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
            onPressAddButton={() => setShowSelectConsultantPopup(true)}
            tableType={'constultantWhoIndicated'}
          />
        )}
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Consultoras indicadas</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredDataIndicatedConsultants}
            tableHeaders={userTableHeadersIndicatedConsultants}
            tableKeys={consultantsByIndicationTableKeys}
            paginatedData={paginatedDataIndicatedConsultants}
            setPaginatedData={setPaginatedDataIndicatedConsultants}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeaderIndicated}
            sortOrientation={sortOrientationIndicated}
            tableType={'indicated'}
          />
        )}
      </div>
      {showSelectConsultantPopup && (
        <SelectConsultantPopup
          type={'indications'}
          showPopup={showSelectConsultantPopup}
          setShowPopup={setShowSelectConsultantPopup}
          refetch={fetchDataTables}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Indications)
