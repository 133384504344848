import React from 'react'
import { SearchOutlined } from '@material-ui/icons'

import '../styles/components/SearchBar.css'

const SearchBar = ({
  placeholder,
  onChange,
  inputClassName,
  itemList,
  setFilteredItemsList,
  filterFunction
}) => {
  const filterItems = value => {
    setFilteredItemsList(
      itemList.filter(item => {
        return (
          item.name?.toLowerCase().includes(value.toLowerCase()) ||
          item.email?.toLowerCase().includes(value.toLowerCase()) ||
          item.consultantName?.toLowerCase().includes(value.toLowerCase()) ||
          item.code?.toLowerCase().includes(value.toLowerCase())
        )
      })
    )
  }

  return (
    <div className='search-bar-container'>
      <SearchOutlined className='search-bar-category-icon' />
      <input
        className={`search-bar-input ${inputClassName || ''}`}
        onChange={
          onChange
            ? onChange
            : e => {
                filterFunction
                  ? filterFunction(e.target.value)
                  : filterItems(e.target.value)
              }
        }
        type='text'
        placeholder={placeholder || 'Buscar por nome ou e-mail'}
      />
    </div>
  )
}

export default SearchBar
