import React from 'react'
import Pagination from '../components/Pagination'
import {
  AddRounded,
  OpenWithOutlined,
  ArrowDropDown,
  ArrowDropUp
} from '@material-ui/icons'

import '../styles/components/BasicTable.css'

const BasicTable = ({
  filteredData,
  tableHeaders,
  tableKeys,
  paginatedData,
  setPaginatedData,
  onPressAddButton,
  openAction,
  itemsPerPage,
  onClickSortHeader,
  selectedSortHeader,
  sortOrientation,
  rowStyle,
  onRowClick,
  tableType
}) => {
  return (
    <div className='overflow-auto rounded-[10px] px-2 py-2'>
      <div className='basic-table-container overflow-auto'>
        {!filteredData || filteredData.length === 0 ? (
          <p className='text-center'>
            Não foram encontrados resultados para sua busca atual.
          </p>
        ) : (
          <div>
            <table className='bg-white basic-table'>
              <thead>
                <tr>
                  {tableHeaders.map((header, key) => {
                    return (
                      <th
                        key={key}
                        className={`basic-table-header text-left px-4 py-2 ${key ===
                          0 && 'basic-table-header-radius-left'} ${key ===
                          tableHeaders.length - 1 &&
                          'basic-table-header-radius-right'} ${key ===
                          tableHeaders.length - 1 &&
                          openAction &&
                          'flex items-center justify-between'}
                          ${onClickSortHeader && 'cursor-pointer'}
                          `}
                        onClick={() => {
                          if (onClickSortHeader) {
                            onClickSortHeader(key, tableType)
                          }
                        }}
                      >
                        {header}
                        {key === selectedSortHeader &&
                          (sortOrientation === 'ASC' ? (
                            <ArrowDropDown className='basic-table-sort-icon' />
                          ) : (
                            <ArrowDropUp className='basic-table-sort-icon' />
                          ))}
                        {key === tableHeaders.length - 1 && openAction && (
                          <button
                            className='basic-table-expand-button'
                            onClick={openAction}
                          >
                            <OpenWithOutlined className='basic-table-expand-icon' />
                          </button>
                        )}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((user, userKey) => {
                  return (
                    <tr
                      onClick={() => onRowClick && onRowClick(user)}
                      className='basic-table-border'
                      style={rowStyle ? rowStyle(user) : {}}
                      key={userKey}
                    >
                      {tableKeys.map(tableKey => {
                        const tableUserDataByKey = user[tableKey.key]
                        return (
                          <td
                            key={`${tableKey.key}-${userKey}`}
                            className={`basic-table-border px-4 py-2 ${
                              tableKey.className
                            } ${tableKey.action &&
                              tableUserDataByKey !== null &&
                              tableUserDataByKey !== '' &&
                              'basic-table-clickable-data'}`}
                            onClick={() => {
                              if (tableKey.action) {
                                // if it is an actionButton, or whe should pass object to action, then we will pass the user data to the function
                                if (
                                  tableKey.key === 'actionButton' ||
                                  tableKey.passObjectToAction === true
                                ) {
                                  tableKey.action(user)
                                } else {
                                  tableKey.action(tableUserDataByKey)
                                }
                              }
                            }}
                          >
                            <div className='basic-table-data-content'>
                              {/* if it is an actionButton, then there is no user key for it */}
                              {tableKey.positionIcon === 'left' &&
                                tableUserDataByKey !== null &&
                                tableUserDataByKey !== '' &&
                                tableKey.icon}

                              {tableKey.key !== 'actionButton' &&
                                tableUserDataByKey}

                              {tableKey.positionIcon !== 'left' &&
                                tableKey.positionIcon !== 'right' &&
                                tableUserDataByKey !== null &&
                                tableUserDataByKey !== '' &&
                                tableKey.icon}

                              {/* TODO: Find a way to generalize this "Adicionar Motivo" */}
                              {tableUserDataByKey === 'Adicionar Motivo' &&
                                tableKey.icon}
                            </div>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className='basic-table-pagination'>
              <Pagination
                itemList={filteredData}
                setPaginatedItemList={setPaginatedData}
                itemsPerPage={itemsPerPage}
              />
            </div>
            {/* TODO: position button with a negative margin-right */}
            {onPressAddButton && (
              <div className='basic-table-add-button-container'>
                <button
                  className='basic-table-add-button'
                  onClick={onPressAddButton}
                >
                  <AddRounded className='basic-table-add-icon' />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BasicTable
