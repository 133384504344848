import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import { sortDataByKey } from '../../helpers/SalesHelpers'
import '../../styles/containers/Sales/CRM.css'
import {
  getBlockedUsersFromReceivingEmail,
  releaseUserToReceiveEmail
} from '../../services/utils'
import Loading from '../../components/Loading'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import SimpleActionPopup from '../../components/SimpleActionPopup'
import { successToast } from '../../helpers/ToastHelper'

const userTableHeaders = ['Nome', 'E-mail', 'Consultora', 'Desbloquear']
const DeletedClientsHistory = props => {
  let navigate = useNavigate()

  let [userData, setUserData] = useState([])
  let [filteredUserData, setFilteredUserData] = useState([])
  let [userDataSearchResult, setUserDataSearchResult] = useState([])
  let [paginatedUserData, setPaginatedUserData] = useState([])
  let [isLoading, setIsLoading] = useState(true)

  let [selectedSortHeader, setSelectedSortHeader] = useState(3)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  let [
    unlockUserFromReceivingEmailPopupShowing,
    setUnlockUserFromReceivingEmailPopupShowing
  ] = useState(false)
  let [email, setEmail] = useState('')
  let [name, setName] = useState('')
  let [popupLoading, setPopupLoading] = useState(false)

  const fetchBlockedUsersFromReceivingEmail = async userProps => {
    setIsLoading(true)
    getBlockedUsersFromReceivingEmail(userProps.token)
      .then(clients => {
        const userData = clients.map(item => {
          const user = {
            id: item.id,
            name: item.name || 'Indefinido',
            email: item.email || 'Indefinido',
            consultantName: item.consultantName,
            consultantId: item.consultantId,
            unlock: 'Clique para desbloquear'
          }
          return user
        })

        setUserData(userData)
        setFilteredUserData(userData)
        setPaginatedUserData(userData)
        setUserDataSearchResult(userData)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => setIsLoading(false))
  }

  const userTableKeys = useMemo(
    () => [
      {
        key: 'name',
        className: 'min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          if (user.consultantId && user.consultantId !== 1) {
            navigate('/clientes/detalhes', {
              state: { user }
            })
          } else {
            navigate('/consultoras/detalhes', {
              state: { user, pageOrigin: 'Emails Bloqueados' }
            })
          }
        },
        icon: ''
      },
      {
        key: 'email',
        className: 'break-all min-w-[120px]',
        action: '',
        icon: ''
      },
      {
        key: 'consultantName',
        className: 'break-all min-w-[120px]',
        passObjectToAction: true,
        action: user => {
          if (user.consultantId) {
            navigate('/consultoras/detalhes', {
              state: {
                user: { id: user.consultantId },
                pageOrigin: 'Emails Bloqueados'
              }
            })
          }
        },
        icon: ''
      },
      {
        key: 'unlock',
        className: '',
        positionIcon: 'left',
        passObjectToAction: true,
        action: user => {
          setEmail(user.email)
          setName(user.name)
          setUnlockUserFromReceivingEmailPopupShowing(true)
        },
        icon: (
          <LockOpenIcon
            style={{
              color: 'var(--identity-7)',
              marginRight: '10px',
              width: '20px'
            }}
          />
        )
      }
    ],
    [navigate]
  )

  useEffect(() => {
    const filteredUserData = [...userDataSearchResult]

    filteredUserData.sort(
      sortDataByKey(userTableKeys[selectedSortHeader].key, sortOrientation)
    )

    setFilteredUserData(filteredUserData)
  }, [
    userDataSearchResult,
    selectedSortHeader,
    sortOrientation,
    userTableKeys,
    userData
  ])

  useEffect(() => {
    fetchBlockedUsersFromReceivingEmail(props)
  }, [props])

  const onClickSortHeader = selectedHeader => {
    let orientation = 'ASC'

    if (selectedSortHeader === selectedHeader) {
      if (sortOrientation === 'ASC') {
        // If the header is clicked twice, we should change orientation to DESC
        orientation = 'DESC'
      } else {
        // If the header is clicked three times, we will return to the default ordering
        selectedHeader = 0
      }
    }

    setSelectedSortHeader(selectedHeader)
    setSortOrientation(orientation)
  }

  const unlockUserFromReceivingEmailPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja retirar o{' '}
          <strong>bloqueio</strong> de recebimento de e-mails deste usuario?
          <br />
          <br />
          Confira se o e-mail do usuario está correto antes de prosseguir!
          <br />
          <strong>{email}</strong>
        </p>
      </div>
    )
  }

  const handleUnlockUserFromReceivingEmail = async () => {
    setPopupLoading(true)
    try {
      await releaseUserToReceiveEmail(props.token, email)
      successToast('E-mail desbloqueado com sucesso!')
      fetchBlockedUsersFromReceivingEmail(props)
    } catch (err) {
      console.error(err)
    } finally {
      setUnlockUserFromReceivingEmailPopupShowing(false)
      setPopupLoading(false)
    }
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Usuários Bloqueados de Receber e-mails</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={userData}
          setFilteredItemsList={list => setUserDataSearchResult(list)}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Usuários</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredUserData}
            tableHeaders={userTableHeaders}
            tableKeys={userTableKeys}
            paginatedData={paginatedUserData}
            setPaginatedData={setPaginatedUserData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
          />
        )}
      </div>
      {unlockUserFromReceivingEmailPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={unlockUserFromReceivingEmailPopupContent()}
          onClosePopup={() =>
            setUnlockUserFromReceivingEmailPopupShowing(false)
          }
          title={`Desbloquear recebimento de e-mail: ${name} `}
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          secondActionButtonText='DESBLOQUEAR'
          onPressSecondActionButton={handleUnlockUserFromReceivingEmail}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setUnlockUserFromReceivingEmailPopupShowing(false)
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(DeletedClientsHistory)
