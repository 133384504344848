import React, { useState } from 'react'
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons'
import NumberFormat from 'react-number-format'
import Select from 'react-select'

import '../styles/components/FormInput.css'

const FormInput = ({
  type,
  placeholder,
  label,
  onChange,
  value,
  className,
  inputClassName,
  mask,
  selectOptions,
  selectedOption,
  defaultValueOption,
  rows,
  maxLength,
  readOnly,
  customStyle
}) => {
  const [isVisible, setIsVisible] = useState(type !== 'password')
  const toggleVisible = () => setIsVisible(!isVisible)

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '34px !important',
      minHeight: '34px !important',
      borderRadius: 20,
      border: state.isFocused
        ? '1px solid var(--identity-6) !important'
        : '1px solid var(--identity-4) !important',
      fontSize: 14,
      boxShadow: '-1px 2px 4px rgba(0, 0, 0, 0.25)'
    }),
    valueContainer: provided => ({
      ...provided,
      height: '32px !important',
      padding: '0 6px'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: '32px !important'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: 'var(--identity-4)'
    })
  }

  return (
    <div className={`form-input-container ${className || ''}`}>
      <div className='form-label'>
        <p className='big-paragraph'>{label}</p>
      </div>
      {mask ? (
        <NumberFormat
          className={`form-input ${inputClassName || ''}`}
          onChange={onChange}
          value={value}
          type={type || 'text'}
          placeholder={placeholder}
          format={mask.format}
        />
      ) : selectOptions ? (
        <Select
          className='react-select__control'
          classNamePrefix='react-select'
          styles={customStyle ? customStyle : customSelectStyles}
          defaultValue={
            defaultValueOption ? defaultValueOption : selectedOption
          }
          onChange={onChange}
          options={selectOptions}
          placeholder={placeholder}
          noOptionsMessage={() => 'Sem opções'}
        />
      ) : rows ? (
        <textarea
          className={`form-input-text-area ${inputClassName || ''}`}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          rows={rows}
          maxLength={maxLength || 10}
          readOnly={readOnly}
        />
      ) : (
        <input
          className={`form-input ${inputClassName || ''}`}
          onChange={onChange}
          value={value}
          type={
            type === 'password'
              ? isVisible
                ? 'text'
                : 'password'
              : type || 'text'
          }
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}

      {type === 'password' &&
        (isVisible ? (
          <VisibilityOutlined
            style={{ fontSize: 20, color: 'var(--identity-4)' }}
            className='form-input-eye'
            onClick={toggleVisible}
          />
        ) : (
          <VisibilityOffOutlined
            style={{ fontSize: 20, color: 'var(--identity-4)' }}
            className='form-input-eye'
            onClick={toggleVisible}
          />
        ))}
    </div>
  )
}

export default FormInput
