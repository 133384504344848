import React from 'react'
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { connect } from 'react-redux'

import SidebarMenu from '../components/SidebarMenu'
import Login from '../containers/Login'
import MyData from '../containers/MyData'
import ManageUsers from '../containers/ManageUsers'
import CRM from '../containers/Sales/CRM'
import Funnel from '../containers/Sales/Funnel'
import TestingConsultants from '../containers/Sales/TestingConsultants'
import ConsultantDetails from '../containers/ConsultantDetails'
import SalesDashboard from '../containers/Sales/SalesDashboard'
import Indications from '../containers/Sales/Indications'
import Coupons from '../containers/Sales/Coupons'
import history from '../services/history'
import SuccessClientsConsultants from '../containers/ClientSuccess/Consultants'
import SuccessClientsSubscribers from '../containers/ClientSuccess/Subscribers'
import SuccessClientsSubscriptions from '../containers/ClientSuccess/Subscriptions'
import SuccessClientsSubscribersSummary from '../containers/ClientSuccess/SubscribersSummary'
import SuccessClientsClients from '../containers/ClientSuccess/Clients'
import SuccessClientsDashboard from '../containers/ClientSuccess/ClientSucessDashboard'
import SuccessClientsCancellations from '../containers/ClientSuccess/Cancellations'
import EditClient from '../containers/EditClient'
import DeletedClientHistory from '../containers/Historic/DeletedClientsHistory'
import ConsultantsChangeHistory from '../containers/Historic/ConsultantsChangeHistory'
import BlockedEmails from '../containers/Historic/BlockedEmails'

const RenderRoutes = ({ props }) => {
  const storeState = props

  const requiresAuthentication = (Element, areaRestriction) => {
    if (!storeState.token) {
      return <Navigate to='/login' />
    }
    if (areaRestriction) {
      if (
        !storeState.adminArea ||
        areaRestriction !== storeState.adminArea.area
      ) {
        return <Navigate to='/' />
      }
    }
    return <Element />
  }

  const noAuthentication = Element => {
    if (!storeState.token) {
      return <Element />
    }
    return <Navigate to='/' />
  }

  return (
    <Routes>
      <Route path='/login' element={noAuthentication(Login)} />
      <Route path='/vendas/crm' element={requiresAuthentication(CRM)} />
      <Route path='/vendas/funil' element={requiresAuthentication(Funnel)} />
      <Route
        path='/vendas/consultoras-em-teste'
        element={requiresAuthentication(TestingConsultants)}
      />
      <Route
        path='/vendas/indicacoes'
        element={requiresAuthentication(Indications)}
      />
      <Route path='/vendas/cupons' element={requiresAuthentication(Coupons)} />
      <Route path='/vendas' element={requiresAuthentication(SalesDashboard)} />
      <Route
        path='/configuracoes'
        element={requiresAuthentication(ManageUsers, 'superAdmin')}
      />
      <Route path='/meus-dados' element={requiresAuthentication(MyData)} />
      <Route
        path='/consultoras/detalhes'
        element={requiresAuthentication(ConsultantDetails)}
      />
      <Route
        path='/clientes/detalhes'
        element={requiresAuthentication(EditClient)}
      />
      <Route
        path='/sucesso-do-cliente/consultoras'
        element={requiresAuthentication(SuccessClientsConsultants)}
      />
      <Route
        path='/sucesso-do-cliente/acompanhamento-assinantes-tabelas'
        element={requiresAuthentication(SuccessClientsSubscribers)}
      />
      <Route
        path='/sucesso-do-cliente/acompanhamento-assinantes-resumo'
        element={requiresAuthentication(SuccessClientsSubscribersSummary)}
      />
      <Route
        path='/sucesso-do-cliente'
        element={requiresAuthentication(SuccessClientsDashboard)}
      />
      <Route
        path='/sucesso-do-cliente/assinaturas'
        element={requiresAuthentication(SuccessClientsSubscriptions)}
      />
      <Route
        path='/sucesso-do-cliente/clientes'
        element={requiresAuthentication(SuccessClientsClients)}
      />
      <Route
        path='/sucesso-do-cliente/cancelamentos'
        element={requiresAuthentication(SuccessClientsCancellations)}
      />
      <Route
        path='/historicos/historico-clientes-apagadas'
        element={requiresAuthentication(DeletedClientHistory)}
      />
      <Route
        path='/historicos/historico-mudanca-consultora'
        element={requiresAuthentication(ConsultantsChangeHistory)}
      />
      <Route
        path='/historicos/emails-bloqueados'
        element={requiresAuthentication(BlockedEmails)}
      />
      {/* TODO: navigate to component that will choose where to redirect, based on user role */}
      {/* customerSuccess */}
      <Route
        path='/'
        element={
          storeState.adminArea?.area === 'customerSuccess'
            ? requiresAuthentication(SuccessClientsDashboard)
            : requiresAuthentication(SalesDashboard)
        }
      />

      {/* TODO: Navigate to another path when no route is found */}
      <Route path='*' element={<Navigate to='/vendas' />} />
    </Routes>
  )
}

const Router = props => {
  return (
    <HistoryRouter history={history}>
      <SidebarMenu />
      <RenderRoutes props={props} />
    </HistoryRouter>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Router)
