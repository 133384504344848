import { UAParser } from 'ua-parser-js'

const { device } = new UAParser().getResult()
const isMobile = ['mobile', 'tablet'].includes(device.type)

const SendWhatsappMessage = (phone, message) => {
  const text = encodeURIComponent(message)

  const api = isMobile ? 'https://api.whatsapp.com' : 'https://web.whatsapp.com'
  const url = `${api}/send?phone=55${phone}&text=${text}`

  window.open(url, '_blank')
}

export default SendWhatsappMessage
