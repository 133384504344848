export const setUser = user => {
  return {
    type: 'SET_USER',
    user
  }
}

export const setToken = token => {
  return {
    type: 'SET_TOKEN',
    token: token
  }
}

export const setAdminArea = adminArea => {
  return {
    type: 'SET_ADMIN_AREA',
    adminArea
  }
}

export const logoutUser = () => {
  return {
    type: 'LOGOUT'
  }
}
