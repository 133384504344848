import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'

import FilterCard from '../../components/FilterCard'
import BasicTable from '../../components/BasicTable'
import {
  getCouponTypeByText,
  getCouponTypeByValue,
  sortDataByKey
} from '../../helpers/SalesHelpers'
import '../../styles/containers/Sales/indications.css'
import {
  createNewCoupon,
  deleteCoupon,
  editCoupon,
  getCoupons
} from '../../services/utils'
import Loading from '../../components/Loading'
import { DateTime } from 'luxon'
import SimpleActionPopup from '../../components/SimpleActionPopup'
import { errorToast, successToast } from '../../helpers/ToastHelper'
import { DatePicker } from '../../components/DateTimePickers'
import FormInput from '../../components/FormInput'
import { AddRounded, DeleteOutline } from '@material-ui/icons'
import EditIcon from '../../assets/images/edit.svg'

const userTableHeadersCoupons = [
  'Código',
  'Tipo',
  'Utilizado por',
  'Limite de uso',
  'Criado em',
  'Expira em',
  'Status',
  '',
  ''
]

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '',
    height: '34px !important',
    minHeight: '34px !important',
    borderRadius: 20,
    border: state.isFocused
      ? '1px solid var(--identity-6) !important'
      : '1px solid var(--identity-4) !important',
    fontSize: 14,
    boxShadow: '-1px 2px 4px rgba(0, 0, 0, 0.25)'
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px !important',
    padding: '0 6px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px !important'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'var(--identity-4)'
  })
}

const cupomTypesOptions = [
  { value: 0, label: 'Selecione uma opção' },
  { value: 10, label: '1 mês gratis' },
  { value: 20, label: '2 mêses gratis' },
  { value: 30, label: '3 mêses gratis' }
]

const cupomStatusOptions = [
  { value: 2, label: 'Selecione uma opção' },
  { value: 1, label: 'Ativado' },
  { value: 0, label: 'Desativado' }
]

const Coupons = props => {
  // Table Coupons
  let [dataCoupons, setDataCoupons] = useState([])
  let [filteredCouponData, setFilteredCouponData] = useState([])
  let [couponsDataSearchResult, setCouponsDataSearchResult] = useState([])
  let [paginatedCouponData, setPaginatedCouponData] = useState([])
  let [selectedSortHeader, setSelectedSortHeader] = useState(4)
  let [sortOrientation, setSortOrientation] = useState('DESC')

  let [showAddOrEditCouponPopup, setShowAddOrEditCouponPopup] = useState(false)

  let [popupLoading, setPopupLoading] = useState(false)
  let [isLoading, setIsLoading] = useState(true)

  let [showDeleteCouponPopup, setShowDeleteCouponPopup] = useState(false)

  // Variables to create new coupon or edit coupon
  let [couponItemExpirationDate, setCouponItemExpirationDate] = useState('')
  let [couponItemCode, setCouponItemCode] = useState('')
  let [couponItemType, setCouponItemType] = useState('')
  let [couponItemLimitNumber, setCouponItemLimitNumber] = useState('')
  let [couponItemStatus, setCouponItemStatus] = useState({})
  let [isUpdateCoupon, setIsUpdateCoupon] = useState(false)

  const fetchCoupons = useCallback(async () => {
    try {
      await getCoupons(props.token).then(coupons => {
        const couponData = coupons.map(coupon => ({
          code: coupon.code,
          type: getCouponTypeByValue(coupon.value),
          usageCount: coupon.usageCount,
          userUsageLimit: !coupon.userUsageLimit
            ? 'Sem limite'
            : coupon.userUsageLimit > 9999
            ? 'Sem limite'
            : coupon.userUsageLimit,
          createdAt: DateTime.fromISO(coupon.createdAt).toFormat('dd/MM/yyyy'),
          status: coupon.active === 1 ? 'Ativo' : 'Desativado',
          expirationAt: !coupon.expiration
            ? 'Não expira'
            : DateTime.fromISO(coupon.expiration)
                .plus({ days: 1 })
                .toFormat('dd/MM/yyyy') === '31/12/2199'
            ? 'Não expira'
            : DateTime.fromISO(coupon.expiration)
                .plus({ days: 1 })
                .toFormat('dd/MM/yyyy')
        }))

        setDataCoupons(couponData)
        setFilteredCouponData(couponData)
        setCouponsDataSearchResult(couponData)
        setPaginatedCouponData(couponData)
      })
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [props.token])

  let [disabledCoupons, setDisabledCoupons] = useState(true)
  let [couponsActivated, setCouponsActivated] = useState(true)

  const filterList = [
    {
      label: 'Ativos',
      filterState: couponsActivated,
      setFilter: value => setCouponsActivated(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#D1F1BF' }}
        ></div>
      )
    },
    {
      label: 'Desativados',
      filterState: disabledCoupons,
      setFilter: value => setDisabledCoupons(value),
      icon: (
        <div
          className='subscription-filter-icon'
          style={{ backgroundColor: '#FFC3C3' }}
        ></div>
      )
    }
  ]

  const couponsTableKeys = useMemo(
    () => [
      {
        key: 'code',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'type',
        className: '',
        passObjectToAction: true,
        action: '',
        icon: ''
      },
      {
        key: 'usageCount',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'userUsageLimit',
        className: '',
        passObjectToAction: false,
        action: ''
      },
      {
        key: 'createdAt',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'expirationAt',
        className: '',
        passObjectToAction: false,
        action: '',
        icon: ''
      },
      {
        key: 'status',
        className: '',
        passObjectToAction: true,
        action: ''
      },
      {
        key: 'editActionButton',
        className: '',
        passObjectToAction: true,
        action: coupon => setUpdatedCouponState(coupon),
        icon: (
          <button className='subscription-edit-button'>
            <img src={EditIcon} alt='edit' />
          </button>
        )
      },
      {
        key: 'deleteActionButton',
        className: '',
        passObjectToAction: true,
        action: coupon => {
          setCouponItemCode(coupon.code)
          setShowDeleteCouponPopup(true)
        },
        icon: <DeleteOutline className='delete-card-icon' />
      }
    ],
    []
  )

  useEffect(() => {
    const filteredUserData = couponsDataSearchResult.filter(item => {
      if (couponsActivated) {
        if (item.status === 'Ativo') {
          return true
        }
      }
      if (disabledCoupons) {
        if (item.status === 'Desativado') {
          return true
        }
      }

      return false
    })

    const isDateField = couponsTableKeys[selectedSortHeader].key === 'createdAt' || couponsTableKeys[selectedSortHeader].key === 'expirationAt'
    

    filteredUserData.sort(
      sortDataByKey(
        couponsTableKeys[selectedSortHeader].key,
        sortOrientation,
        isDateField
      )
    )

    setFilteredCouponData(filteredUserData)
  }, [
    couponsActivated,
    couponsDataSearchResult,
    couponsTableKeys,
    disabledCoupons,
    selectedSortHeader,
    sortOrientation
  ])

  useEffect(() => {
    setIsLoading(true)
    fetchCoupons()
  }, [fetchCoupons])

  const rowStyle = coupon => {
    if (coupon.status === 'Ativo') {
      return { backgroundColor: '#D1F1BF' }
    }
    if (coupon.status === 'Desativado') {
      return { backgroundColor: '#FFC3C3' }
    }

    return {}
  }

  const onClickSortHeader = (selectedHeader, table) => {
    let orientation = 'ASC'

    if (table === 'coupons') {
      if (selectedSortHeader === selectedHeader) {
        if (sortOrientation === 'ASC') {
          // If the header is clicked twice, we should change orientation to DESC
          orientation = 'DESC'
        } else {
          // If the header is clicked three times, we will return to the default ordering
          selectedHeader = 0
        }
      }

      setSelectedSortHeader(selectedHeader)
      setSortOrientation(orientation)
    }
  }

  const fetchDataTables = () => {
    setIsLoading(true)
    fetchCoupons()
  }

  const renderAddOrEditCouponPopupContent = () => {
    return (
      <div className='grid grid-cols-1 max-w-[670px] m-auto'>
        <div>
          <FormInput
            placeholder='Código'
            label='Código do Cupom*'
            maxLength={50}
            value={couponItemCode.toUpperCase()}
            onChange={e => setCouponItemCode(e.target.value)}
          />
          <FormInput
            label='Selecione o tipo de cupom*'
            customStyle={customSelectStyles}
            defaultValueOption={couponItemType}
            value={couponItemType}
            onChange={type => setCouponItemType(type)}
            selectOptions={cupomTypesOptions}
            placeholder={'Selecione o tipo'}
            noOptionsMessage={() => 'Sem opções'}
          />
        </div>
        <div>
          <div className='form-label'>
            <p className='big-paragraph'>Expira em</p>
          </div>
          <DatePicker
            className='form-input mb-6 max-w-[200px] mr-4'
            value={couponItemExpirationDate}
            onChange={e => setCouponItemExpirationDate(e.target.value)}
            max='2030-12-31'
            min='2017-12-31'
          />
          <FormInput
            placeholder='Digite um número'
            label='Limite de uso'
            maxLength={4}
            value={couponItemLimitNumber}
            onChange={e => setCouponItemLimitNumber(e.target.value)}
            mask={{
              format: '######'
            }}
          />
        </div>
        {isUpdateCoupon && (
          <div>
            <FormInput
              label='Selecione o status*'
              customStyle={customSelectStyles}
              defaultValueOption={couponItemStatus}
              value={couponItemStatus}
              onChange={status => setCouponItemStatus(status)}
              selectOptions={cupomStatusOptions}
              placeholder={'Selecione o tipo'}
              noOptionsMessage={() => 'Sem opções'}
            />
          </div>
        )}
      </div>
    )
  }

  const setUpdatedCouponState = coupon => {
    const expirationAt =
      coupon.expirationAt !== 'Não expira'
        ? DateTime.fromFormat(coupon.expirationAt, 'dd/MM/yyyy')
        : '2199-12-31'
    const expirationDate =
      expirationAt !== '2199-12-31'
        ? expirationAt.toFormat('yyyy-MM-dd')
        : '2199-12-31'
    const status =
      coupon.status === 'Ativo'
        ? { value: 1, label: 'Ativado' }
        : { value: 0, label: 'Desativado' }

    setIsUpdateCoupon(true)
    setCouponItemCode(coupon.code)
    setCouponItemType(getCouponTypeByText(coupon.type))
    setCouponItemExpirationDate(expirationDate)
    setCouponItemLimitNumber(coupon.userUsageLimit)
    setCouponItemStatus(status)
    setShowAddOrEditCouponPopup(true)
  }

  const validateCouponInputs = () => {
    const currentDate = DateTime.now()

    if (!couponItemCode) {
      errorToast('Por favor, digite um código valido!')
      return
    }

    if (couponItemType?.value === 0 || couponItemType?.value === undefined) {
      errorToast('Por favor, selecione um tipo de cupom valido!')
      return
    }

    if (DateTime.fromISO(couponItemExpirationDate) < currentDate) {
      errorToast('Por favor, selecione uma data para expirar valida!')
      return
    }

    return true
  }

  const submitNewCoupon = async () => {
    const isValid = validateCouponInputs()
    if (!isValid) return

    setPopupLoading(true)

    const data = {
      type: 'general',
      code: couponItemCode,
      value: couponItemType?.value,
      valueType: 'percentual',
      shippingValue: 1,
      expiration: couponItemExpirationDate
        ? couponItemExpirationDate
        : '2199-12-31',
      userUsageLimit: couponItemLimitNumber
        ? Number(couponItemLimitNumber.replace(/\s/g, ''))
        : 999999
    }

    try {
      await createNewCoupon(props.token, data)
      setShowAddOrEditCouponPopup(false)
      fetchDataTables()
      successToast('Cupom adicionado com sucesso!')
      setCouponItemCode('')
      setCouponItemExpirationDate('')
      setCouponItemLimitNumber('')
      setCouponItemType('')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const submitEditCoupon = async () => {
    const isValid = validateCouponInputs()
    if (!isValid) return

    setPopupLoading(true)

    const limitNumber =
      typeof couponItemLimitNumber === 'string'
        ? Number(couponItemLimitNumber.replace(/\s/g, ''))
        : couponItemLimitNumber

    const data = {
      code: couponItemCode,
      value: couponItemType?.value,
      expiration: couponItemExpirationDate,
      userUsageLimit: !limitNumber ? 999999 : limitNumber,
      active: couponItemStatus ? couponItemStatus?.value : 1
    }

    try {
      await editCoupon(props.token, data)
      setShowAddOrEditCouponPopup(false)
      fetchDataTables()
      successToast('Cupom alterado com sucesso!')
      setCouponItemCode('')
      setCouponItemExpirationDate('')
      setCouponItemLimitNumber('')
      setCouponItemType('')
      setCouponItemStatus({})
      setIsUpdateCoupon(false)
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const renderDeleteCouponPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja <strong>APAGAR</strong> o
          cupom{' '}
          <strong>
            <u>{couponItemCode}</u>
          </strong>
          ?
        </p>
      </div>
    )
  }

  const deleteCouponItem = async () => {
    if (!couponItemCode) {
      errorToast('Nenhum cupom selecionado!')
      return
    }

    setPopupLoading(true)
    try {
      await deleteCoupon(props.token, { code: couponItemCode })
      setShowDeleteCouponPopup(false)
      fetchDataTables()
      setCouponItemCode('')
      successToast('Cupom apagado com sucesso!')
    } catch (e) {
      console.error(e)
    } finally {
      setPopupLoading(false)
    }
  }

  const resetCouponFields = () => {
    if (isUpdateCoupon) {
      setIsUpdateCoupon(false)
      setCouponItemCode('')
      setCouponItemType(getCouponTypeByText(''))
      setCouponItemExpirationDate('')
      setCouponItemLimitNumber('')
      setCouponItemStatus('')
    }
  }

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Gerenciamento de cupons</h2>
      </div>
      <div className='z-10 relative'>
        <FilterCard
          itemList={[...dataCoupons]}
          setFilteredItemsList={list => {
            setCouponsDataSearchResult(list)
          }}
          searchBarPlaceHolder='Buscar cupom por código'
          filterList={filterList}
        />
      </div>
      <div className='normal-table-section'>
        <div className='normal-table-title'>
          <h4 className='normal-table-title-text'>Cupons</h4>
        </div>
        {isLoading ? (
          <Loading />
        ) : filteredCouponData.length > 0 ? (
          <BasicTable
            itemsPerPage={10}
            filteredData={filteredCouponData}
            tableHeaders={userTableHeadersCoupons}
            tableKeys={couponsTableKeys}
            paginatedData={paginatedCouponData}
            setPaginatedData={setPaginatedCouponData}
            onClickSortHeader={onClickSortHeader}
            selectedSortHeader={selectedSortHeader}
            sortOrientation={sortOrientation}
            onPressAddButton={() => setShowAddOrEditCouponPopup(true)}
            tableType={'coupons'}
            rowStyle={rowStyle}
          />
        ) : (
          <div className='basic-table-add-button-container'>
            <button
              className='basic-table-add-button'
              onClick={() => setShowAddOrEditCouponPopup(true)}
            >
              <AddRounded className='basic-table-add-icon' />
            </button>
          </div>
        )}
      </div>
      {showAddOrEditCouponPopup && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={renderAddOrEditCouponPopupContent()}
          onClosePopup={() => {
            setShowAddOrEditCouponPopup(false)
            isUpdateCoupon && resetCouponFields()
          }}
          title={
            isUpdateCoupon
              ? `Editar cupom - ${couponItemCode}`
              : 'Adicionar novo cupom'
          }
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-identity-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText={isUpdateCoupon ? 'EDITAR' : 'ADICIONAR'}
          onPressSecondActionButton={() =>
            isUpdateCoupon ? submitEditCoupon() : submitNewCoupon()
          }
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() => {
            setShowAddOrEditCouponPopup(false)
            isUpdateCoupon && resetCouponFields()
          }}
        />
      )}
      {showDeleteCouponPopup && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={renderDeleteCouponPopupContent()}
          onClosePopup={() => setShowDeleteCouponPopup(false)}
          title='Apagar cupom'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='APAGAR'
          onPressSecondActionButton={() => deleteCouponItem()}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() => setShowDeleteCouponPopup(false)}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(Coupons)
