import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'

import BasicCard from '../../components/BasicCard'

import { ChevronRightOutlined } from '@material-ui/icons'
import Loading from '../../components/Loading'

import { getSubscribers } from '../../services/utils'

import '../../styles/containers/Sales/SalesDashboard.css'
import {
  getPlanName,
  getTotalUserExperience
} from '../../helpers/ConsultantDetailsHelpers'
import { getSubscriptionTime } from '../../helpers/SalesHelpers'

const SubscribersSummary = props => {
  let navigate = useNavigate()
  let [isLoading, setIsLoading] = useState(true)

  let [mostCancelChanceUsers, setMostCancelChanceUsers] = useState([])
  let [awaitingCancelUsers, setAwaitingCancelUsers] = useState([])
  let [mostSuccessChanceUsers, setMostSuccessChanceUsers] = useState([])

  const cancelChanceUserCardContent = user => {
    return (
      <div className='py-4 px-4 grid grid-cols-1 md:grid-cols-3'>
        <div className='flex flex-col items-center justify-center col-span-1'>
          <p
            className='sales-dashboard-client-subscription-chance-number mb-2'
            style={{
              color:
                user.rawCancelChance >= 70
                  ? 'var(--error-2)'
                  : 'var(--success-5)'
            }}
          >
            {user.cancelChance}
          </p>
          <p className='sales-dashboard-client-subscription-chance-text text-center'>
            Chance de cancelar
          </p>
        </div>
        <div className='flex flex-col col-span-2 ml-2'>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.name}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.experience
                ? `Consultora há ${user.experience}`
                : 'Experiência não preenchida'}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {`Plano: ${user.plan}`}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {`Assina há ${user.subscriptionSince}`}
            </p>
          </div>
          <div className='flex'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-three-lines-text'>
              {`Problema com: ${user.functionalitiesWithProblems.join(', ')}`}
            </p>
          </div>
          <div
            className='flex justify-end cursor-pointer mt-8'
            onClick={() => {
              navigate('/consultoras/detalhes', {
                state: { user, pageOrigin: 'Acompanhamento de Assinantes' }
              })
            }}
          >
            <p className='small-paragraph sales-dashboard-see-all-text'>
              Ver detalhes
            </p>
            <ChevronRightOutlined className='sales-dashboard-right-icon' />
          </div>
        </div>
      </div>
    )
  }

  const successChanceUserCardContent = user => {
    return (
      <div className='py-4 px-4 grid grid-cols-1 md:grid-cols-3'>
        <div className='flex flex-col items-center justify-center col-span-1'>
          <p className='sales-dashboard-client-subscription-chance-number mb-2'>
            {user.successChance}
          </p>
          <p className='sales-dashboard-client-subscription-chance-text text-center'>
            Chance de sucesso
          </p>
        </div>
        <div className='flex flex-col col-span-2 ml-2'>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.name}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {user.experience
                ? `Consultora há ${user.experience}`
                : 'Experiência não preenchida'}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {`Plano: ${user.plan}`}
            </p>
          </div>
          <div className='flex mb-4'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-two-lines-text'>
              {`Assina há ${user.subscriptionSince}`}
            </p>
          </div>
          <div className='flex'>
            <div className='sales-dashboard-item-thin-trace' />
            <p className='small-paragraph ml-2 sales-dashboard-client-item sales-dashboard-three-lines-text'>
              {`Principais funcionalidades: ${user.mainFunctionalities.join(
                ', '
              )}`}
            </p>
          </div>
          <div
            className='flex justify-end cursor-pointer mt-8'
            onClick={() => {
              navigate('/consultoras/detalhes', {
                state: { user, pageOrigin: 'Acompanhamento de Assinantes' }
              })
            }}
          >
            <p className='small-paragraph sales-dashboard-see-all-text'>
              Ver detalhes
            </p>
            <ChevronRightOutlined className='sales-dashboard-right-icon' />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    async function getSalesDashboardInfo () {
      try {
        const crm = await getSubscribers(props.token)

        const sortedByChanceUsers = crm.sort(
          (a, b) => a.subscriptionChance - b.subscriptionChance
        )

        // GETTING MOST CANCEL CHANCE USERS
        const mostCancelChanceUsers = sortedByChanceUsers.map(user => {
          const { clientDetails: details } = user

          const functionalities = [
            { label: 'Lista de Compras', value: details.shopListTotal },
            { label: 'Guia de estilo', value: details.moodboardTotal },
            { label: 'Estilo', value: details.clientStyle },
            { label: 'Sobre mim personalizado', value: details.customAboutMe },
            { label: 'Closet virtual', value: details.virtualCloset },
            { label: 'Cobranças', value: details.billings },
            { label: 'Silhueta', value: details.silhouette },
            { label: 'Serviços', value: details.service },
            { label: 'Compromissos', value: details.agendaEvent }
          ]

          functionalities.sort((a, b) => a.value - b.value)

          const functionalitiesWithProblems = [
            functionalities[0].label,
            functionalities[1].label
          ]

          const userDaysSubscribed = DateTime.now()
            .diff(DateTime.fromISO(user.createdAt), 'day')
            .get('days')

          return {
            id: user.id,
            name: user.name,
            experience:
              user.experieceId &&
              getTotalUserExperience(user.experieceId, userDaysSubscribed),
            plan: getPlanName(user.subscriptionPlanIdentifier),
            subscriptionSince: (user.subscriptionCreatedAt
              ? getSubscriptionTime(user.subscriptionCreatedAt)
              : 'indefinido'
            ).toLowerCase(),
            cancelChance: `${((1 - user.subscriptionChance) * 100).toFixed(
              0
            )}%`,
            rawCancelChance: Math.ceil((1 - user.subscriptionChance) * 100),
            functionalitiesWithProblems,
            isAwaitingCancel: user.isAwaitingCancel
          }
        })

        setMostCancelChanceUsers(mostCancelChanceUsers)

        // GETTING AWAITING CANCEL USERS
        const awaitingCancelUsers = mostCancelChanceUsers.filter(user =>
          Boolean(user.isAwaitingCancel)
        )
        setAwaitingCancelUsers(awaitingCancelUsers)

        // GETTING MOST SUCCESS CHANCE USERS
        const mostSuccessChanceUsers = crm
          .sort((a, b) => b.subscriptionChance - a.subscriptionChance)
          .map(user => {
            const { clientDetails: details } = user

            const functionalities = [
              { label: 'Lista de Compras', value: details.shopListTotal },
              { label: 'Guia de estilo', value: details.moodboardTotal },
              { label: 'Estilo', value: details.clientStyle },
              {
                label: 'Sobre mim personalizado',
                value: details.customAboutMe
              },
              { label: 'Closet virtual', value: details.virtualCloset },
              { label: 'Cobranças', value: details.billings },
              { label: 'Silhueta', value: details.silhouette },
              { label: 'Serviços', value: details.service },
              { label: 'Compromissos', value: details.agendaEvent }
            ]

            functionalities.sort((a, b) => b.value - a.value)

            const mainFunctionalities = [
              functionalities[0].label,
              functionalities[1].label
            ]

            const userDaysSubscribed = DateTime.now()
              .diff(DateTime.fromISO(user.createdAt), 'day')
              .get('days')

            return {
              id: user.id,
              name: user.name,
              experience:
                user.experieceId &&
                getTotalUserExperience(user.experieceId, userDaysSubscribed),
              plan: getPlanName(user.subscriptionPlanIdentifier),
              subscriptionSince: (user.subscriptionCreatedAt
                ? getSubscriptionTime(user.subscriptionCreatedAt)
                : 'indefinido'
              ).toLowerCase(),
              successChance: `${(user.subscriptionChance * 100).toFixed(0)}%`,
              mainFunctionalities
            }
          })

        setMostSuccessChanceUsers(mostSuccessChanceUsers)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    }

    getSalesDashboardInfo()
  }, [props.token])

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Acompanhamento de assinantes</h2>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <div>
            <div className='mb-4'>
              <h4 className='my-data-section-header'>
                Consultoras com maior probabilidade de cancelar
              </h4>
            </div>
            <div className='flex overflow-auto px-2 py-2 mb-4'>
              {mostCancelChanceUsers.length > 0 ? (
                mostCancelChanceUsers.map((user, key) => {
                  return (
                    <div key={key} className='mr-4 max-w-[340px] min-w-[300px]'>
                      <BasicCard
                        cardContent={cancelChanceUserCardContent(user)}
                      />
                    </div>
                  )
                })
              ) : (
                <h5 className='w-full py-10 max-w-3xl m-auto text-center'>
                  Nenhuma consultora encontrada
                </h5>
              )}
            </div>
            {mostCancelChanceUsers.length > 0 && (
              <div
                className='flex justify-end cursor-pointer mb-8'
                onClick={() => {
                  navigate(
                    '/sucesso-do-cliente/acompanhamento-assinantes-tabelas',
                    {
                      state: {
                        selectedOnlyFilter: 'cancelChance'
                      }
                    }
                  )
                }}
              >
                <p className='sales-dashboard-see-all-text'>
                  Ver todas as consultoras
                </p>
                <ChevronRightOutlined className='sales-dashboard-right-icon' />
              </div>
            )}
            <div className='mb-4'>
              <h4 className='my-data-section-header'>
                Consultoras com cancelamento agendado
              </h4>
            </div>
            <div className='flex overflow-auto px-2 py-2 mb-4'>
              {awaitingCancelUsers.length > 0 ? (
                awaitingCancelUsers.map((user, key) => {
                  return (
                    <div key={key} className='mr-4 max-w-[340px] min-w-[300px]'>
                      <BasicCard
                        cardContent={cancelChanceUserCardContent(user)}
                      />
                    </div>
                  )
                })
              ) : (
                <h5 className='w-full py-10 max-w-3xl m-auto text-center'>
                  Nenhuma consultora com cancelamento agendado encontrada
                </h5>
              )}
            </div>
            {awaitingCancelUsers.length > 0 && (
              <div
                className='flex justify-end cursor-pointer mb-8'
                onClick={() => {
                  navigate(
                    '/sucesso-do-cliente/acompanhamento-assinantes-tabelas',
                    {
                      state: {
                        selectedOnlyFilter: 'awaitingCancel'
                      }
                    }
                  )
                }}
              >
                <p className='sales-dashboard-see-all-text'>
                  Ver todas as consultoras
                </p>
                <ChevronRightOutlined className='sales-dashboard-right-icon' />
              </div>
            )}
            <div className='mb-4'>
              <h4 className='my-data-section-header'>
                Consultoras com maior probabilidade de sucesso
              </h4>
            </div>
            <div className='flex overflow-auto px-2 py-2 mb-4'>
              {mostSuccessChanceUsers.length > 0 ? (
                mostSuccessChanceUsers.map((user, key) => {
                  return (
                    <div key={key} className='mr-4 max-w-[340px] min-w-[300px]'>
                      <BasicCard
                        cardContent={successChanceUserCardContent(user)}
                      />
                    </div>
                  )
                })
              ) : (
                <h5 className='w-full py-10 max-w-3xl m-auto text-center'>
                  Nenhuma consultora encontrada
                </h5>
              )}
            </div>
            {mostSuccessChanceUsers.length > 0 && (
              <div
                className='flex justify-end cursor-pointer mb-8'
                onClick={() => {
                  navigate(
                    '/sucesso-do-cliente/acompanhamento-assinantes-tabelas',
                    {
                      state: {
                        selectedOnlyFilter: 'successChance'
                      }
                    }
                  )
                }}
              >
                <p className='sales-dashboard-see-all-text'>
                  Ver todas as consultoras
                </p>
                <ChevronRightOutlined className='sales-dashboard-right-icon' />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(SubscribersSummary)
