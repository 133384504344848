import React from 'react'
import '../styles/components/ProgressBar.css'

const ProgressBar = props => {
  const { bgcolor, count, total, ideal } = props

  const fillerStyles = {
    width: `${(count / total) * 100}%`,
    backgroundColor: bgcolor
  }

  const idealLineStyle = {
    width: `${(ideal / total) * 100}%`
  }

  return (
    <div className='flex mb-8'>
      <div className='progress-bar-container'>
        <div className='progress-bar-filler' style={fillerStyles}></div>
        {ideal && (
          <div className='progress-bar-ideal-line' style={idealLineStyle}>
            <span className='progress-bar-ideal-text'>
              Mínimo ideal: {ideal}
            </span>
          </div>
        )}
      </div>
      <div>
        <p>
          {count}/{total}
        </p>
      </div>
    </div>
  )
}

export default ProgressBar
